<template>
  <div>
    <NbPageTitle :title="$t('overpacksPage.title')" />

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" />

      <NbTablev2
        tableOf="ovepacksTable"
        namespace="overpacks"
        ref="ovepacksTableRef"
        :clickable="true"
        :allFields="fields"
        :fields="allFields"
        :tabs="true"
        :optionsWidth="260"
        :filterOptions="filterOptions"
        @reloadFields="allFields = $event"
        @total="totalOverpacks = $event"
        @optionTook="optionTook"
        selectable
        :selected-items.sync="selected"
        :buttonOptions="tableOptions"
        @clickedRow="showOverpackDetails($event.id)"
      >
        <template #cell(masterbox_id)="data">
          <router-link
            v-if="data.item.masterbox_id"
            class="link-1"
            :to="`/shipping/masterboxes?id=` + data.item.masterbox_id"
          >
            {{ data.item.masterbox_id }}
          </router-link>
          <span v-else>-</span>
        </template>
        <template #cell(sent_at)="data">
          <div>
            {{ data.item.sent_at ? formateDate(data.item.sent_at) : "NEW" }}
            {{ data.item.sent_at ? formateTime(data.item.sent_at) : "" }}
          </div>
        </template>
        <template #cell(created_at)="data">
          <div>
            {{
              data.item.created_at ? formateDate(data.item.created_at) : "ERROR"
            }}
            {{ data.item.created_at ? formateTime(data.item.created_at) : "" }}
          </div>
        </template>
      </NbTablev2>
    </section>

    <Modal v-model="openUpdateTrackingStatus">
      <UpdateMultipleTrackingStatus
        :overpack-ids="selected"
        @success="loadItems"
      />
    </Modal>

    <Modal v-model="openUpdateFirstMile">
      <UpdateFirstMile :overpack-ids="selected" @success="loadItems" />
    </Modal>

    <Modal v-model="openOverpackData" size="lg" @close="onCloseOverpackData">
      <Overpack :overpack-id="selectedOverpackId" />
    </Modal>
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import Modal from "../../../components/modal/Modal.vue";
import UpdateMultipleTrackingStatus from "./components/UpdateMultipleTrackingStatus.vue";
import UpdateFirstMile from "./components/UpdateFirstMile.vue";
import Overpack from "./components/Overpack.vue";
import queryParamsMixin from "@/mixins/query-params-mixin.js";

export default {
  name: "Overpacks",
  mixins: [queryParamsMixin],
  components: {
    NbPageTitle,
    NbTablev2,
    NbTabs,
    Modal,
    UpdateMultipleTrackingStatus,
    UpdateFirstMile,
    Overpack,
  },
  data: () => {
    return {
      currentTab: "all",
      totalOverpacks: 0,
      openUpdateTrackingStatus: false,
      openUpdateFirstMile: false,
      openOverpackData: false,
      allFields: [],
      selected: [],
      selectedOverpackId: null,
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: this.currentTab,
          label: this.$t("all"),
          current: this.currentTab,
          found: this.totalOverpacks,
        },
      ];
    },
    items() {
      return this.$store.state[this.namespace].items;
    },
    fields() {
      return [
        { key: "id", label: "ID" },
        {
          key: "sent_at",
          label: this.$t("overpacksPage.fields.sentDate"),
        },
        {
          key: "reference",
          label: this.$t("overpacksPage.fields.trackingNumber"),
        },
        {
          key: "masterbox_id",
          label: this.$t("overpacksPage.fields.masterboxID"),
        },
        {
          key: "first_mile_tracking_number",
          label: this.$t("overpacksPage.fields.fMTrackingN"),
        },
        {
          key: "first_mile_carrier_name",
          label: this.$t("overpacksPage.fields.firstMileCompany"),
        },
        {
          key: "created_at",
          label: this.$t("overpacksPage.fields.creationDate"),
          sortable: true,
        },
      ];
    },
    tableOptions() {
      return [
        {
          text: this.$t("overpacksPage.updateTrackingStatus"),
          value: "showModalUpdateTrackingStatus",
        },
        {
          text: this.$t("overpacksPage.updateOverpacks"),
          value: "showModalUpdateOverpacks",
        },
      ];
    },
    filterOptions() {
      return [
        { key: "reference", label: "Tracking Number" },
        { key: "masterbox_id", label: "Masterbox ID" },
        { key: "first_mile_tracking_number", label: "FM Tracking Number" },
        { key: "first_mile_carrier_name", label: "First Mile Company" },
        {
          key: "registration_date",
          label: this.$t("registrationDate"),
          type: "dates",
        },
        { key: "send_date", label: "Send Date", type: "dates" },
      ];
    },
    trackingOptions() {
      return [
        {
          code: 617,
          description: this.$t(
            "overpacksPage.trackingOptions.forwardedlocalhub",
          ),
        },
        {
          code: 602,
          description: this.$t(
            "overpacksPage.trackingOptions.arrivedHubSorting",
          ),
        },
        {
          code: 605,
          description: this.$t(
            "overpacksPage.trackingOptions.clearanceCompleted",
          ),
        },
        {
          code: 607,
          description: this.$t(
            "overpacksPage.trackingOptions.shipmentArrivedDestinationCountry",
          ),
        },
        {
          code: 608,
          description: this.$t(
            "overpacksPage.trackingOptions.handedCustomsCustomsClearance",
          ),
        },
        {
          code: 610,
          description: this.$t(
            "overpacksPage.trackingOptions.customsClearanceCompleted",
          ),
        },
        {
          code: 616,
          description: this.$t(
            "overpacksPage.trackingOptions.arrivedHubSortingCenter",
          ),
        },
      ];
    },
  },
  methods: {
    onCloseOverpackData() {
      this.setUrlSearchParam("id");
      this.selectedOverpackId = null;
    },
    optionTook(event) {
      this[event]();
    },
    showOverpackDetails(id) {
      this.setUrlSearchParam("id", id);
      this.selectedOverpackId = id;
      this.openOverpackData = true;
    },
    showModalUpdateTrackingStatus() {
      this.openUpdateTrackingStatus = true;
    },
    showModalUpdateOverpacks() {
      this.openUpdateFirstMile = true;
    },
    loadItems() {
      this.$refs?.ovepacksTableRef?.getData();
    },
    selectedRows(items) {
      this.selected = items;
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date) {
      const data = new Date(date);
      const formatedDate = this.addZero(
        data.getDate().toString() +
          "/" +
          this.addZero(data.getMonth() + 1).toString() +
          "/" +
          data.getFullYear(),
      );
      return formatedDate;
    },
    formateTime(time) {
      const hours = new Date(time);
      const formatedHours =
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedHours;
    },
  },
  mounted() {
    const { id: overpackId } = this.$route.query;
    if (overpackId) {
      this.showOverpackDetails(Number(overpackId));
    }
  },
};
</script>
