<template>
  <div>
    <NbPageTitle
      class="pb-3 page-container"
      :title="$t('shippingRulesPage.title')"
      :subTitle="$t('shippingRulesPage.subtitle')"
    >
      <div class="btn-groups d-flex flex-column">
        <NbButton
          class="text-left"
          icon="plus"
          data-toggle="modal"
          data-target="#newShippingRuleModal"
          @click="initializeShippingRule()"
        >
          {{ $t("shippingRulesPage.addNewRule") }}
        </NbButton>
      </div>
    </NbPageTitle>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />

      <NbTablev2
        tableOf="shippingRules"
        :namespace="namespace"
        ref="shippingRules"
        :allFields="allFields"
        :fields="fields"
        :filterOptions="filterOptions"
        @reloadFields="fields = $event"
        @total="totalShippingRules = $event"
      >
        <template #cell(priority)="data">
          <div
            :class="
              data.item.priority >= 4
                ? 'text-info bold'
                : data.item.priority == 3
                  ? 'text-warning bold'
                  : 'text-danger bold'
            "
          >
            {{ getPriorityText(data.item.priority) }}
          </div>
        </template>

        <template #cell(seller_ids)="data">
          <span v-if="data.item.seller_ids?.length > 1" class="dropdown">
            <a data-toggle="dropdown">
              <u class="link-1">
                {{
                  allSellerOptions.length > 0
                    ? allSellerOptions.find(
                        (seller) => seller.value == data.item.seller_ids[0],
                      ).name
                    : "not found"
                }}
                <div>and {{ data.item.seller_ids?.length - 1 }} more ></div>
              </u>
            </a>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <div
                v-for="seller_id in data.item.seller_ids"
                :key="`seller_id_${seller_id}`"
                class="link-1 dropdown-item text-center"
                @click="
                  $router.push(`/users_management/sellers?id=${seller_id}`)
                "
              >
                {{
                  allSellerOptions.length > 0
                    ? allSellerOptions.find(
                        (seller) => seller.value == seller_id,
                      ).name
                    : "loading"
                }}
              </div>
            </div>
          </span>
          <span
            v-else-if="data.item.seller_ids?.length == 1"
            class="link-1"
            @click="
              $router.push(
                `/users_management/sellers?id=${data.item.seller_ids[0]}`,
              )
            "
          >
            {{
              allSellerOptions.length > 0
                ? allSellerOptions.find(
                    (seller) => seller.value == data.item.seller_ids[0],
                  ).name
                : "loading"
            }}
          </span>
          <span v-else>
            <NbIcon
              icon="loader"
              :attributes="{
                class: 'mr-1 mb-1 spin',
                width: '1rem',
                height: '1rem',
              }"
            />
          </span>
        </template>

        <template #cell(conditions)="data">
          <div v-if="data.item.conditions_attributes?.length > 1">
            <a data-toggle="dropdown">
              <u class="link-1">
                {{ data.item.conditions_attributes[0]?.condition_text }}
                <div>
                  and {{ data.item.conditions_attributes?.length - 1 }} more >
                </div>
              </u>
            </a>

            <div class="dropdown-menu" aria-labelledby="dropdownConditions">
              <div
                v-for="condition in data.item.conditions_attributes"
                :key="`${condition.id}-cond`"
                class="dropdown-item text-center"
              >
                {{ condition.condition_text }}
              </div>
            </div>
          </div>
          <div v-else>
            <span>
              {{ data.item.conditions_attributes[0]?.condition_text }}
            </span>
          </div>
        </template>

        <template #cell(active)="data">
          <IsCheckedIcon :checked="data.item.active" />
        </template>

        <template #cell(actions)="data">
          <span
            v-for="action in data.item.actions_attributes"
            :key="`${action.id}-act`"
          >
            {{ action.action_text }}
          </span>
        </template>
        <template #cell(actionsBtn)="data">
          <NbButton
            class="pl-0"
            variant="tertiary"
            icon="edit"
            :title="`Edit Shipping Rule ${data.item.name}`"
            @click="initializeShippingRule(data.item)"
          >
            {{ $t("edit") }}
          </NbButton>
          <NbButton
            variant="tertiary"
            icon="trash"
            :title="`Delete this Shipping Rule ${data.item.name}`"
            data-toggle="modal"
            data-target="#modalDeleteShippingRule"
            @click="shippingRuleToDelete = data.item"
          >
            {{ $t("delete") }}
          </NbButton>
        </template>
      </NbTablev2>
    </section>

    <NbFooter
      class="page-container"
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />

    <!-- modals -->

    <NbModal id="modalDeleteShippingRule" modalConfig="modal-dialog-centered">
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">
            {{ $t("sureWantDelete") }} ({{ shippingRuleToDelete.name }})
          </h5>
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            data-target="#modalDeleteShippingRule"
          >
            {{ $t("close") }}
          </NbButton>
          <NbButton @click="deleteShippingRule(shippingRuleToDelete.id)">
            {{ $t("delete") }}
          </NbButton>
        </div>
      </template>
    </NbModal>

    <ModalShippingRuleCreate
      id="newShippingRuleModal"
      :shippingRule.sync="newShippingRules"
      @reloadShippingRules="reloadShippingRules"
    />

    <!-- end modals -->
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbModal from "@/components/modal/NbModal.vue";
import ModalShippingRuleCreate from "@/views/shipping/shippingrules/components/ModalShippingRuleCreate.vue";

import ShippingRulesService from "@/services/ShippingRulesService";
import IsCheckedIcon from "@/components/IsCheckedIcon.vue";

const shippingRulesService = new ShippingRulesService();

export default {
  name: "ShippingRules",
  components: {
    NbPageTitle,
    NbButton,
    NbTabs,
    NbTablev2,
    NbFooter,
    NbIcon,
    NbModal,
    ModalShippingRuleCreate,
    IsCheckedIcon,
  },
  data() {
    return {
      filterOptions: [
        { key: "priority", label: "priority", type: "boolean" },
        { key: "name", label: "name" },
        { key: "shipping_condition", label: "condition", type: "boolean" },
        { key: "shipping_action", label: "action", type: "boolean" },
        { key: "expiration", label: "expiration", type: "date" },
        { key: "active", label: "is_active", type: "boolean" },
      ],
      fields: [],
      namespace: "shipping_rules",
      shippingRules: [],
      newShippingRules: {
        name: null,
        priority: null, // integer
        active: true, // boolean
        expiration: null, // date string
        seller_ids: [],
        conditions_attributes: [
          /*
          {
            condition_name: null, // string
            operator: null, // string enum
            values: null, // array of string values
          },
          */
        ],
        actions_attributes: [
          {
            action_name: "", // string
            values: "", // array of string values
          },
        ],
      },
      resetControl: false,
      timer: null,
      shippingRuleToDelete: {},
      totalShippingRules: -1,
      currentTable: "all",
    };
  },
  computed: {
    allSellers() {
      return this.$store.state.sellers.all_items;
    },
    allSellerOptions() {
      const sellerOptions = this.allSellers.map((item) => ({
        value: item.id,
        name: item.name,
      }));
      return sellerOptions;
    },
    allFields() {
      return [
        { key: "priority", label: "priority" },
        { key: "name", label: "name" },
        { key: "seller_ids", label: "sellers" },
        { key: "expiration", label: "expiration" },
        { key: "conditions", label: "condition" },
        { key: "actions", label: "action" },
        { key: "active", label: "is active?" },
        {
          key: "actionsBtn",
          label: "Actions",
          isCustomizable: false,
          class: "right--20 right-sticky",
        },
      ];
    },
    tabsOptions() {
      return [
        {
          id: "all",
          label: this.$t("shippingRulesPage.title"),
          found: this.totalShippingRules,
          current: this.currentTable,
        },
      ];
    },
    rulePriorityOptions() {
      return [
        {
          value: 1,
          text: this.$t("shippingRulesPage.rulePriorityOptions.veryHigh"),
        },
        {
          value: 2,
          text: this.$t("shippingRulesPage.rulePriorityOptions.high"),
        },
        {
          value: 3,
          text: this.$t("shippingRulesPage.rulePriorityOptions.medium"),
        },
        {
          value: 4,
          text: this.$t("shippingRulesPage.rulePriorityOptions.low"),
        },
        {
          value: 5,
          text: this.$t("shippingRulesPage.rulePriorityOptions.veryLow"),
        },
      ];
    },
  },
  watch: {},
  created() {
    this.fields = this.allFields;
  },
  methods: {
    reloadShippingRules() {
      this.$refs?.shippingRules?.getData();
    },
    getPriorityText(optionKey) {
      const findText = this.rulePriorityOptions.find(
        (option) => option.value === optionKey,
      ).text;
      if (findText) {
        return findText;
      }
      return "-";
    },
    findAttributesNames(rule) {
      rule.rule_conditions.forEach((rule_condition) => {
        rule.conditions_attributes.forEach((condition) => {
          if (condition.rule_condition_id === rule_condition.id) {
            condition.condition_name = rule_condition.name;
          }
        });
      });
      rule.rule_actions.forEach((rule_action) => {
        rule.actions_attributes.forEach((action) => {
          if (action.rule_action_id === rule_action.id) {
            action.action_name = rule_action.name;
          }
        });
      });
      return rule;
    },
    invertBuildValues(event) {
      let finalData = JSON.parse(JSON.stringify(event));
      finalData.conditions_attributes.forEach((condition) => {
        if (
          condition.condition_name === "Customer Postal Code" ||
          condition.condition_name === "Destination Country" ||
          condition.condition_name === "Item SKU" ||
          condition.condition_name === "Seller Price" ||
          condition.condition_name === "Number of Items" ||
          condition.condition_name === "Shipment Weight"
        ) {
          condition.values = condition.values.join(", ");
        }
      });
      finalData.actions_attributes.forEach((action) => {
        if (
          action.action_name === "Always sort by Courier" ||
          action.action_name === "Sort by Courier if no data given" ||
          action.action_name === "Always use this Incoterm" ||
          action.action_name === "Use this Incoterm if no data given" ||
          action.action_name === "Always use this Package" ||
          action.action_name === "Use this Package if no data given"
        ) {
          action.values = action.values.join(", ");
        } else if (
          action.action_name === "Always use this Courier" ||
          action.action_name === "Use this Courier if no data given"
        ) {
          action.values = action.values[0];
        }
      });
      finalData.seller_ids.forEach((seller_id, index) => {
        finalData.seller_ids[index] = Number(seller_id);
      });
      return finalData;
    },
    deleteShippingRule(id) {
      shippingRulesService.deleteShippingRule(id).then(() => {
        this.$helpers.closeModal("modalDeleteShippingRule");
        this.reloadShippingRules();
      });
    },
    buildUpdateShippingRule(event) {
      this.resetControl = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.timer = null;
        this.resetControl = false;
      }, 400);
      let builtValuesRule = this.findAttributesNames(event);
      this.newShippingRules = this.invertBuildValues(builtValuesRule);
      //document.getElementById("shippingRuleFormBtn").click();
    },
    initializeShippingRule(shippingRule) {
      if (shippingRule) {
        this.buildUpdateShippingRule(shippingRule);
        this.$helpers.openModal("newShippingRuleModal");
        return;
      }
      this.newShippingRules = {
        name: null,
        priority: null, // integer
        active: true, // boolean
        expiration: null, // date string
        seller_ids: [],
        conditions_attributes: [
          /*
          {
            condition_name: null, // string
            operator: null, // string enum
            values: null, // array of string values
          },
          */
        ],
        actions_attributes: [
          {
            action_name: "", // string
            values: "", // array of string values
          },
        ],
      };
    },
  },
};
</script>
