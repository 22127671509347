<template>
  <div>
    <back-button />
    <div class="main-body">
      <div class="row gutters-sm">
        <!-- right part -->
        <div class="col-md-8">
          <div>
            <div class="card-body">
              <!-- Devolution Address -->
              <div class="card">
                <div class="card-header">
                  <div
                    class="text-left"
                    style="font-size: 20px; margin-bottom: 12px"
                  >
                    {{ $t("saldoViewPage.currentBalance") }}:
                    <span
                      :style="`color: ${balance > 0 ? '#00CB8B' : '#FB1F1F'}`"
                    >
                      {{ balance > 0 ? "+" : "-" }} R$
                      {{ Math.abs(balance).toFixed(2) }}</span
                    >
                  </div>
                </div>
                <div class="card-body">
                  <div v-for="item in accountEvents" :key="item.id">
                    <div class="row" style="width: 1500px">
                      <div class="col-sm-6" style="margin-bottom: 12px">
                        <h6 class="mb-0" style="font-size: 12px">
                          {{ item.created_at }}
                        </h6>
                        <h6 class="mb-0" style="font-size: 18px">
                          {{ item.description || "" }}
                        </h6>
                      </div>
                      <div
                        v-if="item.value > 0"
                        class="col-sm-3"
                        style="
                          font-size: 18px;
                          margin-bottom: 12px;
                          color: #00cb8b;
                        "
                      >
                        + R$ {{ Math.abs(item.value).toFixed(2) }}
                      </div>
                      <div
                        v-else
                        class="col-sm-3"
                        style="
                          font-size: 18px;
                          margin-bottom: 12px;
                          color: #fb1f1f;
                        "
                      >
                        - R$ {{ Math.abs(item.value).toFixed(2) }}
                      </div>
                      <!-- <div class="col-sm-9 text-secondary">
                        {{item.value}}
                      </div> -->
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end - right part -->
      </div>

      <div class="row mt-3">
        <div class="col text-center"></div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/BackButton";
import SellerAccountService from "@/services/SellerAccountService";
const sellerAccountService = new SellerAccountService();

export default {
  name: "ViewSellers",
  components: {
    "back-button": BackButton,
  },
  data: () => {
    return {
      totalRows: 1,
      accountEvents: [],
      perPage: 20,
      filter: null,
      filterOn: ["name"],
      contractId: "",
      contractIndex: "",
      sellerId: "",
      currentPage: 1,
      balance: null,
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "name",
          label: this.$t("saldoViewPage.fields.fullName"),
          sortable: false,
        },
        {
          key: "email",
          label: this.$t("saldoViewPage.fields.email"),
          sortable: false,
        },
        { key: "is_blocked", label: this.$t("saldoViewPage.fields.status") },
      ];
    },
  },
  beforeMount() {
    this.getBalance();
    this.sellerId = this.$route.params.seller_id;
    this.loadAccountEvents();

    // pendingActionService.getPendingActionsContracts(this.$route.params.seller_id).then((response) => {
    //     this.requestedApprovals = response.data.data;
    // });
  },
  mounted() {},
  methods: {
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    getBalance() {
      sellerAccountService
        .getBalance(this.$route.params.seller_id)
        .then((response) => {
          this.balance = response.data.balance;
        });
    },
    formateDate(date) {
      const data = new Date(date);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear(),
        ) +
        "  " +
        this.addZero(data.getHours()) +
        ":" +
        this.addZero(data.getMinutes()) +
        ":" +
        this.addZero(data.getSeconds());
      return formatedDate;
    },
    addHTTPS(link) {
      if (link.includes("http")) {
        return link;
      }
      return "https://" + link;
    },
    loadAccountEvents() {
      sellerAccountService.getEvents(this.sellerId).then((response) => {
        this.accountEvents = response.data.seller_account_events;
      });
    },
  },
};
</script>

<style scoped>
.modal {
  overflow-y: auto;
}
</style>
