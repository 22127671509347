<template>
  <div>
    <DatatableRoot
      namespace="status_checkpoints"
      clickable
      :url-state="false"
      :columns="tableColumns"
      :filters="tableFilters"
      @count="$emit('total', $event)"
      @rowClick="loadModalData($event.id)"
    >
      <template #actions>
        <NbButton
          variant="secondary"
          size="sm"
          data-toggle="modal"
          data-target="#addStatusCheckpoint"
          @click="initializeStatusCheckpoint()"
        >
          {{ $t("statusCheckpointsPage.addStatusCheckpoint") }}
        </NbButton>
      </template>

      <template #checkpoint_name="{ row }">
        <div
          class="link-1"
          @click="loadModalNobordistCheckpoint(row.checkpoint_id)"
        >
          {{ getCheckpointName(row.checkpoint_id) }}
        </div>
      </template>
    </DatatableRoot>

    <!-- modal upload -->
    <ModalStatusCheckpointsView :itemId="currentItemId" />

    <ModalNobordistCheckpointsView
      id="StatusModalNobordistCheckpointsView"
      :itemId="currentNbCheckpointId"
    />
    <!-- start modal save -->
    <NbModal
      id="addStatusCheckpoint"
      modalConfig="modal-dialog-centered"
      width="30rem"
    >
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">
            {{ $t("statusCheckpointsPage.addNewStatusCheckpoint") }}
          </h5>
        </div>
      </template>
      <div>
        <div class="d-flex justify-content-between flex-wrap">
          <NbTextInput
            id="status_code"
            v-model="newstatusCheckpoint.status_code"
            required
            :name="$t('statusCheckpointsPage.fields.statusCode')"
            class="w-48-5"
            :error="errors['status_code']"
            placeholder="status code .."
          />
          <NbTextInput
            id="tracking_function"
            v-model="newstatusCheckpoint.tracking_function"
            required
            :name="$t('statusCheckpointsPage.fields.trackingFunction')"
            class="w-48-5 mb-3"
            :error="errors['tracking_function']"
            placeholder="tracking_ unction .."
          />
          <NbSelectInput
            required
            id="checkpoint_id"
            class="w-100 mb-3"
            v-model="newstatusCheckpoint.checkpoint_id"
            :name="$t('statusCheckpointsPage.checkpointID')"
            :error="errors['checkpoint_id']"
            :options="checkpointsOption"
          />
        </div>
      </div>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton
            id="addStatusCheckpointClose"
            class="mx-1"
            variant="secondary"
            data-dismiss="modal"
          >
            {{ $t("close") }}
          </NbButton>
          <NbButton class="mx-1" @click="addStatusCheckpoint()">
            {{ $t("save") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
    <!-- end modal save -->
  </div>
</template>
<script>
import NbButton from "@/components/buttons/NbButton.vue";
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import NProgress from "nprogress";
import NbModal from "@/components/modal/NbModal.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import ModalStatusCheckpointsView from "@/views/checkpoints/status_checkpoints/components/ModalStatusCheckpointsView.vue";
import ModalNobordistCheckpointsView from "@/views/checkpoints/nobordist_checkpoints/components/ModalNobordistCheckpointsView.vue";

import StatusCheckpointService from "@/services/StatusCheckpointService";

const statusCheckpointService = new StatusCheckpointService();

export default {
  name: "StatusCheckpoints",
  components: {
    NbButton,
    DatatableRoot,
    NbModal,
    NbTextInput,
    NbSelectInput,
    ModalStatusCheckpointsView,
    ModalNobordistCheckpointsView,
  },
  props: {},
  data: () => {
    return {
      fields: [],
      currentItemId: "",
      currentNbCheckpointId: "",
      currentNobordistCheckpointId: "",
      newstatusCheckpoint: {
        status_code: "",
        tracking_function: "",
        checkpoint_id: "",
      },
      errors: [],
      checkpoints: [],
      checkpointsOption: [],
    };
  },
  beforeMount() {
    this.loadCheckpoints();
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    checkQuery() {
      if (this.$route.query.statusCheckpointId) {
        this.$emit("switchTab", "statusCheckpoints");
        this.loadModalData(this.$route.query.statusCheckpointId);
        return;
      }
    },
    loadModalData(id) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query.statusCheckpointId) {
          this.$router.push({ query: { statusCheckpointId: id } });
        }
        /* this.$router.push({ query: {} }); */

        this.$helpers.openModal("ModalStatusCheckpointsView");
        return;
      }
    },
    loadModalNobordistCheckpoint(id) {
      if (id) {
        this.currentNbCheckpointId = id;

        if (!this.$route.query.nobordistCheckpointId) {
          this.$router.push({ query: { nobordistCheckpointId: id } });
        }

        this.$helpers.openModal("StatusModalNobordistCheckpointsView");
        return;
      }
    },
    getCheckpointName(checkpoint_id) {
      const checkpointFound = this.checkpoints.filter(
        (checkpoint) => checkpoint.id === checkpoint_id,
      );

      if (checkpointFound && checkpointFound[0]) {
        return checkpointFound[0].title;
      }

      return "-";
    },
    loadCheckpoints() {
      this.checkpoints = this.$store.state["nobordist_checkpoints"].all_items;
      this.checkpointsOption = this.checkpoints.map((item) => ({
        value: item.id,
        text: `(${item.tracking_code}) ${item.title}`,
      }));
    },
    initializeStatusCheckpoint(data) {
      if (data) {
        this.newstatusCheckpoint.id = data.id;
        this.newstatusCheckpoint.status_code = data.status_code;
        this.newstatusCheckpoint.tracking_function = data.tracking_function;
        this.newstatusCheckpoint.checkpoint_id = data.checkpoint_id;
        this.newstatusCheckpoint.created_at = data.created_at;
        this.newstatusCheckpoint.updated_at = data.updated_at;
      } else {
        this.newstatusCheckpoint.status_code = "";
        this.newstatusCheckpoint.tracking_function = "";
        this.newstatusCheckpoint.checkpoint_id = "";
      }
      this.errors = [];
    },
    addStatusCheckpoint() {
      statusCheckpointService
        .setStatusCheckpoint(this.newstatusCheckpoint)
        .then(() => {
          document.getElementById("addStatusCheckpointClose").click();
          this.loadItems();
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
          NProgress.done();
        });
    },
    uploadStatusCheckpoints() {
      statusCheckpointService
        .updateStatusCheckpoint(
          this.newstatusCheckpoint.id,
          this.newstatusCheckpoint,
        )
        .then(() => {
          document.getElementById("editStatusCheckpointClose").click();
          //$('#editStatusCheckpoint').modal('hide');
          this.loadItems();
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
          NProgress.done();
        });
    },
  },
  computed: {
    tableColumns() {
      return [
        {
          key: "status_code",
          label: this.$t("statusCheckpointsPage.fields.statusCode"),
        },
        {
          key: "tracking_function",
          label: this.$t("statusCheckpointsPage.fields.trackingFunction"),
        },
        {
          key: "checkpoint_name",
          label: this.$t("statusCheckpointsPage.fields.checkpoint"),
        },
      ];
    },
    tableFilters() {
      return [
        {
          label: "ID",
          inputs: [{ key: "id" }],
        },
        {
          label: this.$t("statusCheckpointsPage.fields.statusCode"),
          inputs: [{ key: "status_code" }],
        },
        {
          label: this.$t("statusCheckpointsPage.fields.trackingFunction"),
          inputs: [{ key: "tracking_function" }],
        },
        {
          label: "Checkpoint",
          inputs: [
            {
              key: "checkpoint_id",
              type: "select",
              options: [
                { value: null, text: "Select" },
                ...this.checkpointsOption,
              ],
            },
          ],
        },
        {
          label: this.$t("registrationDate"),
          inputs: [
            {
              key: "start_registration_date",
              type: "date",
            },
            { key: "end_registration_date", type: "date" },
          ],
        },
      ];
    },
  },
  watch: {},
};
</script>
