<template>
  <div>
    <NbModal
      :id="id"
      modalConfig="modal-dialog-centered"
      width="45rem"
      @close="errors = {}"
    >
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">
            {{
              shippingRule.id
                ? `Edit ${shippingRule.name}`
                : $t("shippingRulesPage.addNewRule")
            }}
          </h5>
        </div>
      </template>
      <template v-slot:body>
        <div class="d-flex justify-content-between flex-wrap">
          <NbTextInput
            required
            id="shipping-rule-name"
            class="w-49-5 my-2"
            :name="$t('shippingRulesPage.name')"
            :error="errors.name"
            v-model="newShippingRule.name"
          />

          <NbDatePicker
            id="expiration_date"
            class="w-49-5 my-2"
            :name="$t('shippingRulesPage.expiration')"
            size="sm"
            :error="errors.expiration"
            @shortDate="newShippingRule.expiration_short = $event"
            v-model="newShippingRule.expiration"
          />
          <NbSelectInput
            required
            id="shipping_rule_priority"
            class="w-49-5 my-2"
            :name="$t('shippingRulesPage.priority')"
            :options="rulePriorityOptions"
            :error="errors.priority"
            v-model="newShippingRule.priority"
          />

          <NbSelecMultipletInput
            required
            id="seller-id-shipping-rule"
            class="w-49-5 my-2"
            :name="$t('shippingRulesPage.selectMultipleSellers')"
            placeholder="Select Seller"
            :options="allSellerOptions"
            :error="errors.seller"
            v-model="newShippingRule.seller_ids"
          />
          <NbSwitch
            id="rule-active"
            class="w-49-5 my-2"
            :name="$t('shippingRulesPage.is_active')"
            :helpText="$t('shippingRulesPage.is_active_helptext')"
            v-model="newShippingRule.active"
          />
        </div>
        <div
          v-if="newShippingRule.seller_ids?.length > 0"
          class="d-flex justify-content-between flex-wrap"
        >
          <NbCard id="addconditions-card" class="px-3 mt-3 w-100">
            <template #body>
              <div id="new-condition-form" class="mb-2">
                <div class="mt-2 heading-3">
                  {{ $t("shippingRulesPage.createConditionTitle") }}
                </div>
                <div class="mt-2 body-3">
                  {{ $t("shippingRulesPage.createCondition") }}
                </div>
              </div>
              <div
                class="d-flex justify-content-between flex-wrap mt-2 border-top border-gray-20"
              >
                <NbSelectInput
                  id="condition-condition_name"
                  class="w-32-5 my-2"
                  :name="$t('shippingRulesPage.condition')"
                  placeholder="Select Condition"
                  :options="ruleConditionOptions"
                  v-model="newCondition.condition_name"
                />
                <!-- @change="resetConditionValue(index)" -->
                <div
                  v-if="
                    [
                      'Seller Price',
                      'Number of Items',
                      'Shipment Weight',
                    ].includes(newCondition?.condition_name)
                  "
                  class="w-65 d-flex justify-content-between flex-wrap"
                >
                  <NbSelectInput
                    required
                    id="condition-operator"
                    class="w-49-5 my-2"
                    :name="$t('shippingRulesPage.operator')"
                    placeholder="Select Operator"
                    :options="ruleConditionOperatorOptions"
                    v-model="newCondition.operator"
                  />

                  <NbTextInput
                    v-if="newCondition?.condition_name === 'Seller Price'"
                    required
                    id="shipping-rule-val"
                    class="w-49-5 my-2"
                    :name="$t('shippingRulesPage.name')"
                    v-model="newCondition.values"
                  />
                  <NbTextInput
                    v-else-if="
                      newCondition?.condition_name === 'Shipment Weight'
                    "
                    required
                    id="shipping-rule-val"
                    class="w-49-5 my-2"
                    :name="$t('shippingRulesPage.name')"
                    v-model="newCondition.values"
                  />
                  <NbTextInput
                    v-else
                    required
                    id="shipping-rule-val"
                    class="w-49-5 my-2"
                    :name="$t('shippingRulesPage.name')"
                    v-model="newCondition.values"
                  />
                </div>

                <NbSelecMultipletInput
                  v-show="newCondition?.condition_name === 'Contains Category'"
                  required
                  id="shipping-rule-val-cat"
                  class="w-65 my-2"
                  :name="$t('shippingRulesPage.options')"
                  placeholder="Select Category"
                  :options="categoriesOptions"
                  v-model="newCondition.values"
                />

                <NbSelecMultipletInput
                  v-show="newCondition?.condition_name === 'Destination State'"
                  required
                  id="shipping-rule-val-state"
                  class="w-65 my-2"
                  :name="$t('shippingRulesPage.options')"
                  placeholder="Select States"
                  :options="stateOptions"
                  v-model="newCondition.values"
                />

                <NbSelectInput
                  v-if="newCondition?.condition_name === 'Destination Country'"
                  required
                  id="shipping-rule-val"
                  class="w-65 my-2"
                  :name="$t('shippingRulesPage.destinationCountry')"
                  placeholder="Select Country"
                  :options="destinationCountryOptions"
                  v-model="newCondition.values"
                />

                <div
                  v-else-if="
                    newCondition?.condition_name === 'Customer Postal Code'
                  "
                  class="w-65 d-flex justify-content-between flex-wrap"
                >
                  <NbSelectInput
                    required
                    id="shipping-rule-condition-operator"
                    class="w-49-5 my-2"
                    :name="$t('shippingRulesPage.operator')"
                    placeholder="Postal Code..."
                    :options="postalCodeOptions"
                    v-model="newCondition.operator"
                  />
                  <NbTextInput
                    required
                    id="shipping-rule-val"
                    class="w-49-5 my-2"
                    helpText="Insert postal codes separated by commas"
                    :name="$t('shippingRulesPage.value')"
                    v-model="newCondition.values"
                  />
                </div>

                <div
                  v-else-if="newCondition?.condition_name === 'Item SKU'"
                  class="w-65 d-flex justify-content-between flex-wrap"
                >
                  <NbSelectInput
                    required
                    id="shipping-rule-condition-operator"
                    class="w-49-5 my-2"
                    :name="$t('shippingRulesPage.operator')"
                    placeholder="Select Rule"
                    :options="skuOptions"
                    v-model="newCondition.operator"
                  />
                  <NbTextInput
                    required
                    id="shipping-rule-val"
                    class="w-49-5 my-2"
                    helpText="Insert SKU numbers from Settings > Products separated by commas"
                    :name="$t('shippingRulesPage.value')"
                    v-model="newCondition.values"
                  />
                </div>

                <NbSelecMultipletInput
                  v-show="newCondition?.condition_name === 'Channel'"
                  required
                  id="shipping-rule-val-channel"
                  class="w-65 my-2"
                  :name="$t('shippingRulesPage.options')"
                  placeholder="Select Channel"
                  :options="channelOptions"
                  v-model="newCondition.values"
                />

                <div class="w-100"></div>
                <div class="w-10"></div>
                <NbButton
                  variant="secondary"
                  :disabled="checkConditions"
                  @click="addCondition"
                >
                  {{ $t("shippingRulesPage.addCondition") }}
                </NbButton>
              </div>
            </template>
          </NbCard>

          <NbCard
            v-if="newShippingRule.conditions_attributes?.length > 0"
            id="conditions-card"
            class="px-3 mt-3 w-100 bg-gray-05"
          >
            <template #body>
              <div class="mb-2">
                <div class="mt-2 heading-3">
                  {{ $t("shippingRulesPage.conditions") }}
                </div>
              </div>
              <div
                v-for="(
                  condition_attributes, index
                ) in newShippingRule.conditions_attributes"
                :key="condition_attributes.id"
                class="my-3 pt-3 border-top border-gray-20 d-flex justify-content-between flex-wrap"
              >
                <div>
                  <div
                    v-show="condition_attributes?.condition_name"
                    class="mb-2"
                  >
                    <strong>
                      {{ condition_attributes?.condition_name }}
                    </strong>
                  </div>
                  <div
                    v-if="
                      errors[
                        `shipping_rules_conditions[${index}].rule_condition`
                      ]
                    "
                    class="invalid-feedback"
                  >
                    <strong> {{ $t("shippingRulesPage.condition") }}: </strong>
                    {{
                      errors[
                        `shipping_rules_conditions[${index}].rule_condition`
                      ][0]
                    }}
                  </div>

                  <div v-show="condition_attributes.operator">
                    <strong>{{ $t("shippingRulesPage.operator") }}: </strong>
                    {{ getOperatorText(condition_attributes.operator) }}
                  </div>

                  <div
                    v-if="
                      errors[`shipping_rules_conditions[${index}].operator`]
                    "
                    class="invalid-feedback"
                  >
                    <strong>{{ $t("shippingRulesPage.operator") }}: </strong>
                    {{
                      errors[`shipping_rules_conditions[${index}].operator`][0]
                    }}
                  </div>

                  <div v-show="condition_attributes.values">
                    <strong>{{ $t("shippingRulesPage.value") }}: </strong>
                    {{ getValueText(condition_attributes.values) }}
                  </div>

                  <div
                    v-if="errors[`shipping_rules_conditions[${index}].values`]"
                    class="invalid-feedback"
                  >
                    <strong>{{ $t("shippingRulesPage.value") }}: </strong
                    >{{
                      errors[`shipping_rules_conditions[${index}].values`][0]
                    }}
                  </div>
                </div>
                <div>
                  <NbButton
                    variant="tertiary"
                    icon="edit"
                    @click="editConditionAttribute(index)"
                  >
                    {{ $t("edit") }}
                  </NbButton>
                  <NbButton
                    variant="tertiary"
                    icon="trash"
                    @click="deleteConditionAttribute(index)"
                  >
                    {{ $t("delete") }}
                  </NbButton>
                </div>
              </div>
            </template>
          </NbCard>

          <!-- v-show="this.newShippingRule.conditions_attributes?.length > 0" -->
          <NbCard id="addactions-card" class="px-3 mt-3 w-100">
            <template #body>
              <div class="mb-2">
                <div class="mt-2 heading-3">
                  {{ $t("shippingRulesPage.createActionTitle") }}
                </div>
                <div class="mt-2 body-3">
                  {{ $t("shippingRulesPage.createAction") }}
                </div>
              </div>
              <div
                class="d-flex justify-content-between flex-wrap mt-2 border-top border-gray-20"
              >
                <NbSelectInput
                  required
                  id="shipping_rule_action"
                  class="w-49-5 my-2"
                  :name="$t('shippingRulesPage.createActionTitle')"
                  :options="ruleActionOptions"
                  :error="
                    errors[`shipping_rules_actions[0].rule_action`] ||
                    errors[`shipping_rules_actions[0].base`]
                  "
                  @input="resetActionValue()"
                  v-model="newShippingRule.actions_attributes[0].action_name"
                />

                <div
                  v-if="isLoaded"
                  class="w-49-5 d-flex justify-content-between flex-wrap"
                >
                  <NbSelectInput
                    v-show="
                      newShippingRule.actions_attributes[0].action_name ===
                        'Always use this Incoterm' ||
                      newShippingRule.actions_attributes[0].action_name ===
                        'Use this Incoterm if no data given'
                    "
                    required
                    id="action-rule-values"
                    class="w-100 my-2"
                    :name="$t('shippingRulesPage.options')"
                    :options="taxesAndDutiesOptions"
                    :error="errors[`shipping_rules_actions[0].values`]"
                    v-model="newShippingRule.actions_attributes[0].values"
                  />
                  <NbSelectInput
                    v-show="
                      newShippingRule.actions_attributes[0].action_name ===
                        'Always use this Courier' ||
                      newShippingRule.actions_attributes[0].action_name ===
                        'Use this Courier if no data given'
                    "
                    required
                    id="action-rule-values-courier"
                    class="w-100 my-2"
                    :name="$t('shippingRulesPage.options')"
                    :options="courierOptions"
                    :error="errors[`shipping_rules_actions[0].values`]"
                    v-model="newShippingRule.actions_attributes[0].values"
                  />
                  <NbSelecMultipletInput
                    v-show="
                      newShippingRule.actions_attributes[0].action_name ===
                      'Never use Courier(s)'
                    "
                    required
                    id="action-rule-values-never-courier"
                    class="w-100 my-2"
                    :name="$t('shippingRulesPage.options')"
                    :options="courierOptions"
                    :error="errors[`shipping_rules_actions[0].values`]"
                    v-model="newShippingRule.actions_attributes[0].values"
                  />
                  <NbTextInput
                    v-if="
                      newShippingRule.actions_attributes[0].action_name ===
                      'Define freight value to be'
                    "
                    required
                    id="action-rule-values-freight-val"
                    class="w-100 my-2"
                    helpText="The value informed will be in the currency of the order:
                    USD or BRL"
                    prepend="$ or R$"
                    prependStyle="width: 75px;"
                    placeholder="0.00"
                    :name="$t('shippingRulesPage.freightValue')"
                    type="money"
                    :decimals="2"
                    :min="0.0"
                    :error="errors[`shipping_rules_actions[0].values`]"
                    v-model="newShippingRule.actions_attributes[0].values"
                  />
                  <NbSelectInput
                    v-show="
                      (newShippingRule.actions_attributes[0].action_name ===
                        'Always use this Package' ||
                        newShippingRule.actions_attributes[0].action_name ===
                          'Use this Package if no data given') &&
                      newShippingRule.seller_ids?.length == 1
                    "
                    required
                    id="action-rule-values-use-package"
                    class="w-100 my-2"
                    :name="$t('shippingRulesPage.options')"
                    :options="boxesOptions"
                    :error="errors[`shipping_rules_actions[0].values`]"
                    v-model="newShippingRule.actions_attributes[0].values"
                  />
                  <NbSelecMultipletInput
                    v-show="
                      newShippingRule.actions_attributes[0].action_name ===
                        'Never use Package(s)' &&
                      newShippingRule.seller_ids?.length == 1
                    "
                    required
                    id="action-rule-values-never-package"
                    class="w-100 my-2"
                    :name="$t('shippingRulesPage.options')"
                    :options="boxesOptions"
                    :error="errors[`shipping_rules_actions[0].values`]"
                    v-model="newShippingRule.actions_attributes[0].values"
                  />
                  <div
                    v-if="
                      (newShippingRule.actions_attributes[0].action_name ===
                        'Always use this Package' ||
                        newShippingRule.actions_attributes[0].action_name ===
                          'Never use Package(s)' ||
                        newShippingRule.actions_attributes[0].action_name ===
                          'Use this Package if no data given') &&
                      newShippingRule.seller_ids?.length !== 1
                    "
                    class="w-100 my-2"
                  >
                    <Alert
                      type="warning"
                      icon="fas fa-exclamation-circle"
                      message="This action only works for one single
                      seller, you can not use it with multiple sellers"
                    />
                  </div>
                </div>
                <span v-else class="w-49-5">
                  <NbIcon
                    icon="loader"
                    :attributes="{
                      class: 'mt-4 spin',
                      width: '100%',
                      height: '3rem',
                    }"
                  />
                </span>
                <Alert
                  v-if="
                    newShippingRule.actions_attributes[0].action_name ===
                      'Always use this Courier' ||
                    newShippingRule.actions_attributes[0].action_name ===
                      'Never use Courier(s)' ||
                    newShippingRule.actions_attributes[0].action_name ===
                      'Use this Courier if no data given'
                  "
                  class="mb-2 w-100"
                  :aling="'center'"
                  type="success"
                  message="Only the contracts available to all the selected sellers are
                    displayed"
                />
              </div>
            </template>
          </NbCard>
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            :data-target="`#${id}`"
            @click="errors = {}"
          >
            {{ $t("close") }}
          </NbButton>
          <NbButton
            :disabled="newShippingRule.seller_ids?.length < 1"
            @click="finishShippingRule()"
          >
            {{ newShippingRule.id ? $t("update") : $t("save") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbDatePicker from "@/components/generic/NbDatePicker.vue";
import NbSwitch from "@/components/buttons/NbSwitch.vue";
import NbSelecMultipletInput from "@/components/input/select/NbSelecMultipletInput.vue";
import NbCard from "@/components/cards/NbCard.vue";
import Alert from "@/components/alerts/Alert.vue";
import NbIcon from "@/components/icons/NbIcon.vue";

import SalesChannelsManagementService from "@/services/SalesChannelManagementService";
import ContractService from "@/services/ContractService";
import BoxService from "@/services/BoxService";
import ShippingRulesService from "@/services/ShippingRulesService";

const salesChannelManagementService = new SalesChannelsManagementService();
const contractService = new ContractService();
const boxService = new BoxService();
const shippingRulesService = new ShippingRulesService();

import { HS_CODES_UNITED, BRAZIL_STATES } from "@/constants.js";
const hscodes_categories = HS_CODES_UNITED;
const states = BRAZIL_STATES;

export default {
  components: {
    NbModal,
    NbTextInput,
    NbButton,
    NbSelectInput,
    NbDatePicker,
    NbSwitch,
    NbSelecMultipletInput,
    NbCard,
    Alert,
    NbIcon,
  },
  name: "ModalShippingRuleCreate",
  props: {
    id: {
      type: String,
      default: "ModalShippingRuleCreate",
    },
    shippingRule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showNewCondition: true,
      newCondition: {
        condition_name: null, // string
        operator: null, // string enum
        values: null, // array of string values
      },
      availableSalesChannels: [],
      errors: {},
      contracts: [],
      boxes: [],
      resetControl: false,
      isLoaded: true,
    };
  },
  created() {
    this.$store.dispatch("sellers/fetchAllItems", "sellers");
    this.getAndConfigureSalesChannels();
  },
  computed: {
    newShippingRule: {
      get() {
        return this.shippingRule;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    checkConditions() {
      if (this.newCondition?.condition_name === "All Shipments") {
        return false;
      }
      if (!this.newCondition.values) {
        return true;
      }
      if (
        Array.isArray(this.newCondition.values) &&
        this.newCondition.values.length < 1
      ) {
        return true;
      }
      if (
        [
          "Seller Price",
          "Number of Items",
          "Shipment Weight",
          "Customer Postal Code",
          "Item SKU",
        ].includes(this.newCondition?.condition_name)
      ) {
        if (!this.newCondition.operator) {
          return true;
        }
      }
      return false;
    },
    rulePriorityOptions() {
      return [
        {
          value: 1,
          text: this.$t("shippingRulesPage.rulePriorityOptions.veryHigh"),
        },
        {
          value: 2,
          text: this.$t("shippingRulesPage.rulePriorityOptions.high"),
        },
        {
          value: 3,
          text: this.$t("shippingRulesPage.rulePriorityOptions.medium"),
        },
        {
          value: 4,
          text: this.$t("shippingRulesPage.rulePriorityOptions.low"),
        },
        {
          value: 5,
          text: this.$t("shippingRulesPage.rulePriorityOptions.veryLow"),
        },
      ];
    },
    taxesAndDutiesOptions() {
      return [
        { value: "DDP", text: "DDP" },
        { value: "DDU", text: "DDU" },
      ];
    },
    courierOptions() {
      if (this.contracts) {
        return this.contracts.map((courier) => ({
          value: courier.id,
          text: courier.name,
        }));
      }
      return [];
    },
    boxesOptions() {
      let boxList = this.boxes.map((box) => ({
        value: box.id,
        text: box.name,
      }));
      return boxList;
    },
    channelOptions() {
      const channelList = this.availableSalesChannels.map(({ id, name }) => ({
        value: id,
        text: `(${id}) ${name}`,
      }));
      return channelList;
    },
    allOperator() {
      return {
        greater_than: "Greater Than",
        greater_than_or_equal_to: "Greater Than Or Equal To",
        less_than: "Less Than",
        less_than_or_equal_to: "Less Than Or Equal To",
        equals_to: "Equals To",
        is_any_of: "Is any of",
        is_none_of: "Is none of",
        starts_with: "Starts with",
        shipment_contains: "Shipment contains",
        shipment_does_not_contain: "Shipment does not contain",
        shipment_only_contains: "Shipment only contains",
      };
    },
    skuOptions() {
      // this.resetConditionValue();
      return [
        { value: "shipment_contains", text: "Shipment contains" },
        {
          value: "shipment_does_not_contain",
          text: "Shipment does not contain",
        },
        { value: "shipment_only_contains", text: "Shipment only contains" },
      ];
    },
    postalCodeOptions() {
      // this.resetConditionValue();
      return [
        { value: "is_any_of", text: "Is any of" },
        { value: "is_none_of", text: "Is none of" },
        { value: "starts_with", text: "Starts with" },
      ];
    },
    destinationCountryOptions() {
      return [{ value: "BR", text: "Brazil" }];
    },
    stateOptions() {
      // this.resetConditionValue();
      let statesList = [];
      Object.entries(states).forEach((state) => {
        statesList.push({ value: state[1], text: state[0] });
      });
      return statesList;
    },
    categoriesOptions() {
      let categoriesList = [];
      Object.entries(hscodes_categories).forEach((entry) => {
        categoriesList.push({ value: entry[1], text: entry[0] });
      });
      return categoriesList;
    },
    ruleConditionOptions() {
      // this.resetConditionValue();
      const conditions = JSON.parse(
        JSON.stringify(this.newShippingRule.conditions_attributes),
      );
      let templateOptions = [
        { value: "All Shipments", text: "All Shipments", disabled: false },
        {
          value: "Contains Category",
          text: "Contains Category",
          disabled: false,
        },
        {
          value: "Destination Country",
          text: "Destination Country",
          disabled: false,
        },
        {
          value: "Destination State",
          text: "Destination State",
          disabled: false,
        },
        { value: "Item SKU", text: "Item Sku", disabled: false },
        { value: "Number of Items", text: "Number Of Items", disabled: false },
        {
          value: "Customer Postal Code",
          text: "Receiver Postal Code",
          disabled: false,
        },
        { value: "Seller Price", text: "Seller Price", disabled: false },
        { value: "Shipment Weight", text: "Shipment Weight", disabled: false },
        { value: "Channel", text: "Channel", disabled: false },
      ];

      templateOptions.forEach((option, index) => {
        conditions.forEach((condition) => {
          if (templateOptions[index].value == condition.condition_name) {
            templateOptions[index].disabled = true;
          }
        });
      });
      return templateOptions;
    },
    ruleActionOptions() {
      let templateOptions;
      templateOptions = [
        {
          value: "Always use this Incoterm",
          text: "Always Use Incoterm",
          disabled: false,
        },
        {
          value: "Use this Incoterm if no data given",
          text: "Use Incoterm (If No Data Given)",
          disabled: false,
        },
        {
          value: "Always use this Courier",
          text: "Always Use This Courier",
          disabled: false,
        },
        {
          value: "Use this Courier if no data given",
          text: "Use This Courier (If No Data Given)",
          disabled: false,
        },
        {
          value: "Never use Courier(s)",
          text: "Never Use Courier(s)",
          disabled: false,
        },
        {
          value: "Always use this Package",
          text: "Always Use This Package",
          disabled: false,
        },
        {
          value: "Use this Package if no data given",
          text: "Use This Package (If No Data Given)",
          disabled: false,
        },
        {
          value: "Never use Package(s)",
          text: "Never Use Package(s)",
          disabled: false,
        },
        {
          value: "Define freight value to be",
          text: "Define Freight Value To Be",
          disabled: false,
        },
        {
          value: "Define freight value from freight cost",
          text: "Freight Value From Cost",
          disabled: false,
        },
      ];
      return templateOptions;
    },
    ruleConditionOperatorOptions() {
      // this.resetConditionValue();
      return [
        { value: "greater_than", text: "Greater Than" },
        { value: "greater_than_or_equal_to", text: "Greater Than Or Equal To" },
        { value: "less_than", text: "Less Than" },
        { value: "less_than_or_equal_to", text: "Less Than Or Equal To" },
        { value: "equals_to", text: "Equals To" },
      ];
    },
    allSellers() {
      return this.$store.state.sellers.all_items;
    },
    allSellerOptions() {
      const sellerOptions = this.allSellers.map((item) => ({
        value: item.id,
        text: `(${item.id}) ${item.name}`,
      }));
      return sellerOptions;
    },
  },
  methods: {
    finishShippingRule() {
      this.errors = {};
      if (this.newShippingRule.id) {
        this.updateShippingRule();
        return;
      }
      this.saveShippingRule();
    },
    saveShippingRule() {
      let dataToSend = this.buildRuleValues();
      dataToSend["source"] = "admin";

      dataToSend["shipping_rules_conditions_attributes"] =
        dataToSend["conditions_attributes"];
      delete dataToSend["conditions_attributes"];
      dataToSend["shipping_rules_actions_attributes"] =
        dataToSend["actions_attributes"];
      delete dataToSend["actions_attributes"];
      shippingRulesService
        .setShippingRule(dataToSend)
        .then(() => {
          this.$helpers.closeModal(this.id);
          this.$emit("reloadShippingRules");
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.messages
          ) {
            this.errors = error.response.data.messages[0];
          } else {
            this.$bvToast.toast(`Error saving shipping rule`, {
              title: "Error",
              variant: "danger",
              solid: true,
              autoHideDelay: 4000,
            });
          }
        });
      return;
    },
    updateShippingRule() {
      let dataToSend = this.buildRuleValues();
      dataToSend["source"] = "admin";

      dataToSend["shipping_rules_conditions_attributes"] =
        dataToSend["conditions_attributes"];
      delete dataToSend["conditions_attributes"];
      dataToSend["shipping_rules_actions_attributes"] =
        dataToSend["actions_attributes"];
      delete dataToSend["actions_attributes"];
      shippingRulesService
        .updateShippingRule(dataToSend.id, dataToSend)
        .then(() => {
          this.$helpers.closeModal(this.id);
          this.$emit("reloadShippingRules");
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.messages
          ) {
            this.errors = error.response.data.messages[0];
            this.$bvToast.toast(
              `Error updating shipping rule ${
                this.errors.priority ? ": " + this.errors.priority[0] : ""
              }`,
              {
                title: "Error",
                variant: "danger",
                solid: true,
                autoHideDelay: 4000,
              },
            );
          } else {
            this.$bvToast.toast(`Error updating shipping rule`, {
              title: "Error",
              variant: "danger",
              solid: true,
              autoHideDelay: 4000,
            });
          }
        });
    },
    resetActionValue() {
      let action = this.newShippingRule.actions_attributes[0];
      if (
        action.action_name === "Never use Courier(s)" ||
        action.action_name === "Always use this Courier" ||
        action.action_name === "Use this Courier if no data given"
      ) {
        this.loadContracts();
      }
      if (
        action.action_name === "Never use Package(s)" ||
        action.action_name === "Always use this Package" ||
        action.action_name === "Use this Package if no data given"
      ) {
        this.loadBoxes();
      }
      let dataToSend = this.buildRuleValues();
      if (dataToSend.id && this.resetControl) {
        return;
      }
      if (
        !action.action_name === "Always sort by Courier" ||
        !action.action_name === "Sort by Courier if no data given" ||
        !action.action_name === "Always use this Incoterm" ||
        !action.action_name === "Use this Incoterm if no data given" ||
        !action.action_name === "Always use this Package" ||
        !action.action_name === "Use this Package if no data given" ||
        !action.action_name === "Always use this Courier" ||
        !action.action_name === "Use this Courier if no data given"
      ) {
        action.values = [];
      } else {
        action.values = "";
      }
    },
    loadContracts() {
      this.isLoaded = false;
      contractService
        .getActiveContractsForSellers(this.newShippingRule.seller_ids)
        .then((response) => {
          this.contracts = response.data.data;
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },
    loadBoxes() {
      this.isLoaded = false;
      boxService
        .getBoxesFromSellers(this.newShippingRule.seller_ids)
        .then((response) => {
          this.boxes = response.data.data;
        })
        .finally(() => {
          this.isLoaded = true;
        });
    },
    buildRuleValues() {
      let dataToSend = JSON.parse(JSON.stringify(this.newShippingRule));
      dataToSend.conditions_attributes.forEach((condition) => {
        if (
          typeof condition.values == "string" ||
          typeof condition.values == "number"
        ) {
          condition.values = condition.values.toString().split(/\s*,\s*/);
        }
      });
      dataToSend.actions_attributes.forEach((action) => {
        if (
          typeof action.values == "string" ||
          typeof action.values == "number"
        ) {
          action.values = action.values.toString().split(/\s*,\s*/);
        }
      });
      return dataToSend;
    },
    scrollToId(id) {
      if (id) {
        const elmnt = document.getElementById(id);
        elmnt.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    },
    editConditionAttribute(index) {
      const condition_attributes = {
        ...this.newShippingRule.conditions_attributes[index],
      };

      this.newCondition.condition_name =
        condition_attributes.condition_name || "";

      //setTimeout used to avoid reset this.newCondition
      setTimeout(() => {
        this.newCondition.operator = condition_attributes.operator || "";

        this.newCondition.values = condition_attributes.values || "";
      }, 100);

      this.deleteConditionAttribute(index);
      this.scrollToId("new-condition-form");
    },
    deleteConditionAttribute(index) {
      this.newShippingRule.conditions_attributes.splice(index, 1);
    },
    getOperatorText(operator) {
      return this.allOperator[operator];
    },
    getValueText(values) {
      if (Array.isArray(values)) {
        const finalResult = values.join(", ");
        return finalResult;
      }
      return values;
    },
    addCondition() {
      const condition = { ...this.newCondition };
      this.newShippingRule.conditions_attributes.push(condition);
      this.newCondition = {
        condition_name: null, // string
        operator: null, // string enum
        values: null, // array of string values
      };

      /* this.newShippingRule.conditions_attributes.push(this.newCondition);
      this.newCondition = {
        condition_name: null, // string
        operator: null, // string enum
        values: null, // array of string values
      }; */

      /*
      this.setNewAdditionalServiceName();
      this.loadedAdditionalServices.push(this.newAdditionalService);
      this.newAdditionalService = {};
      */
    },
    getAndConfigureSalesChannels() {
      salesChannelManagementService.getSalesChannels().then(({ data }) => {
        this.availableSalesChannels = data.data;
      });
    },
    resetQuery() {
      this.$router.push({ query: {} });
    },
  },
  watch: {
    /* watch attribute */
    "newCondition.condition_name": function (newVal) {
      if (this.newShippingRule.id && this.resetControl) {
        return;
      }
      this.newCondition.operator = "";
      if (
        ["Contains Category", "Destination State", "Channel"].includes(newVal)
      ) {
        this.newCondition.values = [];
        return;
      }

      this.newCondition.values = "";
    },
  },
};
</script>
