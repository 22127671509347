<template>
  <div>
    <NbPageTitle
      class="pb-3 page-container"
      :title="$t('trackingRulesPage.title')"
      :subTitle="$t('trackingRulesPage.subTitle')"
    >
      <div class="btn-groups d-flex flex-column">
        <NbButton
          class="text-left"
          data-toggle="modal"
          data-target="#addTrackingRule"
          @click="initializeTrackingRule()"
        >
          <NbIcon class="mr-2" icon="plus" />
          {{ $t("trackingRulesPage.addTrackingRule") }}
        </NbButton>
      </div>
    </NbPageTitle>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />

      <NbTablev2
        tableOf="trackingRulesTable"
        :namespace="namespace"
        ref="trackingRulesTable"
        :clickable="true"
        :allFields="allFields"
        :fields="fields"
        :filterOptions="filterOptions"
        @reloadFields="fields = $event"
        @total="totalTrackingRules = $event"
        @clickedRow="
          loadModalData($event.id, 'trackingRulesId', 'ModalTrackingRuleView')
        "
      >
        <template #cell(start_checkpoint_id)="data">
          <div
            class="link-1"
            @click="
              loadModalData(
                data.item.start_checkpoint_id,
                'nobordistCheckpointId',
                'ModalNobordistCheckpointsView',
              )
            "
          >
            {{ loadCheckpointName(data.item.start_checkpoint_id) }}
          </div>
        </template>
        <template #cell(end_checkpoint_id)="data">
          <div
            class="link-1"
            @click="
              loadModalData(
                data.item.end_checkpoint_id,
                'nobordistCheckpointId',
                'ModalNobordistCheckpointsView',
              )
            "
          >
            {{ loadCheckpointName(data.item.end_checkpoint_id) }}
          </div>
        </template>
        <template #cell(seller_id)="data">
          <div>
            <router-link
              class="link-1"
              :to="`/users_management/sellers?id=` + data.item.seller_id"
            >
              {{ loadSellerName(data.item.seller_id) }}
            </router-link>
          </div>
        </template>
        <template #cell(contract_id)="data">
          <div>
            <router-link
              class="link-1"
              :to="`/contracts/contracts?contractId=${data.item.contract_id}`"
            >
              {{ loadContractName(data.item.contract_id) }}
            </router-link>
          </div>
        </template>
      </NbTablev2>
    </section>

    <NbFooter
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />

    <!-- modals -->
    <ModalNobordistCheckpointsView :itemId="currentItemId" />

    <ModalTrackingRuleView
      :itemId="currentItemId"
      @reloadTrackingRules="reloadTrackingRules"
    />

    <ModalTrackingRuleCreate
      id="addTrackingRule"
      :trackingRule.sync="newTrackingRule"
      @reloadTrackingRules="reloadTrackingRules"
    />
    <!-- end modal -->
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import ModalTrackingRuleView from "@/views/logs/tracking_rules/components/ModalTrackingRuleView.vue";
import ModalTrackingRuleCreate from "@/views/logs/tracking_rules/components/ModalTrackingRuleCreate.vue";
import ModalNobordistCheckpointsView from "@/views/checkpoints/nobordist_checkpoints/components/ModalNobordistCheckpointsView.vue";

import { mapActions } from "vuex";

export default {
  name: "TrackingRules",
  components: {
    NbPageTitle,
    NbIcon,
    NbButton,
    NbTabs,
    NbTablev2,
    NbFooter,
    ModalNobordistCheckpointsView,
    ModalTrackingRuleCreate,
    ModalTrackingRuleView,
  },
  data: () => {
    return {
      trackingRules: [],
      newTrackingRule: {
        name: null,
        start_checkpoint_id: null,
        end_checkpoint_id: null,
        seller_id: null,
        contract_id: null,
        days: null,
        destination: null,
        configuration: {
          log: false,
          notify_seller: false,
          notify_customer: false,
          notify_nobordist: false,
          notify_webhook: false,
          seller_mail_body: null,
          customer_mail_body: null,
          nobordist_mail_body: null,
          seller_mail_title: null,
          customer_mail_title: null,
          nobordist_mail_title: null,
          seller_notification_title: null,
          seller_notification_body: null,
          seller_notification: false,
          webhook: {
            method: null,
            endpoint: null,
            headers: {
              ContentType: "application/json",
            },
            body: null,
          },
        },
      },
      fields: [],
      checkpoints: [],
      sellers: [],
      contracts: [],
      namespace: "tracking_rules",
      currentItemId: "",
      currentTable: "all",
      totalTrackingRules: -1,
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: "all",
          label: this.$t("trackingRulesPage.title"),
          found: this.totalTrackingRules,
          current: this.currentTable,
        },
      ];
    },
    allContracts() {
      return this.$store.state.contracts.all_items;
    },
    allSellers() {
      return this.$store.state.sellers.all_items;
    },
    allCheckpoints() {
      return this.$store.state.nobordist_checkpoints.all_items;
    },
    nobordistCheckpoints() {
      return this.$store.state.nobordist_checkpoints.items;
    },
    allFields() {
      return [
        { key: "id", label: "ID", sortable: false, thClass: ["small-content"] },
        {
          key: "name",
          label: this.$t("trackingRulesPage.fields.name"),
          sortable: false,
          thClass: ["small-content"],
        },
        {
          key: "start_checkpoint_id",
          label: this.$t("trackingRulesPage.fields.startCheckpoint"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "end_checkpoint_id",
          label: this.$t("trackingRulesPage.fields.endCheckpoint"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "incoterm",
          label: this.$t("trackingRulesPage.fields.incoterm"),
          sortable: false,
          thClass: ["small-content"],
        },
        {
          key: "seller_id",
          label: this.$t("trackingRulesPage.fields.seller"),
          sortable: false,
          thClass: ["small-content"],
        },
        {
          key: "contract_id",
          label: this.$t("trackingRulesPage.fields.contract"),
          sortable: false,
          thClass: ["small-content"],
        },
        {
          key: "days",
          label: this.$t("trackingRulesPage.fields.daysLimit"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "tracking_mode",
          label: this.$t("trackingRulesPage.fields.mode"),
          sortable: false,
          thClass: ["small-content"],
        },
        {
          key: "created_at",
          label: this.$t("trackingRulesPage.fields.creationDate"),
          sortable: true,
          thClass: ["medium-content"],
        },
      ];
    },
    filterOptions() {
      return [
        { key: "id", label: "ID" },
        { key: "name", label: this.$t("trackingRulesPage.fields.name") },
        {
          key: "start_checkpoint_id",
          label: this.$t("trackingRulesPage.fields.startCheckpoint"),
          type: "select",
          options: [],
        },
        {
          key: "end_checkpoint_id",
          label: this.$t("trackingRulesPage.fields.endCheckpoint"),
          type: "select",
          options: [],
        },
        {
          key: "seller_id",
          label: this.$t("trackingRulesPage.fields.seller"),
          type: "select",
          options: [],
        },
        {
          key: "contract_id",
          label: this.$t("trackingRulesPage.fields.contract"),
          type: "select",
          options: [],
        },
        {
          key: "tracking_mode",
          label: this.$t("trackingRulesPage.fields.mode"),
          type: "select",
          options: [
            {
              value: "time_between_checkpoints",
              text: "Time Between Checkpoints",
            },
            { value: "received_checkpoint", text: "Received Checkpoint" },
          ],
        },
        {
          key: "incoterm",
          label: this.$t("trackingRulesPage.fields.incoterm"),
          type: "select",
          options: [
            { value: null, text: "Any" },
            { value: "DDP", text: "DDP" },
            { value: "DDU", text: "DDU" },
          ],
        },
        {
          key: "registration_date",
          label: this.$t("registrationDate"),
          type: "dates",
        },
      ];
    },
  },
  watch: {},
  created() {
    this.fields = this.allFields;
    this.setOptions();
  },
  mounted() {
    this.$store.dispatch("contracts/fetchAllItems", "contracts");
    this.$store.dispatch("nobordist_checkpoints/fetchAllItems", "checkpoints");
    this.checkQuery();
  },
  methods: {
    switchTab(tab) {
      this.currentTable = tab;
    },
    reloadTrackingRules() {
      this.$refs?.trackingRulesTable?.getData();
    },
    checkQuery() {
      if (this.$route.query.nobordistCheckpointId) {
        //this.$emit("switchTab", "nobordistCheckpoints");
        this.loadModalData(
          this.$route.query.nobordistCheckpointId,
          "nobordistCheckpointId",
          "ModalNobordistCheckpointsView",
        );
        return;
      }
      if (this.$route.query.trackingRulesId) {
        this.$emit("switchTab", "all");
        this.loadModalData(
          this.$route.query.trackingRulesId,
          "trackingRulesId",
          "ModalTrackingRuleView",
        );
        return;
      }
    },
    loadModalData(id, queryKey, modalId) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query[queryKey]) {
          this.$router.push({ query: { [queryKey]: id } });
        }
        /* this.$router.push({ query: {} }); */

        this.$helpers.openModal(modalId);
        return;
      }
    },
    setOptions() {
      //this.setNobordistCheckpointOption(); //Checar se e necessario-> estava concatenando com this.setAllCheckpointsOption();
      this.setAllCheckpointsOption();
      this.setAllContractsOption();
      this.setAllSellersOption();
    },
    addSelectOptionsToFilter(key, options) {
      this.filterOptions.find(
        (filterOption) => filterOption.key === key,
      ).options = options;
    },
    setNobordistCheckpointOption() {
      const checkpointsOption = this.nobordistCheckpoints.map((item) => ({
        value: item.id,
        text: `(${item.tracking_code}) ${item.title}`,
      }));

      this.addSelectOptionsToFilter("start_checkpoint_id", checkpointsOption);
      this.addSelectOptionsToFilter("end_checkpoint_id", checkpointsOption);
    },
    setAllCheckpointsOption() {
      const allCheckpointsOption = this.allCheckpoints.map((item) => ({
        value: item.id,
        text: `(${item.tracking_code}) ${item.title}`,
      }));
      this.addSelectOptionsToFilter(
        "start_checkpoint_id",
        allCheckpointsOption,
      );
      this.addSelectOptionsToFilter("end_checkpoint_id", allCheckpointsOption);
    },
    setAllSellersOption() {
      const uniqueSellers = this.allSellers.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.id === item.id),
      );
      const sellersOption = uniqueSellers.map((item) => ({
        value: item.id,
        text: `(${item.id}) ${item.name}`,
      }));
      this.addSelectOptionsToFilter("seller_id", sellersOption);
    },
    setAllContractsOption() {
      const uniqueContracts = this.allContracts.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.id === item.id),
      );
      const contractsOption = uniqueContracts.map((item) => ({
        value: item.id,
        text: `(${item.id}) ${item.slug}`,
      }));
      this.addSelectOptionsToFilter("contract_id", contractsOption);
    },
    reloadFields(event) {
      this.fields = event;
    },
    loadCheckpointName(id) {
      if (id) {
        const data = this.nobordistCheckpoints.filter((data) => data.id === id);
        if (data[0]) {
          return data[0].tracking_code + " (" + data[0].name + ")";
        }
      }
    },
    loadSellerName(id) {
      if (id) {
        const data = this.allSellers.filter((data) => data.id === id);
        if (data[0]) {
          return data[0].name;
        }
      }
    },
    loadContractName(id) {
      if (id) {
        const data = this.allContracts.filter((data) => data.id === id);
        if (data[0]) {
          return data[0].name;
        }
      }
    },
    initializeTrackingRule() {
      let tracking_rule = {
        name: null,
        tracking_mode: null,
        start_checkpoint_id: null,
        end_checkpoint_id: null,
        seller_id: null,
        contract_id: null,
        days: null,
        destination: null,
        configuration: {
          log: false,
          notify_seller: false,
          notify_customer: false,
          notify_nobordist: false,
          notify_webhook: false,
          seller_mail_body: null,
          customer_mail_body: null,
          nobordist_mail_body: null,
          seller_mail_title: null,
          customer_mail_title: null,
          nobordist_mail_title: null,
          seller_notification_title: null,
          seller_notification_body: null,
          seller_notification: false,
          webhook: {
            method: null,
            endpoint: null,
            headers: {
              ContentType: "application/json",
            },
            body: null,
          },
        },
      };
      this.newTrackingRule = tracking_rule;
    },
    ...mapActions("trackingRules", {
      fetchTrackingRules: "fetchTrackingRules",
      setCurrentPage: "setCurrentPage",
    }),
  },
};
</script>
