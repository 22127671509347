<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ $t("download") }}
      </ModalTitle>
    </ModalHeader>
    <p class="body-4">{{ $t("shipmentsPage.checkProcessingDownloads") }}.</p>
    <ModalFooter class="d-flex justify-content-end">
      <ModalClose />
      <NbButton class="ml-2" @click="download" :disabled="loading">
        {{ $t("download") }}
      </NbButton>
    </ModalFooter>
  </div>
</template>
<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import DownloadsService from "@/services/DownloadsService";

const downloadsService = new DownloadsService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
  },
  props: {
    shipmentIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async download() {
      try {
        this.loading = true;
        await downloadsService.loadProcessing({
          data_type: "shipments",
          reference_ids: this.shipmentIds,
        });
        this.$emit("success");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
