<template>
  <div>
    <NbPageTitle
      :title="$t('shipmentsPage.title')"
      :subTitle="$t('shipmentsPage.subtitle')"
    />

    <Modal v-model="showConfirmDownloadShipmentsModal">
      <DownloadShipments
        :shipment-ids="selected"
        @success="onDownloadShipments"
      />
    </Modal>

    <Modal
      v-model="showShipmentDetailsModal"
      size="lg"
      @close="onCloseShipmentDetails"
    >
      <Shipment />
    </Modal>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" />

      <NbTablev2
        tableOf="shipmentsTable"
        namespace="shipments"
        ref="shipmentsTableRef"
        :clickable="true"
        :allFields="fields"
        :fields="allFields"
        :tabs="true"
        :optionsWidth="260"
        :filterOptions="filterOptions"
        @reloadFields="allFields = $event"
        @total="totalShipments = $event"
        :buttonOptions="tableOptions"
        @optionTook="optionTook"
        selectable
        :selectedItems.sync="selected"
        @clickedRow="showShipmentDetails($event.id)"
      >
        <template #cell(flight_id)="data">
          <router-link
            class="link-1"
            :to="'/shipping/flights?id=' + data.item.flight_id"
          >
            {{ data.item.flight_id }}
          </router-link>
        </template>

        <template #cell(cn38_label)="data">
          <div>
            <NbButton
              v-if="
                data.item.cn38 && data.item.cn38.substring(0, 9) != 'TEMPORARY'
              "
              variant="tertiary"
              @click="generateShipmentLabel(data.item.id)"
            >
              {{ $t("download") }}
            </NbButton>
            <span v-else>-</span>
          </div>
        </template>

        <template #cell(actual_weight)="data">
          {{ data.item.actual_weight }}
        </template>
      </NbTablev2>
    </section>
  </div>
</template>

<script>
import ShipmentService from "../../../services/ShipmentService";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import DownloadShipments from "./components/DownloadShipments.vue";
import Modal from "@/components/modal/Modal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import queryParamsMixin from "@/mixins/query-params-mixin.js";
import Shipment from "./components/Shipment.vue";

const shipmentService = new ShipmentService();

export default {
  name: "Shipments",
  mixins: [queryParamsMixin],
  components: {
    NbPageTitle,
    NbTablev2,
    DownloadShipments,
    Modal,
    NbTabs,
    NbButton,
    Shipment,
  },
  data: () => {
    return {
      totalShipments: 0,
      currentTab: "all",
      showConfirmDownloadShipmentsModal: false,
      showShipmentDetailsModal: false,
      allFields: [],
      selected: [],
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: this.currentTab,
          label: this.$t("all"),
          current: this.currentTab,
          found: this.totalShipments,
        },
      ];
    },
    fields() {
      return [
        { key: "id", label: "ID" },
        {
          key: "cn38",
          label: this.$t("shipmentsPage.fields.cn38"),
        },
        {
          key: "cn38_label",
          label: this.$t("shipmentsPage.fields.cn38Label"),
        },
        {
          key: "seller_weight",
          label: this.$t("shipmentsPage.fields.sSellerWeight"),
        },
        {
          key: "actual_weight",
          label: this.$t("shipmentsPage.fields.actualWeight"),
        },
        {
          key: "category",
          label: this.$t("shipmentsPage.fields.category"),
        },
        {
          key: "flight_id",
          label: this.$t("shipmentsPage.fields.flightID"),
        },
        {
          key: "created_at",
          label: this.$t("shipmentsPage.fields.creationDate"),
        },
      ];
    },
    tableOptions() {
      return [
        {
          text: this.$t("shipmentsPage.downloadShipments"),
          value: "showConfirmDownloadShipments",
        },
      ];
    },
    filterOptions() {
      return [
        { key: "cn38", label: "cn38" },
        { key: "category", label: this.$t("shipmentsPage.fields.category") },
        {
          key: "seller_start_weight",
          label: this.$t("shipmentsPage.filterOptions.sellerStartWeight"),
          type: "number",
        },
        {
          key: "seller_end_weight",
          label: this.$t("shipmentsPage.filterOptions.sellerEndWeight"),
          type: "number",
        },
        {
          key: "actual_start_weight",
          label: this.$t("shipmentsPage.filterOptions.actualStartWeight"),
          type: "number",
        },
        {
          key: "actual_end_weight",
          label: this.$t("shipmentsPage.filterOptions.actualEndWeight"),
          type: "number",
        },
        {
          key: "flight_id",
          label: this.$t("shipmentsPage.fields.flightID"),
          type: "number",
        },
        {
          key: "registration_date",
          label: this.$t("registrationDate"),
          type: "dates",
        },
      ];
    },
  },
  methods: {
    onCloseShipmentDetails() {
      this.setUrlSearchParam("id");
      this.showShipmentDetailsModal = false;
    },
    showShipmentDetails(shipmentId) {
      this.setUrlSearchParam("id", shipmentId);
      this.showShipmentDetailsModal = true;
    },
    optionTook(event) {
      this[event]();
    },
    onDownloadShipments() {
      this.showConfirmDownloadShipmentsModal = false;
      this.$router.push("/archives");
    },
    showConfirmDownloadShipments() {
      this.showConfirmDownloadShipmentsModal = true;
    },
    generateShipmentLabel(id) {
      shipmentService;
      shipmentService.generateShipmentLabel(id).then((response) => {
        if (
          // eslint-disable-next-line no-prototype-builtins
          response.data.hasOwnProperty("data") &&
          // eslint-disable-next-line no-prototype-builtins
          response.data.data.hasOwnProperty("download_url")
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
  },
  mounted() {
    const { id: shipmentId } = this.$route.query;
    if (shipmentId) {
      this.showShipmentDetails(Number(shipmentId));
    }
  },
};
</script>
