<template>
  <div>
    <NbPageTitle
      class="pb-3 page-container"
      :title="$t('dutiesAndTaxesStatusPage.title')"
      :subTitle="$t('dutiesAndTaxesStatusPage.subTitle')"
    />

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />

      <NbTablev2
        tableOf="dutiesTaxesTable"
        :namespace="namespace"
        ref="dutiesTaxesTable"
        :clickable="false"
        :allFields="allFields"
        :fields="fields"
        :selectable="true"
        :optionsWidth="120"
        @optionTook="optionTook($event)"
        :buttonOptions="buttonOptions"
        :filterOptions="filterOptions"
        :selectedItems.sync="selected"
        @reloadFields="fields = $event"
        @total="totalDutiesTaxes = $event"
        @clickedRow="loadModalData($event.id)"
      >
        <template #cell(seller_name)="data">
          <router-link
            class="link-1"
            :to="`/users_management/sellers?id=` + data.item.seller_id"
          >
            {{ data.item.seller_name }}
          </router-link>
        </template>
        <template #cell(seller_id)="data">
          <div>
            <router-link
              class="link-1"
              :to="`/users_management/sellers?id=` + data.item.seller_id"
              >{{ data.item.seller_id }}</router-link
            >
          </div>
        </template>
        <template #cell(volume_id)="data">
          <div>
            <router-link
              class="link-1"
              :to="`/shipping/volumes?id=` + data.item.volume_id"
              >{{ data.item.volume_id }}</router-link
            >
          </div>
        </template>

        <template #cell(volume_last_mile_tracking_number)="data">
          <div>
            <router-link
              class="link-1"
              :to="`/shipping/volumes?id=` + data.item.volume_id"
              >{{ data.item.volume_last_mile_tracking_number }}</router-link
            >
          </div>
        </template>
        <template #cell(order_number)="data">
          <router-link
            class="link-1"
            :to="`/shipping/orders?id=` + data.item?.volume?.order?.id"
          >
            {{ data.item?.volume?.order?.order_number }}
          </router-link>
        </template>
        <template #cell(current_status_tracking_code)="data">
          {{ data.item.volume.current_status.tracking_code }}
        </template>
        <template #cell(current_status_title)="data">
          {{ data.item.volume.current_status.title }}
        </template>
        <template #cell(order_created_at)="data">
          {{ formatTimestamp(data.item.volume.order.created_at) }}
        </template>
        <template #cell(status_payment_done_at)="data">
          {{ formatTimestamp(data.item.status_payment_done_at) }}
        </template>
        <template #cell(created_at)="data">
          {{ formatTimestamp(data.item.created_at) }}
        </template>
        <template #cell(real_tax)="data">
          {{ formatMoney(data.item.real_tax, "R$") }}
        </template>
        <template #cell(order_estimated_tax_value)="data">
          {{
            formatMoney(
              data.item.volume.estimated_tax_value,
              data.item.volume.order.currency,
            )
          }}
        </template>

        <template #cell(order_estimated_duties_tax_value)="data">
          {{
            formatMoney(
              data.item.volume.estimated_duties_tax_value,
              data.item.volume.order.currency,
            )
          }}
        </template>

        <template #cell(order_estimated_sales_tax_value)="data">
          {{
            formatMoney(
              data.item.volume.estimated_sales_tax_value,
              data.item.volume.order.currency,
            )
          }}
        </template>

        <template #cell(status)="data">
          <NbBadge
            class="w-fit"
            :type="getStatusType(data.item.status)"
            :text="getStatusText(data.item.status)"
          />
        </template>

        <template #cell(payment_certificate)="data">
          <template v-if="!!data.item.barcode">
            <a @click="certificate(data.item.id)">
              <i
                class="far fa-file fa-lg text-dark"
                style="
                  font-size: 18px;
                  position: relative;
                  left: -4px;
                  bottom: 0px;
                "
              ></i>
            </a>
          </template>
          <template v-else> - </template>
        </template>
      </NbTablev2>
    </section>

    <NbFooter
      class="page-container"
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />
    <!-- modals -->
    <NbModal id="download-modal" modalConfig="modal-dialog-centered">
      <template v-slot:header>
        <h5 class="heading-4">Download</h5>
      </template>
      <template v-slot:body>
        {{ $t("dutiesAndTaxesStatusPage.checktheprocessingdownloads") }}
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton
            id="addStatusCheckpointClose"
            class="mx-1"
            variant="secondary"
            data-dismiss="modal"
            @click="$emit('close')"
          >
            {{ $t("close") }}
          </NbButton>
          <NbButton class="mx-1" @click="download()">
            {{ $t("start") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
    <!-- end modals -->
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";

import DutiesAndTaxesStatusService from "@/services/DutiesAndTaxesStatusService";
import DownloadsService from "@/services/DownloadsService";
import CheckpointService from "@/services/CheckpointService";

const dutiesAndTaxesStatusService = new DutiesAndTaxesStatusService();
const downloadsService = new DownloadsService();
const checkpointService = new CheckpointService();

export default {
  name: "DutiesAndTaxes",
  components: {
    NbPageTitle,
    NbTabs,
    NbTablev2,
    NbBadge,
    NbFooter,
    NbModal,
    NbButton,
  },
  data: () => {
    return {
      fields: [],
      selected: [],
      namespace: "duties_and_taxes_status",
      totalDutiesTaxes: -1,
      currentTable: "dutiesTaxes",
      currentItemId: "",
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: "dutiesTaxes",
          label: this.$t("dutiesAndTaxesStatusPage.dutiesTaxes"),
          found: this.totalDutiesTaxes || 0,
          current: this.currentTable,
        },
      ];
    },
    buttonOptions() {
      return [
        {
          text: this.$t("dutiesAndTaxesStatusPage.download"),
          value: "openDownloadModal",
        },
      ];
    },
    allFields() {
      return [
        {
          key: "seller_name",
          label: null,
        },
        {
          key: "seller_id",
          label: null,
        },
        {
          key: "volume_id",
          label: null,
        },
        {
          key: "volume_last_mile_tracking_number",
          label: null,
        },
        {
          key: "order_number",
          label: null,
        },
        {
          key: "current_status_tracking_code",
          label: null,
        },
        {
          key: "current_status_title",
          label: null,
        },
        {
          key: "order_created_at",
          label: null,
        },
        {
          key: "created_at",
          label: null,
        },
        {
          key: "status_payment_done_at",
          label: null,
        },
        {
          key: "status",
          label: null,
        },
        {
          key: "payment_certificate",
          label: null,
        },
        {
          key: "real_tax",
          label: null,
        },
        {
          key: "order_estimated_tax_value",
          label: null,
        },
        {
          key: "order_estimated_duties_tax_value",
          label: null,
        },
        {
          key: "order_estimated_sales_tax_value",
          label: null,
        },
        { key: "id", label: null, sortable: false },
      ].map((field) => {
        if (field.key)
          field.label = this.$t(
            `dutiesAndTaxesStatusPage.fields.${this.$helpers.toCamelCase(
              field.key,
            )}`,
          );

        return field;
      });
    },
    filterOptions() {
      return [
        { key: "id", label: null, type: "number" },
        { key: "seller_id", label: null, type: "number" },
        { key: "seller_name", label: null, type: "string" },
        { key: "order_number", label: null, type: "string" },
        { key: "order_created_at", label: null, type: "date", between: true },
        { key: "volume_id", label: null, type: "number" },
        {
          key: "volume_last_mile_tracking_number",
          label: null,
          type: "string",
        },
        { key: "current_status_id", label: null, type: "select", options: [] },

        { key: "real_tax", label: null, type: "number", between: true },

        { key: "created_at", label: null, type: "date", between: true },
        {
          key: "status",
          label: "Status",
          type: "select",
          options: [
            { value: null, text: "Select" },
            { value: 0, text: "New" },
            { value: 1, text: "Solved" },
            { value: 2, text: "Priority" },
            { value: 3, text: "In Progress" },
            { value: 4, text: "Waiting for Payment" },
            { value: 5, text: "Sent Again" },
            { value: 6, text: "Payment Done" },
            { value: 7, text: "Pending" },
            { value: 8, text: "Paid Externally" },
            { value: 9, text: "Need Seller Action" },
          ],
        },

        {
          key: "status_payment_done_at",
          label: null,
          type: "date",
          between: true,
        },
      ].map((field) => {
        if (field.key)
          field.label = this.$t(
            `dutiesAndTaxesStatusPage.fields.${this.$helpers.toCamelCase(
              field.key,
            )}`,
          );

        return field;
      });
    },
  },
  created() {
    if (
      this.$store.state.user.group_name !== "Platform-Finance" &&
      !this.$store.state.user.is_super
    ) {
      this.$router.push("/");
      return;
    }
    this.fields = this.allFields;
    this.loadCheckpoints();
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    checkQuery() {
      if (this.$route.query.dutiesTaxes) {
        //this.$emit("switchTab", "alertLogs"); //emit if have more than one tab
        this.loadModalData(this.$route.query.dutiesTaxes);
        return;
      }
    },
    loadModalData(id) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query.dutiesTaxes) {
          this.$router.push({ query: { dutiesTaxes: id } });
        }
        /* this.$router.push({ query: {} }); */
        this.$helpers.openModal("ModalAlertLogsView");
        return;
      }
    },
    switchTab(tab) {
      this.currentTable = tab;
    },
    optionTook(event) {
      this[event]();
    },
    openDownloadModal() {
      this.$helpers.openModal("download-modal");
    },
    loadCheckpoints() {
      checkpointService.getAllCheckpoints().then((response) => {
        this.checkpoints = response.data.data.elements;
        const checkpointsOption = this.checkpoints.map((checkpoint) => ({
          value: checkpoint.id,
          text: `(${checkpoint.tracking_code}) ${checkpoint.title}`,
        }));
        this.addSelectOptionsToFilter("current_status_id", checkpointsOption);
      });
    },
    certificate(id) {
      dutiesAndTaxesStatusService.certificate(id).then((response) => {
        window.open(response.data.data.download_url);
      });
    },
    download() {
      downloadsService
        .loadProcessing({
          data_type: "duties_and_taxes_status",
          reference_ids: this.selected,
        })
        .then(() => {
          this.$helpers.closeModal("download-modal");
          this.$router.push("/archives");
        });
    },
    formatTimestamp(string) {
      return this.$helpers.formatTimestamp(string, { hour12: false });
    },
    formatMoney(string, currency) {
      return this.$helpers.formatMoney(string, currency);
    },
    addSelectOptionsToFilter(key, options) {
      this.filterOptions.find(
        (filterOption) => filterOption.key === key,
      ).options = options;
    },

    getStatusType(status) {
      if (status == "solved_issue") {
        return "success";
      }
      if (status == "priority_issue") {
        return "danger";
      }
      if (status == "waiting_payment") {
        return "warning";
      }
      if (status == "payment_done") {
        return "success";
      }
      if (status == "pending") {
        return "warning";
      }
      if (status == "paid_external") {
        return "success";
      }
      if (status == "need_seller") {
        return "danger";
      }
      return "";
    },
    getStatusText(status) {
      if (status == "new_issue") {
        return this.$t("dutiesAndTaxesStatusPage.new");
      }
      if (status == "solved_issue") {
        return this.$t("dutiesAndTaxesStatusPage.solved");
      }
      if (status == "priority_issue") {
        return this.$t("dutiesAndTaxesStatusPage.priority");
      }
      if (status == "in_progress_issue") {
        return this.$t("dutiesAndTaxesStatusPage.inProgress");
      }
      if (status == "waiting_payment") {
        return this.$t("dutiesAndTaxesStatusPage.waitingforPayment");
      }
      if (status == "sent_again") {
        return this.$t("dutiesAndTaxesStatusPage.sentAgain");
      }
      if (status == "payment_done") {
        return this.$t("dutiesAndTaxesStatusPage.paymentDone");
      }
      if (status == "pending") {
        return this.$t("dutiesAndTaxesStatusPage.pending");
      }
      if (status == "paid_external") {
        return this.$t("dutiesAndTaxesStatusPage.paidExternally");
      }
      if (status == "need_seller") {
        return this.$t("dutiesAndTaxesStatusPage.needSellerAction");
      }

      return status;
    },
  },
};
</script>
<style scoped></style>
