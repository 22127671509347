import { render, staticRenderFns } from "./Datatable.vue?vue&type=template&id=438a2972&scoped=true"
import script from "./Datatable.vue?vue&type=script&lang=js"
export * from "./Datatable.vue?vue&type=script&lang=js"
import style0 from "./Datatable.vue?vue&type=style&index=0&id=438a2972&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "438a2972",
  null
  
)

export default component.exports