<template>
  <div>
    <ModalHeader>
      <ModalTitle>
        {{ $t("shipmentsViewPage.title") + shipmentId }}
      </ModalTitle>
    </ModalHeader>

    <Spinner v-if="loading" size="lg" class="mx-auto" />

    <form v-else class="grid gap-4">
      <div class="grid grid-cols-3">
        <NbCard :title="$t('actions')" id="actions" class="grid-column-3">
          <template #body>
            <div class="grid gap-4">
              <NbButton
                variant="secondary"
                :disabled="!shipment.flight_id"
                @click="
                  navigateTo(`/shipments/flights?id=${shipment.flight_id}`)
                "
                type="button"
              >
                {{ $t("shipmentsPage.goToFlight") }}
              </NbButton>
              <NbButton
                variant="secondary"
                :disabled="labelLoading || !hasLabel"
                @click="generateShipmentLabel"
                type="button"
              >
                {{ $t("shipmentsViewPage.cn38Label") }}
              </NbButton>
            </div>
          </template>
        </NbCard>
      </div>

      <NbCard :title="$t('generalData')" id="general-data">
        <template #body>
          <div class="grid grid-cols-2 gap-4">
            <NbTextInput
              :name="$t('shipmentsViewPage.category')"
              v-model="shipment.category"
              id="shipment.category"
            />
            <NbTextInput
              :name="$t('shipmentsViewPage.flightID')"
              v-model="shipment.flight_id"
              id="shipment.flight_id"
            />
            <NbTextInput
              :name="$t('shipmentsViewPage.cn38')"
              v-model="shipment.cn38"
              id="shipment.cn38"
              class="col-span-2"
            />

            <div class="col-span-2 d-flex justify-content-end">
              <div class="d-flex flex-column">
                <label for="" class="label-input">{{
                  $t("shipmentsViewPage.date")
                }}</label>
                <strong>{{ shipment.created_at | datetime }}</strong>
              </div>
            </div>
          </div>
        </template>
      </NbCard>

      <NbCard :title="$t('shipmentsViewPage.masterboxes')" id="masterboxes">
        <template #body>
          <NbTablev2
            class="mt-5"
            tableOf="shipmentMasterboxesPage"
            :hasParentItens="true"
            :hiddeTableOptions="true"
            height="fit-content"
            :allFields="masterboxesAllFields"
            :fields="masterboxesFields"
            @reloadFields="masterboxesFields = $event"
            :startItems="shipment.masterboxes"
          >
            <template #cell(id)="data">
              <router-link
                class="link-1"
                :to="`/shipping/masterboxes?id=` + data.item.id"
              >
                {{ data.item.id }}
              </router-link>
            </template>
            <template #cell(reference)="data">
              <NbButton
                variant="tertiary"
                v-b-tooltip.hover
                :title="$t('clickToCopy')"
              >
                {{ data.item.reference }}
              </NbButton>
            </template>
            <template #cell(volumes)="data">
              <div
                v-for="volume in data.item.volumes"
                :key="volume.id + '-vol'"
              >
                {{ volume.last_mile_tracking_number }}
              </div>
            </template>
          </NbTablev2>
        </template>
      </NbCard>
    </form>

    <ModalFooter class="d-flex justify-content-end">
      <ModalClose />
    </ModalFooter>
  </div>
</template>
<script>
import ModalHeader from "@/components/modal/ModalHeader.vue";
import ModalTitle from "@/components/modal/ModalTitle.vue";
import ModalFooter from "@/components/modal/ModalFooter.vue";
import ModalClose from "@/components/modal/ModalClose.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import ShipmentService from "@/services/ShipmentService";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbCard from "@/components/cards/NbCard.vue";
import Spinner from "@/components/Spinner.vue";
import moment from "moment";

const shipmentService = new ShipmentService();

export default {
  components: {
    ModalHeader,
    ModalTitle,
    ModalFooter,
    ModalClose,
    NbButton,
    NbTextInput,
    NbTablev2,
    NbCard,
    Spinner,
  },
  filters: {
    datetime(value) {
      return moment(value).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  data() {
    return {
      loading: false,
      labelLoading: false,
      shipmentId: null,
      cn38: null,
      shipment: {
        masterboxes: [],
      },
      masterboxesFields: [],
    };
  },
  computed: {
    hasLabel() {
      return this.cn38 && this.cn38?.substring(0, 9) != "TEMPORARY";
    },
    masterboxesAllFields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "reference",
          label: this.$t("shipmentsViewPage.fields.reference"),
          sortable: false,
        },
        {
          key: "dispatch_id",
          label: this.$t("shipmentsViewPage.fields.dispatchID"),
          sortable: false,
        },
        {
          key: "volumes",
          label: this.$t("shipmentsViewPage.fields.lastMileTrackingNumbers"),
          sortable: false,
        },
      ];
    },
  },
  methods: {
    async generateShipmentLabel() {
      try {
        this.labelLoading = true;
        const {
          data: { data },
        } = await shipmentService.generateShipmentLabel(this.shipmentId);

        if (data?.download_url) {
          window.open(data.download_url);
        }
      } finally {
        this.labelLoading = false;
      }
    },
    navigateTo(path) {
      this.$router.push(path);
    },
    async loadShipment() {
      try {
        this.loading = true;
        const {
          data: { data },
        } = await shipmentService.getShipment(this.shipmentId);
        this.cn38 = data.cn38;
        this.shipment = {
          ...data,
          category: data.category ?? "-",
          cn38: data.cn38 ?? "-",
          flight_id: data.flight_id ?? "-",
        };
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.shipmentId = Number(this.$route.query.id);
    this.loadShipment();
  },
};
</script>
<style lang="scss" scoped></style>
