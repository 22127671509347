var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative",class:`${_vm.size}`},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeCollapse),expression:"closeCollapse"}]},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle"}],staticClass:"nb-button notification-button-option",class:`${_vm.size}`,attrs:{"id":`${_vm.id}-1`,"href":`#${_vm.id}`},on:{"click":function($event){$event.preventDefault();}}},[(_vm.newNotificationsCount > 0)?_c('span',{staticClass:"badge badge-danger notification-badge",staticStyle:{"position":"absolute","top":"-4px","right":"1px","font-size":"0.55rem"}},[_vm._v(" "+_vm._s(_vm.newNotificationsCount)+" ")]):_vm._e(),(_vm.icon)?_c('i',{staticClass:"mr-2",class:_vm.icon}):_vm._e(),_vm._t("default",function(){return [_vm._v("Options")]})],2),_c('b-collapse',{staticClass:"mt-0 colapse-card-wrapper",style:(`width: ${_vm.optionsWidth}px; ${_vm.expandTo}: 0`),attrs:{"id":_vm.id},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('b-card',{staticClass:"notifications-card"},[_c('div',{staticClass:"d-flex justify-content-between mb-3"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("myNotificationsListPage.title"))+" ")]),_c('div')]),(_vm.newNotificationsCount === 0)?_c('div',{staticClass:"notification-button-options d-flex justify-content-start",on:{"click":function($event){_vm.closeCollapse();
            _vm.goTo({ name: 'user_notifications' });}}},[_c('div',{staticClass:"notification-status"},[_c('NbStatus',{attrs:{"type":''}})],1),_c('div',[_c('span',{staticClass:"notification-message-title"},[_vm._v(" You don't have new notifications")])])]):_vm._e(),_vm._l((_vm.notifications),function(notification,index){return _c('div',{key:index + '-notf',class:notification.disabled ? 'text-disabled' : '',on:{"click":function($event){notification.disabled ? null : _vm.emitClicked(notification)}}},[_c('div',{staticClass:"notification-button-options d-flex justify-content-start",on:{"click":function($event){_vm.closeCollapse();
              _vm.goTo({
                name: 'user_notifications',
                query: { userNotificationId: notification.id },
              });}}},[_c('div',{staticClass:"notification-status"},[_c('NbStatus',{attrs:{"type":_vm.getStatusType(notification)}})],1),_c('div',[_c('span',{staticClass:"notification-message-title"},[_vm._v(" "+_vm._s(_vm.notificationTitles[notification.title])+": ")]),_c('span',{staticClass:"notification-message-body"},[_vm._v(_vm._s(notification.body))]),_c('div',{staticClass:"notification-date mt-2"},[_vm._v(" "+_vm._s(_vm.$helpers.formatShortTime(notification.created_at, { hour: "numeric", minute: "numeric", }))+" • "+_vm._s(_vm.$helpers.formatShortDate(notification.created_at, { year: "numeric", month: "short", day: "numeric", }))+" ("+_vm._s(_vm.timeAgo(notification.created_at))+") ")])])])])}),_c('div',{staticClass:"link-2 all-notification-link notification-button-options",on:{"click":function($event){_vm.closeCollapse();
            _vm.goTo({ name: 'user_notifications' });}}},[_vm._v(" "+_vm._s(_vm.$t("myNotificationsListPage.allNotifications"))+" > ")])],2)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }