const redirectLegacyToServerless = () => {
  if (!window.location.host.endsWith("nobordist.com")) return;

  if (
    window.location.host.includes("adm") &&
    !window.location.host.endsWith(".adm.nobordist.com")
  ) {
    if (window.location.host.startsWith("tstadm"))
      window.location.href = window.location.href
        .replace("tstadm.", "tstadm-nb.")
        .replace(/tstadm-(\w+)/, "$1.staging.adm")
        .replace(/tst([\w-]+)/, "nb.staging.adm");
    else if (window.location.host.startsWith("hmladm"))
      window.location.href = window.location.href
        .replace("hmladm.", "hmladm-nb.")
        .replace(/hmladm-(\w+)/, "$1.homolog.adm")
        .replace(/hml([\w-]+)/, "nb.homolog.adm");
    else
      window.location.href = window.location.href
        .replace(/(\w+)-adm/, "adm-$1")
        .replace("adm.", "adm-nb.")
        .replace(/adm-(\w+)/, "$1.production.adm")
        .replace(/adm([\w-]+)/, "nb.production.adm");
  } else if (
    window.location.host.includes("carrier") &&
    !window.location.host.endsWith(".carrier.nobordist.com")
  ) {
    if (window.location.host.startsWith("tstcarrier"))
      window.location.href = window.location.href
        .replace("tstcarrier.", "tstcarrier-nb.")
        .replace(/tstcarrier-(\w+)/, "$1.staging.carrier")
        .replace(/tst([\w-]+)/, "nb.staging.carrier");
    else if (window.location.host.startsWith("hmlcarrier"))
      window.location.href = window.location.href
        .replace("hmlcarrier.", "hmlcarrier-nb.")
        .replace(/hmlcarrier-(\w+)/, "$1.homolog.carrier")
        .replace(/hml([\w-]+)/, "nb.homolog.carrier");
    else
      window.location.href = window.location.href
        .replace(/(\w+)-carrier/, "carrier-$1")
        .replace("carrier.", "carrier-nb.")
        .replace(/carrier-(\w+)/, "$1.production.carrier")
        .replace(/carrier([\w-]+)/, "nb.production.carrier");
  } else if (
    !window.location.host.includes("adm") &&
    !window.location.host.includes("carrier") &&
    !window.location.host.endsWith(".app.nobordist.com")
  ) {
    if (window.location.host.startsWith("tst"))
      window.location.href = window.location.href
        .replace("tstmng.", "tstapp-nb.")
        .replace(/tstapp-(\w+)/, "$1.staging.app")
        .replace(/tst([\w-]+)/, "nb.staging.app");
    else if (window.location.host.startsWith("hml"))
      window.location.href = window.location.href
        .replace("hmlmng.", "hmlapp-nb.")
        .replace(/hmlapp-(\w+)/, "$1.homolog.app")
        .replace(/hml([\w-]+)/, "nb.homolog.app");
    else
      window.location.href = window.location.href
        .replace("prdmng.", "nb.")
        .replace("app.", "nb.")
        .replace(".nobordist", ".production.app.nobordist");
  }
};

redirectLegacyToServerless();

import Vue from "vue";
import App from "./App.vue";
import api from "./services/HttpService";
import router from "./router";
import store from "./store";
import helpers from "./helpers";
import "bootstrap";
import "feather-icons";
import Toasted from "vue-toasted";

import { BootstrapVue } from "bootstrap-vue";
import { CoolSelectPlugin } from "vue-cool-select";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-cool-select/dist/themes/material-design.css";
import i18n from "./i18n"; //importando i18n global

import * as Sentry from "@sentry/vue";

const plugin = {
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  },
};

Vue.use(plugin);
Vue.use(CoolSelectPlugin);
Vue.use(BootstrapVue);
Vue.use(Toasted, { iconPack: "fontawesome" });

Sentry.init({
  Vue,
  environment: process.env.VUE_APP_ENV,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "nobordist.com"],
  // Session Replay
  replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

export const app = new Vue({
  store,
  router,
  i18n /* i18n.doc CONFIGURA i18n quando vue é criado */,
  api,
  render: (h) => h(App),
}).$mount("#app");
