<template>
  <div>
    <div class="container">
      <h4 class="heading-4">2. {{ $t("flightsPage.insertFlightData") }}</h4>
      <p class="body-4">
        {{ $t("flightsPage.pleaseInsertFlightData") }}
      </p>
    </div>
    <form>
      <NbCard :title="$t('flightsPage.generalData')" id="general-info">
        <template #body>
          <div class="grid grid-cols-4">
            <NbTextInput
              id="airline"
              :placeholder="$t('flightsViewPage.airline')"
              class="col-span-2"
              :name="$t('flightsViewPage.airline')"
              :error="errors?.airline"
              v-model="flight.airline"
            />
            <NbTextInput
              id="flight_number"
              :placeholder="$t('flightsViewPage.flightNumber')"
              :name="$t('flightsViewPage.flightNumber')"
              type="number"
              :error="errors?.flight_number"
              v-model="flight.flight_number"
            />
            <NbTextInput
              id="mawb_number"
              :placeholder="$t('flightsViewPage.mawbNumber')"
              :name="$t('flightsViewPage.mawbNumber')"
              :error="errors?.mawb_number"
              v-model="flight.mawb_number"
            />
          </div>

          <div class="grid grid-cols-3 my-4">
            <NbRadio
              id="maws-file-format"
              v-model="flight.mawb_file_format"
              :name="$t('flightsPage.howWouldLikeToUploadMawb')"
              :options="mawbFormats"
              class="align-self-end"
              @input="resetMawbFile"
            />
          </div>

          <div class="upload-area">
            <NbTextInput
              v-if="flight.mawb_file_format == 'link'"
              id="mawb-file-text"
              :placeholder="$t('sitePlaceholder')"
              class="w-100 my-2"
              :name="$t('flightsViewPage.mawbFileLink')"
              :error="errors?.departure_airport"
              v-model="flight.mawb_file"
            />
            <NbDropFile
              v-else
              id="upload-file"
              v-model="mawb_file_temp"
              :pdfUrl="pdfUrl"
              :showMiniaturePdf="true"
              :showPreviewFiles="
                flight.mawb_file_format == 'binary' ? true : false
              "
              :accept="['application/pdf']"
              :label="$t('alertLogsPage.browseFilesUpload')"
              @stringFile="stringFile"
              @click="changeLinkBinary('binary')"
              minHeight="365px"
              embedHeight="306px"
            >
              <div class="py-4 text-in-nbdropfile">
                <div class="text-center">
                  {{ $t("flightsViewPage.noMawbDocument") }}
                </div>
                <div class="text-center">{{ $t("clickHereUploadFile") }}</div>
                <div class="text-center mb-3">
                  {{ $t("selectPdfFileUpload") }}
                </div>
              </div>
            </NbDropFile>
          </div>
        </template>
      </NbCard>

      <NbCard
        :title="$t('flightsPage.departureAndArrive')"
        id="departure-arrival"
      >
        <template #body>
          <div class="grid grid-cols-3">
            <NbTextInput
              id="departure_airport"
              :placeholder="$t('flightsViewPage.departureAirport')"
              :name="$t('flightsViewPage.departureAirport')"
              :error="errors?.departure_airport"
              v-model="flight.departure_airport"
            />
            <NbDatePicker
              id="departure_date"
              size="sm"
              :name="$t('flightsViewPage.departureDate')"
              :error="errors?.departure_date"
              v-model="flight.departure_date"
            />
            <NbTextInput
              id="departure_time"
              :placeholder="$t('notFound')"
              :name="$t('flightsViewPage.departureTime')"
              type="time"
              :error="errors?.departure_time"
              v-model="flight.departure_time"
            />
            <NbTextInput
              id="arrival_airport"
              :placeholder="$t('flightsViewPage.arrivalAirport')"
              :name="$t('flightsViewPage.arrivalAirport')"
              :error="errors?.arrival_airport"
              v-model="flight.arrival_airport"
            />
            <NbDatePicker
              id="arrival_date"
              size="sm"
              :name="$t('flightsViewPage.arrivalDate')"
              :error="errors?.arrival_date"
              v-model="flight.arrival_date"
            />

            <NbTextInput
              id="arrival_time"
              :placeholder="$t('notFound')"
              :name="$t('flightsViewPage.arrivalTime')"
              type="time"
              :error="errors?.arrival_time"
              v-model="flight.arrival_time"
            />
            <NbTextInput
              id="stopover_airport"
              :placeholder="$t('flightsViewPage.stopoverAirport')"
              :name="$t('flightsViewPage.stopoverAirport')"
              :error="errors?.stopover_airport"
              v-model="flight.stopover_airport"
            />
          </div>
        </template>
      </NbCard>

      <NbCard :title="$t('flightsPage.taxes')" id="tax">
        <template #body>
          <div class="grid grid-cols-3">
            <NbTextInput
              id="freight_value"
              placeholder="0.00"
              :name="$t('ordersViewPage.freightValue')"
              type="money"
              :decimals="2"
              :min="0.0"
              :error="errors?.freight_value"
              v-model="flight.freight_value"
            />
            <NbTextInput
              id="sub_total"
              :placeholder="$t('flightsViewPage.subtotal')"
              :name="$t('flightsViewPage.subtotal')"
              type="money"
              :decimals="2"
              :min="0.0"
              :error="errors?.sub_total"
              v-model="flight.sub_total"
            />
            <NbTextInput
              id="charges_per_kilo"
              :placeholder="$t('flightsViewPage.chargesKilo')"
              :name="$t('flightsViewPage.chargesKilo')"
              type="number"
              :error="errors?.charges_per_kilo"
              v-model="flight.charges_per_kilo"
            />
            <NbTextInput
              id="other_charges"
              :placeholder="$t('flightsViewPage.otherCharges')"
              :name="$t('flightsViewPage.otherCharges')"
              type="number"
              :error="errors?.other_charges"
              v-model="flight.other_charges"
            />
          </div>
        </template>
      </NbCard>

      <div class="d-flex justify-content-between">
        <NbButton
          variant="secondary"
          @click="$emit('previous')"
          :disabled="loading"
        >
          {{ $t("flightsPage.returnsToPreviousStep") }}
        </NbButton>
        <NbButton @click="createFlight" :disabled="loading">
          {{ $t("flightsPage.createFlight") }}
        </NbButton>
      </div>
    </form>
  </div>
</template>

<script>
import NbCard from "@/components/cards/NbCard.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbRadio from "@/components/buttons/NbRadio.vue";
import NbDropFile from "@/components/input/drop-file/NbDropFile.vue";
import NbDatePicker from "@/components/generic/NbDatePicker.vue";
import FlightService from "@/services/FlightService";
import moment from "moment";

const flightService = new FlightService();

export default {
  components: {
    NbCard,
    NbButton,
    NbTextInput,
    NbDropFile,
    NbDatePicker,
    NbRadio,
  },
  props: {
    masterboxIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      errors: {},
      mawb_file_temp: "",
      has_mawb_file: false,
      flight: {
        departure_airport: "",
        arrival_airport: "",
        stopover_airport: "",
        departure_date: "",
        departure_time: "",
        arrival_date: "",
        arrival_time: "",
        airline: "",
        flight_number: "",
        freight_value: "",
        mawb_file: "",
        mawb_file_format: "binary",
      },
    };
  },
  computed: {
    mawbFormats() {
      return [
        { text: "Link", value: "link" },
        { text: this.$t("flightsPage.uploadFromMyComputer"), value: "binary" },
      ];
    },
    pdfUrl() {
      if (!this.mawb_file_temp) {
        return false;
      }
      if (this.mawb_file_temp && this.flight.mawb_file_format !== "link") {
        return (
          "data:application/pdf;base64," +
          this.mawb_file_temp +
          "#toolbar=0&navpanes=0&scrollbar=0"
        );
      }
      return this.mawb_file_temp + "#toolbar=0&navpanes=0&scrollbar=0";
    },
  },
  methods: {
    resetMawbFile() {
      this.flight.mawb_file = "";
      this.mawb_file_temp = "";
    },
    openUploadFile() {
      this.changeLinkBinary("binary");
      setTimeout(() => {
        document.getElementById("upload-file-trigger").click();
      }, 500);
    },
    changeLinkBinary(val) {
      if (val) {
        this.flight.mawb_file_format = val;
        return;
      }
      if (this.flight.mawb_file_format == "binary") {
        this.flight.mawb_file_format = "link";
        return;
      }

      this.flight.mawb_file_format = "binary";
    },
    stringFile(event) {
      this.flight.mawb_file = event;
    },
    async createFlight() {
      try {
        this.loading = true;
        await flightService.setFlight({
          ...this.flight,
          box_numbers: this.masterboxIds,
          departure_date: moment(this.flight.departure_date).format(
            "YYYY-MM-DD",
          ),
          arrival_date: moment(this.flight.arrival_date).format("YYYY-MM-DD"),
        });
        this.$emit("success");
      } catch (error) {
        this.errors = error?.response?.data?.messages || {};
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .grid {
    display: grid;
    gap: 1rem;
    &.grid-cols-3 {
      grid-template-columns: repeat(3, 1fr);
    }
    &.grid-cols-4 {
      grid-template-columns: repeat(4, 1fr);
    }
    .col-span-2 {
      grid-column: span 2 / span 2;
    }
  }
}
</style>
