<template>
  <div>
    <NbPageTitle
      class="pb-3 page-container"
      :title="$t('generalEmailsPage.title')"
      :subTitle="$t('generalEmailsPage.subTitle')"
    >
      <div class="btn-groups d-flex flex-column">
        <NbButton
          class="text-left"
          data-toggle="modal"
          data-target="#addGeneralEmail"
          @click="initializeGeneralEmail()"
        >
          <NbIcon class="mr-2" icon="plus" />
          {{ $t("generalEmailsPage.createGeneralEmails") }}
        </NbButton>
      </div>
    </NbPageTitle>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />

      <NbTablev2
        tableOf="generalEmailTable"
        :namespace="namespace"
        ref="generalEmailTable"
        :clickable="true"
        :allFields="allFields"
        :fields="fields"
        :filterOptions="filterOptions"
        @reloadFields="fields = $event"
        @total="totalGeneralEmail = $event"
        @clickedRow="
          loadModalData($event.id, 'generalEmailId', 'ModalGeneralEmailsView')
        "
      >
        <template #cell(destination)="data">
          {{ displayMaxCharacters(data.item.destination, 70) }}
        </template>
        <template #cell(body)="data">
          {{ displayMaxCharacters(data.item.body, 70) }}
        </template>
        <template #cell(sent)="data">
          {{ data.item.sent }}
        </template>
      </NbTablev2>
    </section>

    <NbFooter
      class="page-container"
      :text="$t('components.footer.checkOurSupport')"
      link="https://nobordistinc.freshdesk.com/support/home"
    />

    <!-- modals -->
    <ModalGeneralEmailsView
      @reloadGeneralEmail="reloadGeneralEmail"
      :itemId="currentItemId"
    />

    <ModalGeneralEmailsCreate
      id="addGeneralEmail"
      :generalEmail.sync="newGeneralEmail"
      @reloadGeneralEmail="reloadGeneralEmail"
    />
    <!-- end modal -->
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import ModalGeneralEmailsView from "@/views/logs/general_emails/components/ModalGeneralEmailsView.vue";
import ModalGeneralEmailsCreate from "@/views/logs/general_emails/components/ModalGeneralEmailsCreate.vue";

export default {
  name: "GeneralEmails",

  components: {
    NbPageTitle,
    NbIcon,
    NbButton,
    NbTabs,
    NbTablev2,
    NbFooter,
    ModalGeneralEmailsView,
    ModalGeneralEmailsCreate,
  },
  data: () => {
    return {
      newGeneralEmail: {
        title: "",
        destinations: "",
        body: "",
      },
      fields: [],
      namespace: "general_emails",
      totalGeneralEmail: -1,
      currentTable: "all",
      currentItemId: "",
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: "all",
          label: this.$t("generalEmailsPage.title"),
          found: this.totalGeneralEmail,
          current: this.currentTable,
        },
      ];
    },
    allFields() {
      return [
        { key: "id", label: "ID", sortable: false, thClass: ["small-content"] },
        {
          key: "title",
          label: this.$t("generalEmailsPage.fields.titleGeneral"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "destination",
          label: this.$t("generalEmailsPage.fields.Destination"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "body",
          label: this.$t("generalEmailsPage.fields.Body"),
          sortable: false,
          thClass: ["large-content"],
        },
        {
          key: "sent",
          label: this.$t("generalEmailsPage.fields.Sent"),
          sortable: false,
          thClass: ["small-content"],
        },
      ];
    },
    filterOptions() {
      return [
        {
          key: "title",
          label: this.$t("generalEmailsPage.fields.titleGeneral"),
        },
        {
          key: "destination",
          label: this.$t("generalEmailsPage.fields.Destination"),
        },
        { key: "body", label: this.$t("generalEmailsPage.fields.Body") },
        {
          key: "sent",
          label: this.$t("generalEmailsPage.fields.Sent"),
          type: "select",
          options: [
            { value: null, text: "Select" },
            { value: true, text: "True" },
            { value: false, text: "False" },
          ],
        },
      ];
    },
  },
  created() {
    this.fields = this.allFields;
  },
  mounted() {
    this.checkQuery();
  },
  methods: {
    switchTab(tab) {
      this.currentTable = tab;
    },
    displayMaxCharacters(str, limit) {
      let finalStr = str;
      if (typeof finalStr !== "string") {
        finalStr = JSON.stringify(str);
      }
      if (finalStr.length > limit) {
        return finalStr.substring(0, limit - 5) + "(...)";
      }
      return finalStr;
    },
    reloadGeneralEmail() {
      this.$refs?.generalEmailTable?.getData();
    },
    checkQuery() {
      if (this.$route.query.generalEmailId) {
        //this.$emit("switchTab", "nobordistCheckpoints");
        this.loadModalData(
          this.$route.query.generalEmailId,
          "generalEmailId",
          "ModalGeneralEmailsView",
        );
        return;
      }
    },
    loadModalData(id, queryKey, modalId) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query[queryKey]) {
          this.$router.push({ query: { [queryKey]: id } });
        }
        /* this.$router.push({ query: {} }); */

        this.$helpers.openModal(modalId);
        return;
      }
    },
    initializeGeneralEmail() {
      this.newGeneralEmail = {
        sent: "",
        title: "",
        destination: "",
        body: "",
      };
    },
  },
};
</script>
