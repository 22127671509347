<template>
  <div>
    <NbTablev2
      tableOf="alertLogs"
      :namespace="namespace"
      ref="alertLogs"
      :clickable="true"
      :allFields="allFields"
      :fields="fields"
      :tabs="true"
      :selectable="true"
      :optionsWidth="200"
      :filterOptions="filterOptions"
      @optionTook="optionTook($event)"
      :buttonOptions="buttonOptions"
      :buttonsTable="buttonsTable"
      :selectedItems.sync="rowSelection"
      @reloadFields="fields = $event"
      @total="$emit('total', $event)"
      @clickedRow="checkPaymentLoadModalData($event)"
    >
      <!--
      <template #cell(id)="data">
        <div v-if="data.item.type == 'duties_payment'">
          <div class="link-1" @click="loadModalData(data.item.id)">
            {{ data.item.id }}
          </div>
        </div>
        <div v-else>
          {{ data.item.id }}
        </div>
      </template>
      -->

      <template #cell(attachment)="data">
        <template v-if="data.item.attachment">
          <a href="" @click="openDocument(data.item.attachment)">
            <i class="far fa-file-alt fa-lg text-dark"></i>
          </a>
        </template>
        <template v-else>
          <div>
            <i
              class="fa fa-clock-o text-danger"
              aria-hidden="true"
              style="
                font-size: 8px;
                height: 25px;
                position: relative;
                left: 7px;
              "
            ></i>
            <i
              class="far fa-file fa-lg text-dark"
              style="
                font-size: 18px;
                position: relative;
                left: -4px;
                bottom: 0px;
              "
            ></i>
          </div>
        </template>
      </template>

      <template #cell(comment)="data">
        <template v-if="data.item.comment">
          {{ data.item.comment }}
        </template>
        <template v-else>
          {{ "N/A" }}
        </template>
      </template>

      <template #cell(duties_status)="data">
        <template v-if="!!data.item.duties_status">
          <div>
            {{ data.item.duties_status }}
          </div>
        </template>
      </template>

      <template #cell(real_tax)="data">
        <template v-if="!!data.item.real_tax">
          <div>
            {{ data.item.real_tax }}
          </div>
        </template>
      </template>

      <template #cell(barcode)="data">
        <template v-if="!!data.item.barcode && isFinance">
          <div>
            {{ data.item.barcode }}
          </div>
        </template>
        <template v-else>
          {{ "N/A" }}
        </template>
      </template>

      <template #cell(payment_certificate)="data">
        <template v-if="!!data.item.barcode">
          <a @click="getPaymentPdf(data.item.id)">
            <i
              class="far fa-file fa-lg text-dark"
              style="
                font-size: 18px;
                position: relative;
                left: -4px;
                bottom: 0px;
              "
            ></i>
          </a>
        </template>
        <template v-else> - </template>
      </template>

      <template #cell(tracking_code)="data">
        {{ data.item.checkpoint.tracking_code }}
      </template>

      <template #cell(title)="data">
        <template v-if="data.item">
          {{ titleReturn(data.item.checkpoint_id) }}
        </template>
      </template>

      <template #cell(tracking_rule_id)="data">
        <router-link
          v-if="data.item.tracking_rule_id"
          class="link-1"
          :to="
            '/logs/tracking_rules?trackingRulesId=' + data.item.tracking_rule_id
          "
        >
          {{ getTrackingRuleName(data.item.tracking_rule_id) }}
        </router-link>
        <span v-else>-</span>
      </template>

      <template #cell(status)="data">
        <NbBadge v-if="data.item.status == 'new_issue'">
          {{ $t("alertLogsPage.new") }}
        </NbBadge>
        <NbBadge v-if="data.item.status == 'solved_issue'" type="success">
          {{ $t("alertLogsPage.solved") }}
        </NbBadge>
        <NbBadge v-if="data.item.status == 'priority_issue'" type="danger">
          {{ $t("alertLogsPage.priority") }}
        </NbBadge>
        <NbBadge v-if="data.item.status == 'in_progress_issue'" type="warning"
          >{{ $t("alertLogsPage.inProgress") }}
        </NbBadge>
        <NbBadge v-if="data.item.status == 'waiting_payment'" type="warning">
          {{ $t("alertLogsPage.waitingforPayment") }}
        </NbBadge>
        <NbBadge v-if="data.item.status == 'sent_again'">
          {{ $t("alertLogsPage.sentAgain") }}
        </NbBadge>
        <NbBadge v-if="data.item.status == 'payment_done'" type="success">
          {{ $t("alertLogsPage.paymentDone") }}
        </NbBadge>
        <NbBadge v-if="data.item.status == 'pending'" type="warning">
          {{ $t("alertLogsPage.pending") }}
        </NbBadge>
        <NbBadge v-if="data.item.status == 'paid_external'" type="success">
          {{ $t("alertLogsPage.paidExternally") }}
        </NbBadge>
        <NbBadge v-if="data.item.status == 'need_seller'" type="danger">
          {{ $t("alertLogsPage.needSellerAction") }}
        </NbBadge>
      </template>
    </NbTablev2>
    <!-- modals -->
    <NbModal
      id="uploadPdfModal"
      modalConfig="modal-dialog-centered"
      width="500px"
    >
      <template v-slot:header>
        <div class="heading-4">{{ $t("upload") }}</div>
      </template>
      <template v-slot:body>
        <NbDropFile
          v-model="chosenFile"
          :accept="['application/pdf']"
          :label="$t('alertLogsPage.browseFilesUpload')"
        />
      </template>
      <template v-slot:footer>
        <NbButton
          class="float-right mr-3"
          @click="sendFile()"
          :disabled="isLoading"
        >
          {{ $t("upload") }}
        </NbButton>
        <!-- :disabled="loading || !modelValue" -->
        <NbButton variant="secondary" data-dismiss="modal">
          {{ $t("close") }}
        </NbButton>
      </template>
    </NbModal>

    <NbModal
      id="importValueStatus"
      modalConfig="modal-dialog-centered"
      width="500px"
    >
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("alertLogsPage.importValuesandStatuses") }}
        </div>
      </template>
      <template v-slot:body>
        <NbTextInput
          required
          id="name"
          :name="$t('alertLogsPage.name')"
          class="mb-4"
          v-model="newItem.name"
        />

        <NbDropFile
          ref="dropFile"
          v-model="chosenFile"
          :label="$t('alertLogsPage.browseFilesUpload')"
          :accept="['text/csv']"
        >
          <div class="py-4">
            <div class="text-center">{{ $t("dragDropFile") }}</div>
            <div class="text-center mb-3">
              {{ $t("alertLogsPage.selectcsvFileUpload") }}
            </div>

            <NbButton variant="secondary d-block mx-auto">
              {{ $t("upload") }}
            </NbButton>

            <NbButton
              variant="secondary my-2 d-block mx-auto"
              @click.stop="downloadCsvExample"
            >
              {{ $t("alertLogsPage.downloadHere") }}
              {{ $t("alertLogsPage.blankTable") }}
            </NbButton>

            <div class="text-center">
              {{ $t("alertLogsPage.downloadHere") }}
              <a
                href="javascript:void;"
                @click.stop="downloadInstructionsFile"
                class="text-center link-1"
              >
                {{ $t("alertLogsPage.instructionsXLSfile") }}
              </a>
            </div>
          </div>
        </NbDropFile>
        <p>{{ $t("alertLogsPage.updateStatusInfo") }}</p>
      </template>
      <template v-slot:footer>
        <NbButton
          :disabled="newItem.name.length > 0 ? false : true"
          class="float-right mr-3"
          @click.prevent="importFile()"
        >
          {{ $t("save") }}
        </NbButton>
        <NbButton variant="secondary" data-dismiss="modal">
          {{ $t("close") }}
        </NbButton>
      </template>
    </NbModal>

    <NbModal
      id="updateStatusModal"
      modalConfig="modal-dialog-centered"
      width="500px"
    >
      <template v-slot:header>
        <div class="heading-4">{{ $t("update") }}</div>
      </template>
      <template v-slot:body>
        <p>Update SELECTED Alert Log</p>
        <NbSelectInput
          required
          id="select-status"
          class="mb-3"
          :name="$t('alertLogsPage.status')"
          :error="errors.name"
          :options="statusOptions"
          v-model="newLog.status"
        />

        <NbTextInput
          id="comment-status"
          :name="$t('alertLogsPage.comment')"
          class="mb-3"
          v-model="newLog.comment"
        />
      </template>
      <template v-slot:footer>
        <NbButton
          class="float-right mr-3"
          :disabled="!newLog.status"
          @click="
            $helpers.openModal('confirmSave');
            $helpers.openModal('updateStatusModal');
          "
        >
          {{ $t("update") }}
        </NbButton>
        <!-- :disabled="loading || !modelValue" -->
        <NbButton variant="secondary" data-dismiss="modal">
          {{ $t("close") }}
        </NbButton>
      </template>
    </NbModal>

    <NbModal id="confirmSave" modalConfig="modal-dialog-centered" width="500px">
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("confirm") }}
        </div>
      </template>
      <template v-slot:body>
        <p v-if="newLog.status != 'sent_again'">
          {{ $t("alertLogsPage.sureWantSave") }}?
        </p>
        <div v-else>
          <p>
            {{ $t("alertLogsPage.shouldbeCommaSeparated") }}{{ rowSelection }}
          </p>
          <div class="form-group">
            <NbTextInput
              required
              id="new_tracking_number"
              :name="$t('alertLogsPage.newTrackingNumbers')"
              class="mb-4"
              v-model="newLog.new_tracking_number"
            />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <NbButton
          v-if="newLog.status != 'sent_again'"
          class="float-right mr-3"
          :disabled="loadingUpdateMassive"
          @click="updateMassiveStatus()"
        >
          {{ $t("save") }}
        </NbButton>
        <NbButton
          v-else
          :disabled="!newLog.new_tracking_number || loadingUpdateMassive"
          class="float-right mr-3"
          @click="updateMassiveStatus()"
        >
          {{ $t("save") }}
        </NbButton>
        <!-- :disabled="loading || !modelValue" -->
        <NbButton variant="secondary" data-dismiss="modal">
          {{ $t("close") }}
        </NbButton>
      </template>
    </NbModal>

    <NbModal
      id="downloadModal"
      modalConfig="modal-dialog-centered"
      width="500px"
    >
      <template v-slot:header>
        <div class="heading-4">
          {{ $t("download") }}
        </div>
      </template>
      <template v-slot:body>
        <p>{{ $t("alertLogsPage.checktheprocessingdownloads") }}</p>
      </template>
      <template v-slot:footer>
        <NbButton class="float-right mr-3" @click="downloadLogs">
          {{ $t("start") }}
        </NbButton>
        <!-- :disabled="loading || !modelValue" -->
        <NbButton variant="secondary" data-dismiss="modal">
          {{ $t("close") }}
        </NbButton>
      </template>
    </NbModal>

    <ModalAlertLogsView :itemId="currentItemId" />
    <!-- end-modals -->
  </div>
</template>

<script>
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbDropFile from "@/components/input/drop-file/NbDropFile.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import ModalAlertLogsView from "@/views/logs/alert_logs/components/ModalAlertLogsView.vue";
import NbBadge from "@/components/alerts/NbBadge.vue";

import { mapState } from "vuex";
//import { BCard } from "bootstrap-vue";
import AlertLogsService from "../../../services/AlertLogsService";
import DownloadsService from "@/services/DownloadsService";
//import $ from 'jquery';
import TrackingRuleService from "../../../services/TrackingRuleService";
import CheckpointService from "../../../services/CheckpointService";
import store from "@/store/index";

const alertLogsService = new AlertLogsService();
const trackingRuleService = new TrackingRuleService();
const downloadsService = new DownloadsService();
const checkpointService = new CheckpointService();
const isFinance = store.state.user.group_name === "Platform-Finance";

export default {
  name: "AlertLogs",
  components: {
    NbTablev2,
    NbModal,
    NbButton,
    NbDropFile,
    NbTextInput,
    NbSelectInput,
    ModalAlertLogsView,
    NbBadge,
  },
  data: () => {
    return {
      isLoading: false,
      loadingUpdateMassive: false,
      rowSelection: [],
      fields: [],
      errors: {},
      newItem: {
        name: "",
      },
      logs: [],
      trackingRules: [],
      isFinance: isFinance,
      newLog: {
        new_tracking_number: null,
        status: null,
        comment: null,
      },
      file: null,
      chosenFile: "",
      namespace: "tracking_alert_logs",
      currentItemId: "",
    };
  },
  computed: {
    statusOptions() {
      let finalOptions = [
        {
          text: this.$t("alertLogsPage.new"),
          value: "new_issue",
        },
        {
          text: this.$t("alertLogsPage.solved"),
          value: "solved_issue",
        },
        {
          text: this.$t("alertLogsPage.priority"),
          value: "priority_issue",
        },
        {
          text: this.$t("alertLogsPage.inProgress"),
          value: "in_progress_issue",
        },
        {
          text: this.$t("alertLogsPage.waitingforPayment"),
          value: "waiting_payment",
        },
        /* {
          text: this.$t("alertLogsPage.sentAgain"),
          value: "sent_again",
        }, */
        {
          text: this.$t("alertLogsPage.paymentDone"),
          value: "payment_done",
        },
        {
          text: this.$t("alertLogsPage.pending"),
          value: "pending",
        },
        {
          text: this.$t("alertLogsPage.paidExternally"),
          value: "paid_external",
        },
        {
          text: this.$t("alertLogsPage.needSellerAction"),
          value: "need_seller",
        },
      ];

      if (this.trackingCodeReturnMassive(this.rowSelection)) {
        const sentAgain = {
          text: this.$t("alertLogsPage.sentAgain"),
          value: "sent_again",
        };
        finalOptions.splice(5, 0, sentAgain);
      }

      return finalOptions;
    },
    buttonOptions() {
      return [
        {
          text: this.$t("alertLogsPage.updateStatus"),
          value: "updateStatusModal",
        },
        {
          text: this.$t("alertLogsPage.downloadLogs"),
          value: "downloadModal",
        },
      ];
    },
    buttonsTable() {
      return [
        {
          text: "Import Payment Certificates",
          value: "uploadPdfModal",
        },
        {
          text: this.$t("alertLogsPage.importValuesandStatuses"),
          value: "importValueStatus",
        },
      ];
    },
    items() {
      return this.$store.state[this.namespace].items;
    },
    ...mapState("nobordist_checkpoints", {
      nobordistCheckpoints: (state) => state.all_items,
    }),
    allFields() {
      return [
        { key: "id", label: "ID", sortable: false, thClass: ["small-content"] },
        {
          key: "volume_id",
          label: this.$t("alertLogsPage.fields.volumeId"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "tracking_code",
          label: this.$t("alertLogsPage.fields.trackingCode"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "seller_id",
          label: this.$t("alertLogsPage.fields.sellerId"),
          sortable: false,
          thClass: ["small-content"],
        },
        {
          key: "message",
          label: this.$t("alertLogsPage.fields.message"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "attachment",
          label: this.$t("alertLogsPage.fields.attachment"),
          sortable: false,
          thClass: ["small-content"],
        },
        {
          key: "last_mile_tracking_number",
          label: this.$t("alertLogsPage.fields.lastMileTrackingNumber"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "date_iso",
          label: this.$t("alertLogsPage.fields.dateISO"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "created_at",
          label: this.$t("alertLogsPage.fields.creationTime"),
          sortable: true,
          thClass: ["medium-content"],
        },
        {
          key: "title",
          label: this.$t("alertLogsPage.fields.title"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "tracking_rule_id",
          label: this.$t("alertLogsPage.fields.rule"),
          sortable: false,
          thClass: ["small-content"],
        },
        {
          key: "type",
          label: this.$t("alertLogsPage.fields.type"),
          sortable: false,
          thClass: ["small-content"],
        },
        {
          key: "status",
          label: this.$t("alertLogsPage.fields.status"),
          sortable: false,
          thClass: ["small-content"],
        },
        {
          key: "comment",
          label: this.$t("alertLogsPage.fields.comment"),
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "real_tax",
          label: "Real Tax",
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "barcode",
          label: "Barcode",
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "dutypay_link",
          label: "Dutypay Link",
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "duties_status",
          label: "Duties Status",
          sortable: false,
          thClass: ["medium-content"],
        },
        {
          key: "payment_certificate",
          label: "Payment Certificate",
          sortable: false,
          thClass: ["medium-content"],
        },
      ];
    },
    filterOptions() {
      return [
        { key: "volume_id", label: this.$t("alertLogsPage.fields.volumeId") },
        {
          key: "tracking_code",
          label: this.$t("alertLogsPage.fields.trackingCode"),
          type: "select",
          options: [{ value: null, text: "Select" }],
        },
        { key: "message", label: this.$t("alertLogsPage.fields.message") },
        {
          key: "last_mile_tracking_number",
          label: this.$t("alertLogsPage.fields.lastMileTrackingNumber"),
        },
        { key: "comment", label: this.$t("alertLogsPage.fields.comment") },
        {
          key: "rule",
          label: this.$t("alertLogsPage.fields.rule"),
          type: "select",
          options: [{ value: null, text: "Select" }],
        },
        {
          key: "type",
          label: this.$t("alertLogsPage.fields.type"),
          type: "select",
          options: [
            { value: null, text: "Select" },
            { value: 0, text: "Normal Type" },
            { value: 1, text: "Payment for the Seller" },
            { value: 2, text: "Received Checkpoint" },
            { value: 3, text: "Time Between Checkpoints" },
          ],
        },
        { value: 4, text: "Duties Payment" },
        { key: "title", label: this.$t("alertLogsPage.fields.title") },
        {
          key: "status",
          label: this.$t("alertLogsPage.fields.status"),
          type: "select",
          options: [
            { value: null, text: "Select" },
            { value: 0, text: "New" },
            { value: 1, text: "Solved" },
            { value: 2, text: "Priority" },
            { value: 3, text: "In Progress" },
            { value: 4, text: "Waiting for Payment" },
            { value: 5, text: "Sent Again" },
            { value: 6, text: "Payment Done" },
            { value: 7, text: "Pending" },
            { value: 8, text: "Paid Externally" },
            { value: 9, text: "Need Seller Action" },
          ],
        },
        {
          key: "attachment",
          label: this.$t("alertLogsPage.fields.attachment") + "?",
          type: "boolean",
        },
        {
          key: "registration_date",
          label: this.$t("registrationDate"),
          type: "dates",
        },
        {
          key: "seller_id",
          label: this.$t("alertLogsPage.fields.seller"),
          type: "number",
        },
        {
          key: "duties_payment",
          label: this.$t("alertLogsPage.fields.dutiesPayment"),
          type: "select",
          options: [
            { value: "R$", text: "R$" },
            { value: "USD", text: "$" },
          ],
        },
      ];
    },
  },
  mounted() {
    this.fields = this.allFields;
    this.loadTrackingRules();
    this.loadCheckpoints();
    this.checkQuery();
  },
  methods: {
    checkQuery() {
      if (this.$route.query.alertLogsId) {
        //this.$emit("switchTab", "alertLogs"); //emit if have more than one tab
        this.loadModalData(this.$route.query.alertLogsId);
        return;
      }
    },
    loadModalData(id) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query.alertLogsId) {
          this.$router.push({ query: { alertLogsId: id } });
        }
        /* this.$router.push({ query: {} }); */
        this.$helpers.openModal("ModalAlertLogsView");
        return;
      }
    },
    checkPaymentLoadModalData(event) {
      if (event.type == "duties_payment") {
        this.loadModalData(event.id);
        return;
      }
    },

    downloadCsvExample() {
      window.open("/files/import_values_and_statuses.csv", "_blank");
    },
    downloadInstructionsFile() {
      window.open(
        "/files/instructions_import_values_and_statuses.xls",
        "_blank",
      );
    },
    downloadModal() {
      this.$helpers.openModal("downloadModal");
    },
    updateStatusModal() {
      this.newLog.status = null;
      this.newLog.comment = "";

      this.$helpers.openModal("updateStatusModal");
    },
    uploadPdfModal() {
      //this.$bvModal.show("uploadPdfModals");
      this.$helpers.openModal("uploadPdfModal");
    },
    importValueStatus() {
      this.errors = {};
      this.newItem.name = "";
      this.chosenFile = "";
      this.file = null;

      this.$helpers.openModal("importValueStatus");
    },
    optionTook(event) {
      this[event]();
    },
    loadItems() {
      this.$store.dispatch(this.namespace + "/fetchItems", this.namespace);
    },
    sendFile() {
      this.file = new FormData();
      this.file.append("file", this.chosenFile, this.chosenFile.name);
      this.file.append("name", this.newItem.name);

      if (this.file) {
        this.isLoading = true;
        alertLogsService
          .sendPaymentPdf(this.file)
          .then(() => {
            this.$helpers.openModal("uploadPdfModal"); //togglemodal
          })
          .finally(() => (this.isLoading = false));
        this.loadItems();
      }
    },
    getPaymentPdf(logId) {
      alertLogsService.downloadPaymentCerti(logId).then((response) => {
        window.open(response.data.data.download_url);
      });
    },
    initiateFileCreation(dataQuery) {
      this.$helpers.openModal("downloadModal"); //togglemodal
      downloadsService.loadProcessing(dataQuery).then(() => {
        this.$router.push("/archives");
      });
    },
    downloadLogs() {
      this.initiateFileCreation({
        data_type: "logs",
        reference_ids: this.rowSelection,
      });
    },
    importFile() {
      this.file = new FormData();
      this.file.append("file", this.chosenFile, this.chosenFile.name);
      this.file.append("name", this.newItem.name);
      this.file.append("data_type", "import_value_status");
      downloadsService.loadProcessing(this.file);
      setTimeout(() => {
        this.$helpers.openModal("importValueStatus"); //toggle modal
        this.$router.push("/archives");
      }, 1500);
    },
    loadTrackingRules() {
      trackingRuleService.getAllTrackingRules().then((response) => {
        this.trackingRules = response.data.data.elements;
        const rulesOptions = this.trackingRules.map((rule) => ({
          value: rule.id,
          text: rule.name ? rule.name : rule.id,
        }));

        this.addSelectOptionsToFilter("rule", rulesOptions);
      });
    },
    getTrackingRuleName(id) {
      let trackingName = this.trackingRules.filter(
        (trackingRule) => trackingRule.id == id,
      );
      if (trackingName.length > 0) {
        return trackingName[0].name;
      }
      return "-";
    },
    updateMassiveStatus() {
      let dataToSend = {};
      dataToSend.ids = this.rowSelection;
      dataToSend.tracking_alert_log = this.newLog;
      this.loadingUpdateMassive = true;
      alertLogsService
        .updateMassiveLog(dataToSend)
        .then(() => {
          this.$helpers.openModal("confirmSave"); //togglemodal
          this.newLog.status = null;
          this.newLog.comment = null;
          this.newLog.new_tracking_number = null;
          this.loadItems();
        })
        .catch(() => {
          this.$bvToast.toast(
            "Something gone wrong... check the form and try again.",
            {
              title: "Error",
              solid: true,
              variant: "danger",
              autoHideDelay: 2000,
            },
          );
        })
        .finally(() => {
          this.loadingUpdateMassive = false;
        });
    },
    trackingCodeReturnMassive(ids) {
      let count = 0;
      const codes = [1000, 1001, 1002, 1003, 1004, 1005, 1006, 1007];

      ids.forEach((id) => {
        if (
          codes.includes(
            this.items.find((item) => item.id === id)?.checkpoint
              ?.tracking_code,
          )
        ) {
          count++;
        }
      });

      if (ids.length === count) {
        return true;
      }
      return false;
    },
    titleReturn(id) {
      let title;
      title = this.nobordistCheckpoints.find(
        (checkpoint) => checkpoint.id === id,
      );
      if (title) {
        return title.title;
      }
    },
    openDocument(attachment) {
      window.open(attachment);
    },
    addSelectOptionsToFilter(key, options) {
      this.filterOptions.find(
        (filterOption) => filterOption.key === key,
      ).options = options;
    },
    loadCheckpoints() {
      checkpointService.getAllCheckpoints().then((response) => {
        this.checkpoints = response.data.data.elements;
        const checkpointsOption = this.checkpoints.map((checkpoint) => ({
          value: checkpoint.tracking_code,
          text: `(${checkpoint.tracking_code}) ${checkpoint.title}`,
        }));

        this.addSelectOptionsToFilter("tracking_code", checkpointsOption);
      });
    },
  },
};
</script>
