var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('NbPageTitle',{staticClass:"pb-3 page-container",attrs:{"title":_vm.$t('trackingRulesPage.title'),"subTitle":_vm.$t('trackingRulesPage.subTitle')}},[_c('div',{staticClass:"btn-groups d-flex flex-column"},[_c('NbButton',{staticClass:"text-left",attrs:{"data-toggle":"modal","data-target":"#addTrackingRule"},on:{"click":function($event){return _vm.initializeTrackingRule()}}},[_c('NbIcon',{staticClass:"mr-2",attrs:{"icon":"plus"}}),_vm._v(" "+_vm._s(_vm.$t("trackingRulesPage.addTrackingRule"))+" ")],1)],1)]),_c('section',{staticClass:"page-container"},[_c('NbTabs',{staticClass:"mb-4",attrs:{"tabs":_vm.tabsOptions},on:{"switchTab":function($event){return _vm.switchTab($event)}}}),_c('NbTablev2',{ref:"trackingRulesTable",attrs:{"tableOf":"trackingRulesTable","namespace":_vm.namespace,"clickable":true,"allFields":_vm.allFields,"fields":_vm.fields,"filterOptions":_vm.filterOptions},on:{"reloadFields":function($event){_vm.fields = $event},"total":function($event){_vm.totalTrackingRules = $event},"clickedRow":function($event){return _vm.loadModalData($event.id, 'trackingRulesId', 'ModalTrackingRuleView')}},scopedSlots:_vm._u([{key:"cell(start_checkpoint_id)",fn:function(data){return [_c('div',{staticClass:"link-1",on:{"click":function($event){return _vm.loadModalData(
              data.item.start_checkpoint_id,
              'nobordistCheckpointId',
              'ModalNobordistCheckpointsView',
            )}}},[_vm._v(" "+_vm._s(_vm.loadCheckpointName(data.item.start_checkpoint_id))+" ")])]}},{key:"cell(end_checkpoint_id)",fn:function(data){return [_c('div',{staticClass:"link-1",on:{"click":function($event){return _vm.loadModalData(
              data.item.end_checkpoint_id,
              'nobordistCheckpointId',
              'ModalNobordistCheckpointsView',
            )}}},[_vm._v(" "+_vm._s(_vm.loadCheckpointName(data.item.end_checkpoint_id))+" ")])]}},{key:"cell(seller_id)",fn:function(data){return [_c('div',[_c('router-link',{staticClass:"link-1",attrs:{"to":`/users_management/sellers?id=` + data.item.seller_id}},[_vm._v(" "+_vm._s(_vm.loadSellerName(data.item.seller_id))+" ")])],1)]}},{key:"cell(contract_id)",fn:function(data){return [_c('div',[_c('router-link',{staticClass:"link-1",attrs:{"to":`/contracts/contracts?contractId=${data.item.contract_id}`}},[_vm._v(" "+_vm._s(_vm.loadContractName(data.item.contract_id))+" ")])],1)]}}])})],1),_c('NbFooter',{attrs:{"text":_vm.$t('components.footer.checkOurSupport'),"link":"https://nobordistinc.freshdesk.com/support/home"}}),_c('ModalNobordistCheckpointsView',{attrs:{"itemId":_vm.currentItemId}}),_c('ModalTrackingRuleView',{attrs:{"itemId":_vm.currentItemId},on:{"reloadTrackingRules":_vm.reloadTrackingRules}}),_c('ModalTrackingRuleCreate',{attrs:{"id":"addTrackingRule","trackingRule":_vm.newTrackingRule},on:{"update:trackingRule":function($event){_vm.newTrackingRule=$event},"update:tracking-rule":function($event){_vm.newTrackingRule=$event},"reloadTrackingRules":_vm.reloadTrackingRules}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }