<template>
  <div>
    <DatatableRoot
      namespace="tracking_notifications"
      :columns="tableColumns"
      :filters="tableFilters"
      @count="$emit('total', $event)"
      :url-state="false"
    >
      <template #volume_checkpoint_id="{ row }">
        <div class="link-1" @click="loadModalData(row.volume_checkpoint_id)">
          {{ row.volume_checkpoint_id }}
        </div>
      </template>
      <template #sent="{ row }">
        <IsCheckedIcon :checked="row.sent" />
      </template>
    </DatatableRoot>

    <ModalVolumeCheckpointsView
      id="ModalTrackingVolumeCheckpoints"
      :itemId="currentItemId"
    />
  </div>
</template>
<script>
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import ModalVolumeCheckpointsView from "@/views/checkpoints/volume_checkpoints/components/ModalVolumeCheckpointsView.vue";
import IsCheckedIcon from "@/components/IsCheckedIcon.vue";

export default {
  name: "TrackingNotifications",
  components: {
    DatatableRoot,
    ModalVolumeCheckpointsView,
    IsCheckedIcon,
  },
  props: {},
  data: () => {
    return {
      fields: [],
      currentItemId: "",
    };
  },
  created() {},
  methods: {
    checkQuery() {
      if (this.$route.query.volumeCheckpointId) {
        this.$emit("switchTab", "volumeCheckpoints");
        this.loadModalData(this.$route.query.volumeCheckpointId);
        return;
      }
    },
    loadModalData(id) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query.volumeCheckpointId) {
          this.$router.push({ query: { volumeCheckpointId: id } });
        }

        this.$helpers.openModal("ModalTrackingVolumeCheckpoints");
        return;
      }
    },
  },
  computed: {
    tableColumns() {
      return [
        { key: "id", label: "ID" },
        {
          key: "notification_type",
          label: this.$t("trackingNotificationsPage.fields.notificationType"),
        },
        {
          key: "volume_checkpoint_id",
          label: this.$t("trackingNotificationsPage.fields.volumeCheckpointID"),
        },
        {
          key: "data",
          label: this.$t("trackingNotificationsPage.fields.data"),
        },
        {
          key: "sent",
          label: this.$t("trackingNotificationsPage.fields.sent"),
        },
      ];
    },
    tableFilters() {
      return [
        {
          label: this.$t("trackingNotificationsPage.fields.notificationType"),
          inputs: [{ key: "notification_type" }],
        },
        {
          label: this.$t("trackingNotificationsPage.fields.volumeCheckpointID"),
          inputs: [{ key: "volume_checkpoint_id", type: "number" }],
        },
        {
          label: this.$t("trackingNotificationsPage.fields.data"),
          inputs: [{ key: "data" }],
        },
        {
          label: this.$t("trackingNotificationsPage.fields.sent"),
          inputs: [{ key: "sent", type: "boolean" }],
        },
        {
          label: this.$t("registrationDate"),
          inputs: [
            {
              key: "start_registration_date",
              type: "date",
            },
            {
              key: "end_registration_date",
              type: "date",
            },
          ],
        },
      ];
    },
  },
  watch: {},
};
</script>
