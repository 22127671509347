import PassThrough from "../../components/PassThrough";
import Accesibility from "../../views/settings/Accessibility.vue";
import PintingSetting from "@/views/settings/PintingSetting.vue";

const accesibility = {
  path: "/settings/accesibility",
  name: "accesibility",
  component: Accesibility,
  meta: {
    visible: true,
    label: "Accessibility",
    searchData: "accessibility",
  },
};
const printing_setting = {
  path: "/settings/printing_setting",
  name: "printing_setting",
  component: PintingSetting,
  meta: {
    visible: true,
    label: "PrintingSetting",
    searchData: "printing_setting",
  },
};

const settings_children = [accesibility, printing_setting];

const settings = {
  path: "/settings",
  name: "settings",
  component: PassThrough,
  meta: {
    visible: true,
    label: "Settings",
    icon: "settings",
  },
  children: settings_children,
};

export default settings;
