var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('NbModal',{attrs:{"id":_vm.id,"modalConfig":"modal-dialog-centered"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex mb-4"},[_c('h5',{staticClass:"heading-4 ml-3 mb-1"},[_vm._v(" "+_vm._s(_vm.$t("generalEmailsPage.createGeneralEmails"))+" ")])])]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('NbButton',{attrs:{"variant":"secondary","data-toggle":"modal","data-target":`#${_vm.id}`}},[_vm._v(" "+_vm._s(_vm.$t("close"))+" ")]),_c('NbButton',{on:{"click":function($event){return _vm.checkGeneralEmail()}}},[_vm._v(" "+_vm._s(_vm.newGeneralEmail.id ? _vm.$t("update") : _vm.$t("save"))+" ")])],1)]},proxy:true}])},[_c('div',{staticClass:"modal-body mb-5"},[_c('NbTextInput',{staticClass:"w-100 my-2",attrs:{"required":"","id":"title-generalemail","name":_vm.$t('generalEmailsPage.titleGeneral'),"placeholder":_vm.$t('generalEmailsPage.titleGeneral'),"error":_vm.errors.title},model:{value:(_vm.newGeneralEmail.title),callback:function ($$v) {_vm.$set(_vm.newGeneralEmail, "title", $$v)},expression:"newGeneralEmail.title"}}),_c('NbRadio',{staticClass:"w-100 pt-3",attrs:{"id":"generalemail-destinationtype","required":"","options":[
          {
            text: _vm.$t('generalEmailsPage.destination'),
            value: 'destination',
          },
          {
            text: _vm.$t('generalEmailsPage.destinationsSellers'),
            value: 'destinationsSellers',
          },
        ]},on:{"change":_vm.clearSelected},model:{value:(_vm.newGeneralEmail.destinationType),callback:function ($$v) {_vm.$set(_vm.newGeneralEmail, "destinationType", $$v)},expression:"newGeneralEmail.destinationType"}}),(_vm.newGeneralEmail.destinationType)?_c('div',[_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[(_vm.newGeneralEmail.destinationType == 'destination')?_c('div',{staticClass:"d-flex justify-content-between flex-wrap w-100"},[_c('NbTextInput',{staticClass:"w-100 my-2",attrs:{"required":"","id":"destination-generalemail","type":"textarea","placeholder":_vm.$t('generalEmailsPage.destination'),"error":_vm.errors.destination},model:{value:(_vm.newGeneralEmail.destination),callback:function ($$v) {_vm.$set(_vm.newGeneralEmail, "destination", $$v)},expression:"newGeneralEmail.destination"}})],1):_c('div',{staticClass:"d-flex justify-content-between flex-wrap w-100"},[_c('NbSelecMultipletInput',{staticClass:"w-100 my-2",attrs:{"required":"","id":"general-multiple-destinations","placeholder":"Select Sellers","options":_vm.allSellerEmails,"error":_vm.errors.destination},model:{value:(_vm.newGeneralEmail.destinations),callback:function ($$v) {_vm.$set(_vm.newGeneralEmail, "destinations", $$v)},expression:"newGeneralEmail.destinations"}})],1)]),_c('NbTextInput',{staticClass:"w-100 my-2",attrs:{"required":"","id":"body-generalemail","name":_vm.$t('generalEmailsPage.body'),"type":"textarea","placeholder":_vm.$t('generalEmailsPage.body'),"error":_vm.errors.body},model:{value:(_vm.newGeneralEmail.body),callback:function ($$v) {_vm.$set(_vm.newGeneralEmail, "body", $$v)},expression:"newGeneralEmail.body"}})],1):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }