<template>
  <div>
    <NbModal
      :id="id"
      :preventClose="true"
      modalConfig="modal-dialog-centered"
      width="76rem"
    >
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">
            {{ `${$t("alertLogsViewPage.title")} ${itemId}` }}
          </h5>
        </div>
      </template>
      <div>
        <NbCard
          id="alertLog_chard"
          :title="$t('generalInformation')"
          class="bg-gray-05"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap">
              <NbTextInput
                v-if="alertLog.order_data"
                id="incoterm"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-24 my-2"
                :name="$t('alertLogsViewPage.incoterm')"
                v-model="alertLog.incoterm"
                disabled
              />
              <NbTextInput
                v-if="alertLog.order_data"
                id="status"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-24 my-2"
                :name="$t('alertLogsViewPage.status')"
                v-model="alertLog.status"
                disabled
              />
              <NbTextInput
                id="date"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-24 my-2"
                :name="$t('alertLogsViewPage.date')"
                v-model="alertLog.created_at"
                disabled
              />
              <NbTextInput
                id="dateIso"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-24 my-2"
                :name="$t('alertLogsViewPage.dateIso')"
                v-model="alertLog.date_iso"
                disabled
              />
              <div
                class="w-24 mb-2 mt-2 link-1"
                @click="pushTo(`/shipping/orders?id=${alertLog.order_id}`)"
              >
                <NbTextInput
                  id="orderID"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  class="w-100 link-1"
                  :name="$t('alertLogsViewPage.orderID')"
                  v-model="alertLog.order_id"
                  disabled
                />
              </div>
              <NbTextInput
                id="orderNumber"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-24 my-2"
                :name="$t('alertLogsViewPage.orderNumber')"
                v-model="alertLog.order_number"
                disabled
              />
              <div
                v-if="alertLog.order_data"
                class="w-24 my-2 link-1"
                @click="pushTo(`/shipping/volumes?id=${alertLog.volume_id}`)"
              >
                <NbTextInput
                  id="volumeID"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  class="w-100 link-1"
                  :name="$t('alertLogsViewPage.volumeID')"
                  v-model="alertLog.volume_id"
                  disabled
                />
              </div>
              <div
                class="w-24 my-2 link-1"
                @click="
                  pushTo(`/users_management/sellers?id=${alertLog.seller_id}`)
                "
              >
                <NbTextInput
                  id="sellerID"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  class="w-100 link-1"
                  :name="$t('alertLogsViewPage.sellerID')"
                  v-model="alertLog.seller_id"
                  disabled
                />
              </div>
              <NbTextInput
                v-if="alertLog"
                id="taxID"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('alertLogsViewPage.taxID')"
                v-model="alertLog.tax_id"
                disabled
              />
              <NbTextInput
                v-if="alertLog"
                id="lastMileNumber"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('alertLogsViewPage.lastMileNumber')"
                v-model="alertLog.last_mile_number"
                disabled
              />
              <NbTextInput
                v-if="alertLog"
                id="productsValue"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-24 my-2"
                :name="$t('alertLogsViewPage.productsValue')"
                :prepend="getCurrency(alertLog.currency)"
                v-model="alertLog.products_value"
                disabled
              />
              <NbTextInput
                id="freightValue"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-24 my-2"
                :name="$t('alertLogsViewPage.freightValue')"
                :prepend="getCurrency(alertLog.currency)"
                v-model="alertLog.freight_value"
                disabled
              />
              <NbTextInput
                id="expectedTax"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-24 my-2"
                :name="$t('alertLogsViewPage.expectedTax')"
                :prepend="getCurrency(alertLog.currency)"
                v-model="alertLog.expected_tax"
                disabled
              />
              <NbTextInput
                id="realTax"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-24 my-2"
                :name="$t('alertLogsViewPage.realTax')"
                prepend="R$"
                v-model="alertLog.real_tax"
                disabled
              />
            </div>
          </template>
        </NbCard>
        <NbCard
          id="alertLog_edit_card"
          :title="$t('alertLogsViewPage.editableValues')"
          class="bg-gray-05 mt-3 w-50"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap">
              <NbTextInput
                id="sellerChargedAmount"
                type="money"
                :decimals="2"
                :min="0.0"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('alertLogsViewPage.sellerChargedAmount')"
                prepend="R$"
                :error="errors.charged_amount"
                v-model="alertLog.charged_amount"
              />
              <NbTextInput
                id="exchange"
                type="money"
                :decimals="2"
                :min="0.0"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('alertLogsViewPage.exchange')"
                prepend="R$"
                :error="errors.exchange"
                v-model="alertLog.exchange"
              />
              <div
                class="w-32-5 mt-2 d-flex flex-column text-center input-label"
              >
                <div class="mt-1 mb-2">
                  {{ $t("alertLogsViewPage.valueR$") }}
                </div>
                <div class="number-3 mt-2">R${{ value_brl }}</div>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div></div>
              <NbButton class="mt-2" @click="updateAlertLog()">
                {{ $t("save") }}
              </NbButton>
            </div>
          </template>
        </NbCard>
        <NbFooter
          :text="$t('components.footer.checkOurSupport')"
          link="https://nobordistinc.freshdesk.com/support/home"
        />
      </div>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            :data-target="`#${id}`"
            @click="resetQuery()"
          >
            {{ $t("close") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import AlertLogsService from "@/services/AlertLogsService";

const alertLogsService = new AlertLogsService();

export default {
  components: { NbModal, NbButton, NbTextInput, NbCard, NbFooter },
  name: "ModalAlertLogsView",
  props: {
    id: {
      type: String,
      default: "ModalAlertLogsView",
    },
    itemId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      alertLog: {},
      errors: {},
    };
  },
  methods: {
    updateAlertLog() {
      alertLogsService
        .updateLog(this.itemId, this.alertLog)
        .then()
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
          // NProgress.done();
        });
    },
    getCurrency(currency) {
      if (currency) {
        if (currency.toUpperCase() === "USD") {
          return "$ ";
        }
        return "R$ ";
      }
      return;
    },
    pushTo(path) {
      this.$helpers.closeModal(this.id);
      this.$router.push({
        path: path,
      });
    },
    resetQuery() {
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.push({ query: {} });
        return;
      }
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date, time) {
      const data = new Date(date);
      const hours = new Date(time);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear(),
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
  },
  computed: {
    value_brl: {
      get() {
        if (this.alertLog.charged_amount && this.alertLog.exchange) {
          const finalResult =
            Number(this.alertLog.charged_amount) *
            Number(this.alertLog.exchange);
          return finalResult.toFixed(2);
        } else {
          return "0.00";
        }
      },
      set(val) {
        return val;
      },
    },
  },
  watch: {
    itemId(newVal) {
      if (newVal) {
        alertLogsService.getLog(newVal).then((response) => {
          this.alertLog = response.data.data;
          //formatacao de datas iso e created at
          const time_date_iso = this.alertLog.date_iso;
          const date_date_iso = this.alertLog.date_iso;
          this.alertLog.date_iso = this.formateDate(
            date_date_iso,
            time_date_iso,
          );

          const time = this.alertLog.created_at;
          const date = this.alertLog.created_at;
          this.alertLog.created_at = this.formateDate(date, time);
        });
      }
    },
  },
};
</script>

<style scoped></style>
