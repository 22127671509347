<template>
  <div>
    <NbModal
      :id="id"
      :preventClose="true"
      modalConfig="modal-dialog-centered"
      width="76rem"
    >
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">
            {{ `${$t("trackingRulesViewPage.title")} ${itemId}` }}
          </h5>
        </div>
      </template>
      <div class="d-flex justify-content-between mb-5">
        <div></div>
        <NbCard
          id="actions-card"
          :title="$t('actions')"
          class="px-3"
          width="15rem"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap pt-3">
              <NbButton
                class="w-100 my-1 px-2 text-left text-14-20"
                variant="secondary"
                data-toggle="modal"
                data-target="#deleteTrackingRule"
                title="Delete Tracking Rule"
              >
                {{ $t("trackingRulesViewPage.deleteTrackingRule") }}
              </NbButton>
              <NbButton
                class="w-100 my-1 px-2 text-left text-14-20"
                data-toggle="modal"
                data-target="#updateTrackingRule"
                @click="copyTrackingRule()"
              >
                {{ $t("trackingRulesViewPage.editTrackingRule") }}
              </NbButton>
            </div>
          </template>
        </NbCard>
      </div>
      <div>
        <NbCard
          id="checkpoint_chard"
          :title="$t('generalInformation')"
          class="bg-gray-05"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap">
              <NbTextInput
                id="name-tracking-rule-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('trackingRulesViewPage.name')"
                v-model="trackingRule.name"
                disabled
              />
              <NbTextInput
                id="id-tracking-rule-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                name="ID"
                v-model="trackingRule.id"
                disabled
              />
              <div
                class="w-48-5 my-2 link-1"
                @click="
                  pushTo(
                    `/users_management/sellers?id=${trackingRule.seller_id}`,
                  )
                "
              >
                <NbTextInput
                  id="seller_name-tracking-rule-view"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  :name="$t('trackingRulesViewPage.seller')"
                  v-model="seller_name"
                  disabled
                />
              </div>
              <div
                class="w-48-5 my-2 link-1"
                @click="
                  pushTo(
                    `/contracts/contracts?contractId=${trackingRule.contract_id}`,
                  )
                "
              >
                <NbTextInput
                  id="contract_name-tracking-rule-view"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  :name="$t('trackingRulesViewPage.contract')"
                  v-model="contract_name"
                  disabled
                />
              </div>
              <div
                class="w-48-5 my-2 link-1"
                @click="loadModalData(trackingRule.start_checkpoint_id)"
              >
                <NbTextInput
                  id="start_checkpoint_name-tracking-rule-view"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  :name="$t('trackingRulesViewPage.startCheckpoint')"
                  v-model="start_checkpoint_name"
                  disabled
                />
              </div>
              <div
                class="w-48-5 my-2 link-1"
                @click="loadModalData(trackingRule.end_checkpoint_id)"
              >
                <NbTextInput
                  v-if="
                    newTrackingRule.tracking_mode == 'time_between_checkpoints'
                  "
                  id="end_checkpoint_id-tracking-rule-view"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  :name="$t('trackingRulesViewPage.endCheckpoint')"
                  v-model="end_checkpoint_name"
                  disabled
                />
              </div>

              <NbTextInput
                v-if="
                  newTrackingRule.tracking_mode == 'time_between_checkpoints'
                "
                id="days-tracking-rule-view"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('trackingRulesViewPage.daysLimit')"
                v-model="trackingRule.days"
                disabled
              />
            </div>
          </template>
        </NbCard>

        <div class="d-flex justify-content-between flex-wrap">
          <NbCard
            id="track_chard-tracking-rule-view"
            :title="$t('trackingRulesViewPage.tracking')"
            class="bg-gray-05 mt-3 w-100"
          >
            <template #body>
              <div class="d-flex justify-content-between flex-wrap">
                <NbTextInput
                  id="tracking_mode-tracking-rule-view"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  class="w-48-5 my-2"
                  :name="$t('trackingRulesViewPage.trackingMode')"
                  v-model="trackingRule.tracking_mode"
                  disabled
                />
                <NbTextInput
                  v-if="
                    trackingRule.configuration &&
                    typeof trackingRule.configuration.log == 'boolean'
                  "
                  id="log-tracking-rule-view"
                  variant="borderless-gray-10"
                  placeholder="false"
                  class="w-48-5 my-2"
                  :name="$t('trackingRulesViewPage.log')"
                  v-model="trackingRule.configuration.log"
                  disabled
                />
              </div>
            </template>
          </NbCard>
          <NbCard
            v-if="trackingRule.configuration"
            id="sec_chard-tracking-rule-view"
            :title="$t('trackingRulesViewPage.seller')"
            class="bg-gray-05 mt-3 w-48-5"
          >
            <template #body>
              <div class="d-flex justify-content-between flex-wrap">
                <NbTextInput
                  id="notify_seller-tracking-rule-view"
                  variant="borderless-gray-10"
                  placeholder="false"
                  :class="
                    trackingRule.configuration.notify_seller
                      ? 'w-48-5 my-2'
                      : 'w-100 my-2'
                  "
                  :name="$t('trackingRulesViewPage.notifySeller')"
                  v-model="trackingRule.configuration.notify_seller"
                  disabled
                />
                <NbTextInput
                  v-if="trackingRule.configuration.notify_seller"
                  id="seller_mail_title-tracking-rule-view"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  class="w-48-5 my-2"
                  :name="$t('trackingRulesViewPage.sellerMailTitle')"
                  v-model="trackingRule.configuration.seller_mail_title"
                  disabled
                />
                <NbTextInput
                  v-if="trackingRule.configuration.notify_seller"
                  id="seller_mail_body-tracking-rule-view"
                  variant="borderless-gray-10"
                  :textarea="true"
                  :placeholder="$t('notFound')"
                  class="w-100 my-2"
                  :name="$t('trackingRulesViewPage.sellerMailBody')"
                  v-model="trackingRule.configuration.seller_mail_body"
                  disabled
                />
              </div>
            </template>
          </NbCard>

          <NbCard
            v-if="trackingRule.configuration"
            id="third_chard-tracking-rule-view"
            :title="$t('trackingRulesViewPage.customer')"
            class="bg-gray-05 mt-3 w-48-5"
          >
            <template #body>
              <div class="d-flex justify-content-between flex-wrap">
                <NbTextInput
                  id="notify_customer-tracking-rule-view"
                  variant="borderless-gray-10"
                  placeholder="false"
                  :class="
                    trackingRule.configuration.notify_customer
                      ? 'w-48-5 my-2'
                      : 'w-100 my-2'
                  "
                  :name="$t('trackingRulesViewPage.notifyCustomer')"
                  v-model="trackingRule.configuration.notify_customer"
                  disabled
                />
                <NbTextInput
                  v-if="trackingRule.configuration.notify_customer"
                  id="customer_mail_title-tracking-rule-view"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  class="w-48-5 my-2"
                  :name="$t('trackingRulesViewPage.customerMailTitle')"
                  v-model="trackingRule.configuration.customer_mail_title"
                  disabled
                />
                <NbTextInput
                  v-if="trackingRule.configuration.notify_customer"
                  id="customer_mail_body-tracking-rule-view"
                  variant="borderless-gray-10"
                  :textarea="true"
                  :placeholder="$t('notFound')"
                  class="w-100 my-2"
                  :name="$t('trackingRulesViewPage.customerMailBody')"
                  v-model="trackingRule.configuration.customer_mail_body"
                  disabled
                />
              </div>
            </template>
          </NbCard>

          <NbCard
            v-if="trackingRule.configuration"
            id="four_chard-tracking-rule-view"
            :title="$t('trackingRulesViewPage.nobordist')"
            class="bg-gray-05 mt-3 w-100"
          >
            <template #body>
              <div class="d-flex justify-content-between flex-wrap">
                <NbTextInput
                  id="notify_nobordist-tracking-rule-view"
                  variant="borderless-gray-10"
                  placeholder="false"
                  :class="
                    trackingRule.configuration.notify_nobordist
                      ? 'w-32-5 my-2'
                      : 'w-100 my-2'
                  "
                  :name="$t('trackingRulesViewPage.notifyNobordist')"
                  v-model="trackingRule.configuration.notify_nobordist"
                  disabled
                />
                <NbTextInput
                  v-if="trackingRule.configuration.notify_nobordist"
                  id="nobordist_mail_title-tracking-rule-view"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  class="w-32-5 my-2"
                  :name="$t('trackingRulesViewPage.nobordistMailTitle')"
                  v-model="trackingRule.configuration.nobordist_mail_title"
                  disabled
                />
                <NbTextInput
                  v-if="trackingRule.configuration.notify_nobordist"
                  id="destination-tracking-rule-view"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  class="w-32-5 my-2"
                  :name="$t('trackingRulesViewPage.destination')"
                  v-model="trackingRule.destination"
                  disabled
                />
                <NbTextInput
                  v-if="trackingRule.configuration.notify_nobordist"
                  id="nobordist_mail_body-tracking-rule-viewv"
                  variant="borderless-gray-10"
                  :textarea="true"
                  :placeholder="$t('notFound')"
                  class="w-100 my-2"
                  :name="$t('trackingRulesViewPage.nobordistMailBody')"
                  v-model="trackingRule.configuration.nobordist_mail_body"
                  disabled
                />
              </div>
            </template>
          </NbCard>
          <NbCard
            v-if="
              trackingRule.configuration && trackingRule.configuration.webhook
            "
            id="five_chard-tracking-rule-view"
            :title="$t('trackingRulesViewPage.webhook')"
            class="bg-gray-05 mt-3 w-100"
          >
            <template #body>
              <div class="d-flex justify-content-between flex-wrap">
                <NbTextInput
                  id="notify_webhook-tracking-rule-view"
                  variant="borderless-gray-10"
                  placeholder="false"
                  :class="
                    trackingRule.configuration.notify_webhook
                      ? 'w-32-5 my-2'
                      : 'w-100 my-2'
                  "
                  :name="$t('trackingRulesViewPage.notifyWebhook')"
                  v-model="trackingRule.configuration.notify_webhook"
                  disabled
                />
                <NbTextInput
                  v-if="trackingRule.configuration.notify_webhook"
                  id="method-tracking-rule-view"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  class="w-32-5 my-2"
                  :name="$t('trackingRulesViewPage.method')"
                  v-model="trackingRule.configuration.webhook.method"
                  disabled
                />
                <NbTextInput
                  v-if="trackingRule.configuration.notify_webhook"
                  id="endpoint-tracking-rule-view"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  class="w-32-5 my-2"
                  :name="$t('trackingRulesViewPage.endpoint')"
                  v-model="trackingRule.configuration.webhook.endpoint"
                  disabled
                />
                <div
                  class="w-100 my-2"
                  v-if="trackingRule.configuration.notify_webhook"
                >
                  <label for="headers" class="input-label">
                    {{ $t("trackingRulesViewPage.headers") }}
                  </label>
                  <pre class="bg-gray-10 px-3 pt-2 pb-0">
                    <code>
{{ trackingRule.configuration.webhook.headers }}
                    </code>
                  </pre>
                </div>
                <NbTextInput
                  v-if="trackingRule.configuration.notify_webhook"
                  id="body-tracking-rule-view"
                  variant="borderless-gray-10"
                  :textarea="true"
                  :placeholder="$t('notFound')"
                  class="w-100 my-2"
                  :name="$t('trackingRulesViewPage.body')"
                  v-model="trackingRule.configuration.webhook.body"
                  disabled
                />
              </div>
            </template>
          </NbCard>
        </div>

        <NbFooter
          :text="$t('components.footer.checkOurSupport')"
          link="https://nobordistinc.freshdesk.com/support/home"
        />
      </div>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            :data-target="`#${id}`"
            @click="resetQuery()"
          >
            {{ $t("close") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
    <NbModal id="deleteTrackingRule" modalConfig="modal-dialog-centered">
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">
            {{ $t("trackingRulesViewPage.deleteTrackingRule") }}
          </h5>
        </div>
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            data-target="#deleteTrackingRule"
          >
            {{ $t("close") }}
          </NbButton>
          <NbButton @click="deleteTrackingRule()">
            {{ $t("delete") }}
          </NbButton>
        </div>
      </template>
    </NbModal>

    <ModalNobordistCheckpointsView
      id="trackrule-nobordistcheckpoint"
      :itemId="currentItemId"
      zindex="1051"
    />

    <ModalTrackingRuleCreate
      id="updateTrackingRule"
      :trackingRule.sync="newTrackingRule"
      @updateTrackingRule="
        getData();
        $emit('reloadTrackingRules');
      "
    />
  </div>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import ModalNobordistCheckpointsView from "@/views/checkpoints/nobordist_checkpoints/components/ModalNobordistCheckpointsView.vue";
import ModalTrackingRuleCreate from "@/views/logs/tracking_rules/components/ModalTrackingRuleCreate.vue";

import TrackingRuleService from "@/services/TrackingRuleService";
import CheckpointService from "@/services/CheckpointService";
import SellerService from "@/services/SellerService";
import ContractService from "@/services/ContractService";

const trackingRuleService = new TrackingRuleService();
const checkpointService = new CheckpointService();
const sellerService = new SellerService();
const contractService = new ContractService();

export default {
  components: {
    NbModal,
    NbButton,
    NbTextInput,
    NbCard,
    NbFooter,
    ModalNobordistCheckpointsView,
    ModalTrackingRuleCreate,
  },
  name: "ModalTrackingRuleView",
  props: {
    id: {
      type: String,
      default: "ModalTrackingRuleView",
    },
    itemId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      trackingRule: {},
      checkpoints: [],
      sellers: [],
      contracts: [],
      seller_name: "",
      contract_name: "",
      start_checkpoint_name: "",
      end_checkpoint_name: "",
      hkey: "",
      hvalue: "",
      newTrackingRule: {},
      currentItemId: "",
    };
  },
  created() {},
  computed: {},
  methods: {
    deleteTrackingRule() {
      trackingRuleService.deleteTrackingRule(this.itemId).then(() => {
        this.$helpers.closeAllModals();
        this.$router.push({ query: {} });
        this.$emit("reloadTrackingRules");
      });
    },
    loadModalData(id) {
      if (id) {
        this.currentItemId = id;

        if (!this.$route.query.nobordistCheckpointId) {
          this.$router.push({ query: { nobordistCheckpointId: id } });
        }

        this.$helpers.openModal("trackrule-nobordistcheckpoint");
        return;
      }
    },
    pushTo(path) {
      this.$helpers.closeModal(this.id);
      this.$router.push({
        path: path,
      });
    },
    replaceTrackingRules() {
      this.trackingRule = Object.assign({}, this.newTrackingRule);
    },
    copyTrackingRule() {
      this.errors = [];
      this.newTrackingRule = Object.assign({}, this.trackingRule);
    },
    resetQuery() {
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.push({ query: {} });
        return;
      }
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date) {
      const data = new Date(date);
      const formatedDate =
        data.getFullYear() +
        "-" +
        this.addZero(data.getMonth() + 1).toString() +
        "-" +
        this.addZero(data.getDate().toString());
      return formatedDate;
    },
    getData() {
      this.trackingRule = {};
      trackingRuleService.getTrackingRule(this.itemId).then((response) => {
        this.trackingRule = response.data.data;

        let date = this.trackingRule.created_at;
        this.trackingRule.created = this.formateDate(date);

        if (this.trackingRule.seller_id) {
          sellerService
            .getOneSeller(this.trackingRule.seller_id)
            .then((response) => {
              this.seller_name = response.data.data.name;
            });
        }
        if (this.trackingRule.contract_id) {
          contractService
            .getOneContract(this.trackingRule.contract_id)
            .then((response) => {
              this.contract_name = response.data.data.name;
            });
        }
        if (this.trackingRule.start_checkpoint_id) {
          checkpointService
            .getCheckpoint(this.trackingRule.start_checkpoint_id)
            .then((response) => {
              this.start_checkpoint_name = response.data.data.name;
            });
        }
        if (this.trackingRule.end_checkpoint_id) {
          checkpointService
            .getCheckpoint(this.trackingRule.end_checkpoint_id)
            .then((response) => {
              this.end_checkpoint_name = response.data.data.name;
            })
            .catch(() => {
              this.end_checkpoint_name = null;
            });
        }
      });
      //this is for edit tracking rules could call it when edit?
      /* checkpointService.getAllCheckpoints().then((response) => {
          this.checkpoints = response.data.data.elements;
        });
        sellerService.getAllSellers().then((response) => {
          this.sellers = response.data.data.elements;
        });
        contractService.getAllContracts().then((response) => {
          this.contracts = response.data.data.elements;
        }); */
    },
  },
  watch: {
    itemId(newVal) {
      if (newVal) {
        this.getData();
      }
    },
  },
};
</script>

<style scoped></style>
