import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import config from "./config";
import countries from "./countries";
import downloaded_files from "./downloads";
import overview from "./overview";
import shared from "./shared";
//shipping
import volumes from "./shipping/volumes";
import volume_reverses from "./shipping/volume_reverses";
import orders from "./shipping/orders";
import overpacks from "./shipping/overpacks";
import masterboxes from "./shipping/masterboxes";
import shipments from "./shipping/shipments";
import flights from "./shipping/flights";
import shipping_rules from "./shipping/shipping_rules";
import duties_and_taxes_status from "./shipping/duties_and_taxes_status";
//user-management
import users from "./users-management/users";
import carriers from "./users-management/carriers";
import sellers from "./users-management/sellers";
import groups from "./users-management/groups";
import permissions from "./users-management/permissions";
import platforms from "./users-management/platforms";
//finances
import seller_account_events from "./finances/seller-account-events";
import fiscal_payments from "./finances/fiscal-payments";
//contracts
import contracts from "./contracts/contracts";
import delivery_methods from "./contracts/delivery-methods";
import price_tables from "./contracts/price-tables";
import delivery_times from "./contracts/price-tables";
import shipstation_contracts from "./contracts/shipstation-contracts";
//logs
import tracking_alert_logs from "./logs/tracking_alert_logs";
import rejected_order_logs from "./logs/rejected_order_logs";
import logs from "./logs/nobordist_logs";
import bil_logs from "./logs/bil_logs";
import external_apis_logs from "./logs/external_logs";
import delayed_jobs from "./logs/delayed_jobs";
import tracking_rules from "./logs/tracking_rules";
import notifications from "./logs/notifications";
import user_notifications from "./logs/user_notifications";
import general_emails from "./logs/general_emails";
//checkpoints
import nobordist_checkpoints from "./checkpoints/nobordist_checkpoints";
import received_checkpoints from "./checkpoints/received_checkpoints";
import status_checkpoints from "./checkpoints/status_checkpoints";
import volume_checkpoints from "./checkpoints/volume_checkpoints";
import tracking_notifications from "./checkpoints/tracking_notifications";
import accessibility from "./accessibility.js";

Vue.use(Vuex);

const modules_general = {
  config,
  overview,
  shared,
  accessibility,
};

const modules_get_all_items = {
  contracts,
  countries,
  carriers,
  sellers,
  delayed_jobs,
  platforms,
};

const modules_get_main_items = {
  platforms,
  sellers,
};

const modules_get_twenty_items = {
  volumes,
  volume_reverses,
  orders,
  overpacks,
  masterboxes,
  shipments,
  flights,
  shipping_rules,
  duties_and_taxes_status,
  users,
  carriers,
  sellers,
  groups,
  permissions,
  platforms,
  contracts,
  delivery_methods,
  price_tables,
  tracking_alert_logs,
  rejected_order_logs,
  logs,
  external_apis_logs,
  tracking_rules,
  notifications,
  user_notifications,
  general_emails,
  nobordist_checkpoints,
  delivery_times,
  shipstation_contracts,
  received_checkpoints,
  status_checkpoints,
  volume_checkpoints,
  tracking_notifications,
  downloaded_files,
  bil_logs,
  seller_account_events,
  fiscal_payments,
};

const store = new Vuex.Store({
  modules: {
    ...modules_general,
    ...modules_get_twenty_items,
    ...modules_get_all_items,
    ...modules_get_main_items,
  },
  plugins: [createPersistedState()],
  state: {
    user: "",
    platform: "nb",
    site: "adm",
  },
  mutations: {
    SET_LOGGEDIN_USER(state, userData) {
      state.user = userData;
    },
    DELETE_LOGGEDIN_USER(state) {
      state.user = "";
    },
    SET_PLATFORM(state, platform) {
      state.platform = platform;
    },
    SET_SITE(state, site) {
      state.site = site;
    },
  },
  actions: {
    fetchAllData() {
      this.dispatch("nobordist_checkpoints/fetchAllItems", "checkpoints");
      this.dispatch("overview/fetchBalance");
      Object.keys(modules_get_twenty_items).forEach((namespace) => {
        let twentyItems = namespace + "/fetchItems";
        this.dispatch(twentyItems, namespace);
      });
      Object.keys(modules_get_all_items).forEach((namespace) => {
        let allItems = namespace + "/fetchAllItems";
        this.dispatch(allItems, namespace);
      });
    },
    fetchMainData() {
      Object.keys(modules_get_main_items).forEach((namespace) => {
        let allItems = namespace + "/fetchAllItems";
        this.dispatch(allItems, namespace);
      });
    },
  },
});

store.subscribe((mutation) => {
  const namespace = mutation.type.split("/")[0];
  const mutation_command = mutation.type.split("/")[1];
  const mutations_filter = ["SET_CURRENT_PAGE", "SET_LIMIT", "SET_FILTERS"];
  if (mutations_filter.includes(mutation_command)) {
    store.dispatch(namespace + "/fetchItems", namespace);
  }
});

export default store;
