<template>
  <NbButton type="button" @click="close" variant="secondary">
    {{ $t("close") }}
  </NbButton>
</template>
<script>
import NbButton from "@/components/buttons/NbButton.vue";
export default {
  inject: ["context"],
  components: {
    NbButton,
  },
  methods: {
    close() {
      this.context.close();
    },
  },
};
</script>
<style lang="scss" scoped></style>
