<template>
  <div>
    <div class="container">
      <h4 class="heading-4">1. {{ $t("flightsPage.selectMasterboxes") }}</h4>
      <p class="body-4">
        {{ $t("flightsPage.findAndSelectMasterboxes") }}
      </p>
    </div>

    <NbCardBox class="mx-4 mb-4">
      <form @submit.prevent="loadMasterboxes">
        <NbSearchInput
          v-model="search"
          id="search"
          :placeholder="`${$t(
            'flightsPage.typeMasterboxesReference',
          )}: ex: BRNBSTNAAAAAAAAAAAAAAA`"
        />

        <div v-if="totalResults !== null" class="results">
          <p v-if="totalResults === 0" class="body-4">
            {{ $t("notFound") }}
          </p>

          <button
            v-for="masterbox in filteredMasterboxes"
            :key="masterbox.id"
            :disabled="masterbox.selected || false"
            @click="selectMasterbox(masterbox)"
          >
            {{ masterbox.reference }}
          </button>
        </div>
      </form>
    </NbCardBox>
    <div v-show="selectedMasterboxes.length" class="container masterboxes">
      <h3 class="heading-3">{{ $t("flightsPage.selectedMasterboxes") }}</h3>

      <div class="d-flex flex-direction-column flex-direction-md-row">
        <table>
          <thead>
            <th>{{ $t("flightsPage.reference") }}</th>
            <th>{{ $t("volumes") }}</th>
            <th>{{ $t("actions") }}</th>
          </thead>
          <tbody>
            <tr v-for="item in selectedMasterboxes" :key="item.id">
              <td>{{ item.reference }}</td>
              <td>{{ item.volumes?.length || 0 }}</td>
              <td>
                <NbButton variant="tertiary" @click="removeMasterbox(item.id)">
                  {{ $t("remove") }}
                </NbButton>
              </td>
            </tr>
          </tbody>
        </table>
        <NbButton class="btn-next-step" @click="next">
          {{ $t("flightsPage.next") }}
        </NbButton>
      </div>
    </div>
  </div>
</template>

<script>
import NbSearchInput from "@/components/input/text/NbSearchInput.vue";
import NbCardBox from "@/components/cards/NbCardBox.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import MasterBoxService from "@/services/MasterBoxService";

const masterboxService = new MasterBoxService();

export default {
  components: {
    NbSearchInput,
    NbCardBox,
    NbButton,
  },
  data() {
    return {
      search: "",
      filteredMasterboxes: [],
      selectedMasterboxes: [],
      totalResults: null,
    };
  },
  watch: {
    search(value) {
      if (!value) {
        this.filteredMasterboxes = [];
        this.totalResults = null;
      }
    },
  },
  methods: {
    async loadMasterboxes() {
      if (!this.search.trim()) return;
      try {
        this.loading = true;
        const { data } = await masterboxService.getByReference(this.search);

        this.filteredMasterboxes = data.data.elements.map((item) => {
          if (
            this.selectedMasterboxes.find((selected) => selected.id == item.id)
          ) {
            item.selected = true;
          }

          return item;
        });
        this.totalResults = data.data.count;
      } catch (error) {
        const message =
          error?.response?.data?.messages?.[0] ||
          this.$t("errorMessages.genericError");
        this.$bvToast.toast(message, {
          variant: "danger",
        });
      } finally {
        this.loading = false;
      }
    },
    removeMasterbox(masterboxId) {
      const masterboxIndex = this.selectedMasterboxes.findIndex(
        (item) => item.id === masterboxId,
      );
      if (masterboxIndex !== -1) {
        this.selectedMasterboxes.splice(masterboxIndex, 1);
      }
    },
    next() {
      if (!this.selectedMasterboxes.length) {
        return;
      }

      this.$emit(
        "next",
        this.selectedMasterboxes.map((item) => item.reference),
      );
    },
    selectMasterbox(masterbox) {
      if (!this.selectedMasterboxes.find((item) => item.id == masterbox.id)) {
        this.selectedMasterboxes.push(masterbox);
      }
      this.search = "";
      this.totalResults = null;
      this.filteredMasterboxes = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.masterboxes {
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 0.5rem;
    thead {
      background-color: transparent;
      border: none;
      &:after {
        border: none;
      }
      th {
        text-align: left;
        padding: 0 1rem;
      }
    }
    tbody {
      tr {
        background-color: var(--gray-10);
        td {
          text-align: left;
          padding: 0.5rem 1rem;
          font-weight: 500;
          font-size: 0.85rem;
          &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
          }
          &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      }
    }
  }
  .btn-next-step {
    margin: 2.5rem;
  }
}

form {
  position: relative;
  .results {
    position: absolute;
    top: 2.5rem;
    width: 100%;
    padding: 1rem;
    border-radius: 4px;
    background-color: var(--white);
    border: 1px solid var(--gray-05);
    left: 0;
    max-height: 250px;
    z-index: 10;
    overflow-y: auto;
    button {
      border: none;
      display: block;
      background-color: transparent;
      width: 100%;
      text-align: left;
      padding: 0.5rem 1rem;
      &:hover {
        background-color: var(--gray-05);
      }
    }
  }
}
</style>
