var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeCollapse),expression:"closeCollapse"}],staticClass:"mt-0 relative"},[(_vm.name)?_c('div',{staticClass:"input-label",attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.configuredName)+" "),(_vm.helpText)?_c('NbHelpText',{staticClass:"mx-1",attrs:{"id":`${_vm.id}-popover`,"size":_vm.helpTextSize,"placement":"topright"}},[_vm._v(" "+_vm._s(_vm.helpText)+" ")]):_vm._e()],1):_vm._e(),_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:([`collapse-${_vm.id}`]),expression:"[`collapse-${id}`]"}],staticClass:"button select-button w-100",class:_vm.error[0]
        ? `is-invalid ${_vm.filled} ${_vm.isDisabled} ${_vm.size} ${_vm.variant}`
        : `${_vm.filled} ${_vm.isDisabled} ${_vm.size} ${_vm.variant}`,style:(_vm.customStyle),attrs:{"disabled":_vm.disabled,"id":_vm.id,"data-value":_vm.search},on:{"input":_vm.onInput,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.onPressEsc.apply(null, arguments)}}},[_c('input',{ref:"input",staticClass:"search",attrs:{"type":"text","data-value":_vm.search},domProps:{"value":_vm.search}}),(!_vm.search)?_c('span',[_vm._v(" "+_vm._s(_vm.showOptionSelected)+" ")]):_vm._e(),_c('span',{class:[
        'placeholder',
        { filled: _vm.optionSelected || _vm.search || _vm.optionSelected === 0 },
      ]},[_vm._v(" "+_vm._s(_vm.placeholderText)+" ")]),_c('i',{staticClass:"fas fa-angle-right",class:_vm.size ? '' : 'mt-1'})]),_c('div',{staticClass:"mt-0 relative"},[_c('b-collapse',{staticClass:"mt-0 select-colapse-card",attrs:{"id":`collapse-${_vm.id}`},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('b-card',{staticClass:"p-0 nb-selectinput-card"},_vm._l((_vm.filteredOptions),function(option){return _c('div',{key:option.value,class:{
            'text-disabled': option.disabled,
            selected: option.value == _vm.optionSelected,
          },on:{"click":function($event){return _vm.switchSelect(option)}}},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:([`collapse-${option.disabled ? '' : _vm.id}`]),expression:"[`collapse-${option.disabled ? '' : id}`]"}],staticClass:"select-options w-100"},[_c('span',{staticClass:"select-label",domProps:{"innerHTML":_vm._s(option.highlight || option.text)}})])])}),0)],1)],1),_c('ErrorFeedback',{attrs:{"error":_vm.error[0]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }