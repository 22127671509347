<template>
  <div class="nb-input" :class="errorsFeedback ? `is-invalid` : ``">
    <label
      v-if="name"
      class="input-label"
      :class="disabled ? 'not-allowed' : ''"
      :for="id"
    >
      {{ configuredName }}
      <NbHelpText
        v-if="helpText"
        :id="`${id}-popover`"
        class="mx-1"
        :size="helpTextSize"
        placement="topright"
      >
        {{ helpText }}
        <a
          v-if="helpTextLink"
          :href="helpTextLink.href"
          target="_blank"
          class="link-2"
          style="color: white !important"
        >
          {{ helpTextLink.text }}c
        </a>
      </NbHelpText>
    </label>
    <div :id="`${id}-input-wrapper`" class="d-flex input-wrapper">
      <Datepicker
        v-model="modelValue"
        :lang="language"
        :position="position"
        :class="`element nb-calendar ${size}`"
        :range="range"
        input-class="input-custom"
      />
    </div>
    <ErrorFeedback :error="errorsFeedback" />
  </div>
</template>

<script>
import "vue-datepicker-ui/lib/vuedatepickerui.css";
import VueDatepickerUi from "vue-datepicker-ui";
import ErrorFeedback from "@/components/generic/ErrorFeedback.vue";
import NbHelpText from "@/components/generic/NbHelpText.vue";

export default {
  components: {
    Datepicker: VueDatepickerUi,
    ErrorFeedback,
    NbHelpText,
  },
  props: {
    id: {
      type: String,
      default: "NbDatePicker",
    },
    value: {
      type: [Array, String, Date],
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    range: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: "right",
    },
    size: {
      type: String,
      default: "",
    },
    helpText: {
      type: String,
      required: false,
    },
    helpTextSize: {
      type: String,
      default: "sm",
    },
    helpTextLink: {
      type: Object,
      required: false,
    },
    error: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      internalErrors: [],
    };
  },
  methods: {
    formatDate(date) {
      if (date && date.toString() !== "Invalid Date") {
        // Obtém o year, mês e dia da date
        let internalDate = date;
        if (typeof date == "string") {
          const parts = date.split("-");
          internalDate = new Date(parts[0], parts[1] - 1, parts[2]);
        }
        const year = internalDate.getFullYear();
        const month = String(internalDate.getMonth() + 1).padStart(2, "0");
        const day = String(internalDate.getDate()).padStart(2, "0");

        return `${year}-${month}-${day}`;
      }
      return "";
    },
  },
  computed: {
    modelValue: {
      get() {
        if (
          typeof this.value == "string" &&
          this.value.length < 11 &&
          this.value.length > 1
        ) {
          //corrige que data quando usada como string volta 1 dia
          const parts = this.value.split("-");
          const internalDate = new Date(parts[0], parts[1] - 1, parts[2]);
          return internalDate;
        }
        return this.value;
      },
      set(val) {
        if (val) {
          this.$emit("shortDate", this.formatDate(val));
        }
        this.$emit("input", val);
      },
    },
    configuredName() {
      if (this.required) {
        return `${this.name} *`;
      }
      return this.name;
    },
    language() {
      const locale = this.$i18n.locale;

      return locale.toLowerCase().replace("_", "-");
    },
    errorsFeedback() {
      if (this.error.length > 0) {
        return this.error[0];
      }
      if (this.internalErrors.length > 0) {
        return this.internalErrors[0];
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
//not working in scoped repliqued in _form.css
.sm.v-calendar.nb-calendar .input-field input {
  height: 2.5rem;
}

.element {
  --v-calendar-picker-color: var(--gray-05);
  --v-calendar-input-bg-color: var(--gray-05);
  --v-calendar-select-bg-color: var(--gray-05);
  --v-calendar-border-color: #323b43;
  --v-calendar-triangle-color: var(--gray-05);
  --v-calendar-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.2);
  --v-calendar-top-shadow: 3px -14px 30px 0px rgba(0, 0, 0, 0.2);
  --v-calendar-text-color: var(--black);
  --v-calendar-action-color: #b8b8b9;
  --v-calendar-text-disabled-color: #b8b8b97c;
  --v-calendar-view-button-color: var(--black);
  --v-calendar-datepicker-icon-color: #b8b8b9;
  --v-calendar-datepicker-icon-size: 1.1rem;
  --v-calendar-active-bg-color: var(--primary);
  --v-calendar-active-text-color: var(--gray-05);
  --v-calendar-range-bg-color: #00189112;
  --v-calendar-view-button-font-weight: 700;
  --v-calendar-view-button-font-size: 1.1rem;
  --v-calendar-range-text-color: var(--black);
  --v-calendar-range-radius: 100%;
  --v-calendar-day-hover-bg-color: var(--gray-10);
  --v-calendar-day-width: 45px;
  --v-calendar-day-height: 45px;
  --v-calendar-day-font-size: 0.8rem;
  --v-calendar-day-font-weight: 500;
  --v-calendar-input-border: none;
  --v-calendar-input-text-color: var(--black);
  --v-calendar-input-font-size: 0.75rem;
  --v-calendar-input-font-weight: 700;
  --v-calendar-content-radius: 4px;
  --v-calendar-day-name-font-size: 0.9rem;
  --v-calendar-day-name-font-weight: 600;
  --v-calendar-day-name-color: #323b43;
  --v-calendar-year-font-size: 1.1rem;
  --v-calendar-year-color: #b8b8b9;
  --v-calendar-year-font-weight: 500;
  --v-calendar-input-bg-disable-color: rgb(245, 245, 245);
  --v-calendar-input-text-disable-color: #b8b8b9;
  --v-calendar-year-disabled-color: #b8b8b9;
  --v-calendar-year-disabled-bg-color: transparent;
  --v-calendar-year-padding: 10px;
  --v-calendar-year-border: none;
  --v-calendar-year-border-radius: none;
  --v-calendar-month-font-size: 1.1rem;
  --v-calendar-month-color: #b8b8b9;
  --v-calendar-month-font-weight: 500;
  --v-calendar-month-disabled-color: #b8b8b9;
  --v-calendar-month-disabled-bg-color: transparent;
  --v-calendar-month-padding: 8px;
  --v-calendar-month-border: none;
  --v-calendar-month-border-radius: none;
}
</style>
