<template>
  <div>
    <div class="d-md-flex justify-content-md-between">
      <NbPageTitle :title="$t('sellerAccountEventsPage.title')" />
      <NbButton @click="handleAddCreditModal">
        {{ $t("sellerAccountEventsPage.addCredit") }}
      </NbButton>
    </div>

    <section class="page-container mt-4">
      <NbTabs class="mb-4" :tabs="tabsOptions" />
      <NbTablev2
        tableOf="sellerAccountsEventsTable"
        namespace="seller_account_events"
        ref="sellerAccountsEventsTableRef"
        :clickable="true"
        :allFields="tableFields"
        :fields="fields"
        :tabs="true"
        :optionsWidth="260"
        :filterOptions="filterOptions"
        @reloadFields="fields = $event"
        @total="totalEvents = $event"
        selectable
        :selectedItems.sync="selected"
        @optionTook="optionTook($event)"
        :buttonOptions="buttonOptions"
      >
        <template #cell(seller_name)="data">
          <router-link
            style="width: 30px; height: 30px"
            class="link-1"
            :to="{
              name: 'users_management_seller_view',
              params: { seller_id: data.item.seller_id },
            }"
          >
            {{ data.item.seller_name }}
          </router-link>
        </template>
        <template #cell(category)="data">
          <UpcaseString :string="data.item.category" />
        </template>
        <template #cell(value)="data">
          <div v-if="data.item.value > 0" style="color: #00cb8b">
            + R$ {{ parseFloat(data.item.value).toFixed(2) }}
          </div>
          <div v-else style="color: #fb1f1f">
            - R$ {{ Math.abs(data.item.value).toFixed(2) }}
          </div>
        </template>

        <template #cell(created_at)="data">
          {{ data.item.created_at | dateTime }}
        </template>
      </NbTablev2>
    </section>

    <Modal v-model="showDownloadFinancesModal">
      <DownloadFinances :reference-ids="selected" />
    </Modal>

    <Modal v-model="showAddCreditModal">
      <AddCredit @success="$refs?.sellerAccountsEventsTableRef?.getData()" />
    </Modal>
  </div>
</template>

<script>
import SellerAccountService from "@/services/SellerAccountService";
import UpcaseString from "@/components/UpcaseString";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import moment from "moment";
import DownloadFinances from "./components/DownloadFinances.vue";
import Modal from "@/components/modal/Modal.vue";
import AddCredit from "./components/AddCredit.vue";

const sellerAccountService = new SellerAccountService();

export default {
  name: "SellerAccountEvents",
  components: {
    UpcaseString,
    NbTabs,
    NbTablev2,
    NbPageTitle,
    NbButton,
    DownloadFinances,
    Modal,
    AddCredit,
  },
  filters: {
    dateTime(value) {
      return value && moment(value).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  data: () => {
    return {
      selectMode: "multi",
      fields: [],
      totalRows: 1,
      columns: {},
      isInvisible: true,
      seller: {},
      selected: [],
      categories: {},
      new_event: null,
      namespace: "seller_account_events",
      currentTab: "all",
      totalEvents: 0,
      showDownloadFinancesModal: false,
      showAddCreditModal: false,
    };
  },
  computed: {
    buttonOptions() {
      return [
        {
          text: this.$t("download"),
          value: "onDownloadFinances",
        },
      ];
    },
    tabsOptions() {
      return [
        {
          id: this.currentTab,
          label: this.$t("all"),
          current: this.currentTab,
          found: this.totalEvents,
        },
      ];
    },
    sellers() {
      return this.$store.state.sellers.all_items;
    },
    tableFields() {
      return [
        { key: "id", label: "ID", sortable: false, thClass: ["small-content"] },
        {
          key: "seller_name",
          label: this.$t("sellerAccountEventsPage.seller"),
          sortable: false,
        },
        {
          key: "category",
          label: this.$t("sellerAccountEventsPage.fields.category"),
          sortable: true,
        },
        {
          key: "event_attachment",
          label: this.$t("sellerAccountEventsPage.fields.attachment"),
          sortable: false,
        },
        {
          key: "value",
          label: this.$t("sellerAccountEventsPage.fields.value"),
          sortable: true,
        },
        {
          key: "description",
          label: this.$t("sellerAccountEventsPage.fields.description"),
          sortable: true,
          thClass: ["large-content"],
        },
        {
          key: "created_at",
          label: this.$t("sellerAccountEventsPage.fields.creationDate"),
          sortable: true,
        },
      ];
    },
    filterOptions() {
      return [
        {
          key: "seller_id",
          label: this.$t("sellerAccountEventsPage.seller"),
          type: "select",
          options: this.sellers.map((item) => ({
            text: item?.name?.concat(` (${item.id})`),
            value: item.id,
          })),
        },
        {
          key: "start_value",
          label:
            this.$t("sellerAccountEventsPage.fields.value") + ` (Min and Max)`,
          type: "money",
          startKey: "start_value",
          endKey: "end_value",
          currency: "R$",
        },
        {
          key: "registration_date",
          label: this.$t("registrationDate"),
          type: "dates",
        },
      ];
    },
  },
  methods: {
    handleAddCreditModal() {
      this.showAddCreditModal = true;
    },
    onDownloadFinances() {
      this.showDownloadFinancesModal = true;
    },
    optionTook(event) {
      this[event]();
    },
    getCategories() {
      sellerAccountService.getCategories(this.seller_id).then((response) => {
        this.categories = response.data.data;
      });
    },
  },
  created() {
    this.getCategories();
  },
};
</script>

<style scoped>
.seller-link {
  width: 10px;
  height: 10px;
  cursor: pointer;
  color: var(--secondary);
}
</style>
