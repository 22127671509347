<template>
  <div>
    <NbModal
      :id="id"
      :preventClose="true"
      modalConfig="modal-dialog-centered"
      width="76rem"
    >
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">
            {{ `${$t("gGeneralEmailsViewPage.title")} ${itemId}` }}
          </h5>
        </div>
      </template>
      <!-- edit and delete general emails
      <div class="d-flex justify-content-between mb-5">
        <div></div>
        <NbCard
          id="actions-card"
          :title="$t('actions')"
          class="px-3"
          width="15rem"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap pt-3">
              <NbButton
                class="w-100 my-1 px-2 text-left text-14-20"
                variant="secondary"
                data-toggle="modal"
                data-target="#deleteGeneralEmail"
                title="Delete General Email"
              >
                {{ $t("gGeneralEmailsViewPage.deleteGeneralEmail") }}
              </NbButton>
              <NbButton
                class="w-100 my-1 px-2 text-left text-14-20"
                data-toggle="modal"
                data-target="#updateGeneralEmail"
                @click="copyGeneralEmail()"
              >
                {{ $t("gGeneralEmailsViewPage.editGeneralEmail") }}
              </NbButton>
            </div>
          </template>
        </NbCard>
      </div>
      -->
      <div>
        <NbCard
          id="generalemail_chard"
          :title="$t('generalInformation')"
          class="bg-gray-05"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap">
              <NbTextInput
                id="general_email_id"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('gGeneralEmailsViewPage.iD')"
                v-model="generalEmail.id"
                disabled
              />
              <NbTextInput
                id="general_email_gsent"
                variant="borderless-gray-10"
                placeholder="false"
                class="w-48-5 my-2"
                :name="$t('gGeneralEmailsViewPage.sent')"
                v-model="generalEmail.sent"
                disabled
              />
              <NbTextInput
                id="general_email_title"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('gGeneralEmailsViewPage.titleGeneral')"
                v-model="generalEmail.title"
                disabled
              />
              <NbTextInput
                id="general_email_destinationFormated"
                variant="borderless-gray-10"
                :textarea="true"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('gGeneralEmailsViewPage.destination')"
                v-model="generalEmail.destinationFormated"
                disabled
              />
              <NbTextInput
                id="general_email_body"
                variant="borderless-gray-10"
                :textarea="true"
                :placeholder="$t('notFound')"
                class="w-100 my-2"
                :name="$t('gGeneralEmailsViewPage.body')"
                v-model="generalEmail.body"
                disabled
              />
            </div>
          </template>
        </NbCard>
        <NbFooter
          :text="$t('components.footer.checkOurSupport')"
          link="https://nobordistinc.freshdesk.com/support/home"
        />
      </div>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            :data-target="`#${id}`"
            @click="resetQuery()"
          >
            {{ $t("close") }}
          </NbButton>
        </div>
      </template>
    </NbModal>

    <!-- edit and delete general emails
    <NbModal id="deleteGeneralEmail" modalConfig="modal-dialog-centered">
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">
            {{ $t("gGeneralEmailsViewPage.deleteGeneralEmail") }}
          </h5>
        </div>
      </template>
      <template v-slot:body>
        {{ $t("gGeneralEmailsViewPage.sureWantDelete") }}
      </template>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            data-target="#deleteGeneralEmail"
          >
            {{ $t("close") }}
          </NbButton>
          <NbButton @click="deleteGeneralEmail()">
            {{ $t("delete") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
     
    //edit delete general email
    <ModalGeneralEmailsCreate
      id="updateGeneralEmail"
      :generalEmail.sync="newGeneralEmail"
      @uploadGeneralEmail="
        getData();
        $emit('reloadGeneralEmail');
      "
    />
    -->
  </div>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
//import ModalGeneralEmailsCreate from "@/views/logs/general_emails/components/ModalGeneralEmailsCreate.vue";

import GeneralEmailService from "@/services/GeneralEmailService";

const generalEmailService = new GeneralEmailService();

export default {
  components: {
    NbModal,
    NbButton,
    NbTextInput,
    NbCard,
    NbFooter,
    //ModalGeneralEmailsCreate,
  },
  name: "ModalGeneralEmailsView",
  props: {
    id: {
      type: String,
      default: "ModalGeneralEmailsView",
    },
    itemId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      generalEmail: {},
      newGeneralEmail: {},
    };
  },
  created() {},
  computed: {},
  methods: {
    copyGeneralEmail() {
      this.newGeneralEmail = Object.assign({}, this.generalEmail);
      this.newGeneralEmail.destinationType = "destination";
    },
    getData() {
      generalEmailService.getEmail(this.itemId).then((response) => {
        this.generalEmail = response.data.data;
        this.generalEmail.destinationFormated =
          this.generalEmail.destination.replace(/,/g, ", ");
        this.generalEmail.created = this.formateDate(
          this.generalEmail.created_at,
        );
      });
    },
    resetQuery() {
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.push({ query: {} });
        return;
      }
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date) {
      const data = new Date(date);
      const formatedDate =
        data.getFullYear() +
        "-" +
        this.addZero(data.getMonth() + 1).toString() +
        "-" +
        this.addZero(data.getDate().toString());
      return formatedDate;
    },
    deleteGeneralEmail() {
      generalEmailService.deleteGeneralEmail(this.itemId).then(() => {
        this.$helpers.closeAllModals();
        this.$router.push({ query: {} });
        this.$emit("reloadGeneralEmail");
      });
    },
  },
  watch: {
    itemId(newVal) {
      if (newVal) {
        this.getData();
      }
    },
  },
};
</script>

<style scoped></style>
