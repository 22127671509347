<template>
  <div>
    <div class="d-flex mb-0">
      <input
        :id="id"
        v-model="content"
        class="mr-2 nb-checkbox"
        :class="disabled ? 'not-allowed' : ''"
        :style="boxStyle"
        :disabled="disabled"
        type="checkbox"
        @change.stop="handleInput"
      />
      <label
        class="nbcheckbox-label pointer"
        :class="disabled ? 'not-allowed' : ''"
        :for="id"
        :style="labelStyle"
      >
        {{ name }}
      </label>
    </div>
    <ErrorFeedback :error="error[0]" />
  </div>
</template>

<script>
import ErrorFeedback from "../generic/ErrorFeedback.vue";

export default {
  components: {
    ErrorFeedback,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
    labelStyle: {
      type: String,
      required: false,
      default: "",
    },
    boxStyle: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    helpText: {
      type: String,
      required: false,
      default: "",
    },
    helpTextSize: {
      type: String,
      default: "sm",
    },
    error: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.$emit("change", newValue);
      if (this.content !== newValue) {
        this.content = newValue;
      }
    },
  },
  methods: {
    handleInput() {
      this.$emit("input", this.content);
    },
  },
};
</script>

<style scoped>
input[type="checkbox"].nb-checkbox {
  min-width: 1rem;
  height: 1rem;
}
/* input[type="checkbox"].nb-checkbox:focus {
  box-shadow: 0px 0px 0px 1px var(--primary) !important;
} */
input[type="checkbox"].nb-checkbox:hover {
  filter: invert(20%) sepia(2%) saturate(592%) hue-rotate(238deg)
    brightness(114%) contrast(81%);
}
input[type="checkbox"].nb-checkbox:checked {
  accent-color: #34495e;
}
input[type="checkbox"].nb-checkbox:checked:hover {
  filter: none;
}
input[type="checkbox"] + label {
  margin-bottom: 0px;
  font: normal normal normal 14px/18px var(--font-family-base);
  letter-spacing: 0px;
  color: var(--gray-60);
}
input[type="checkbox"] + label:hover {
  color: var(--black);
}
input[type="checkbox"]:checked + label {
  font: normal normal 600 14px/18px var(--font-family-base);
  color: var(--black);
}
</style>
