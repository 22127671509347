<template>
  <div>
    <div
      class="heading d-md-flex justify-content-md-between align-items-md-center"
    >
      <NbPageTitle
        :title="$t('masterboxesPage.title')"
        :subTitle="$t('masterboxesPage.subtitle')"
      />

      <NbButton
        @click="isCreateMasterboxModalOpen = true"
        class="btn-add d-flex justify-content-start align-items-center"
      >
        <NbIcon icon="plus" class="mr-2" />
        {{ $t("masterboxesPage.createMasterbox") }}
      </NbButton>
    </div>

    <section class="page-container">
      <NbTabs class="mt-5 mb-4" :tabs="tabsOptions" />

      <DatatableRoot
        namespace="masterboxes"
        ref="masterboxesRef"
        :clickable="true"
        :columns="tableColumns"
        :filters="tableFilters"
        @rowClick="showMasterbox($event.id)"
        @count="totalMasterboxes = $event"
        :limit="10"
        :limits="[10]"
      >
        <template #id="{ row }">
          <NbButton variant="tertiary" @click="showMasterbox(row.id)">
            {{ row.id }}
          </NbButton>
        </template>
        <template #carriers="{ row }">
          <div v-if="row.carriers?.length > 0">
            <router-link
              v-for="carrier in row.carriers"
              :key="carrier.id"
              class="link-1"
              :to="'/contracts/carriers?carrierId=' + carrier.id"
            >
              {{ carrier.name ? carrier.name : "-" }}
            </router-link>
          </div>
          <span v-else>-</span>
        </template>
        <template #shipment_id="{ row }">
          <router-link
            v-if="row.shipment_id"
            class="link-1"
            :to="'/shipping/shipments?id=' + row.shipment_id"
          >
            {{ row.shipment_id }}
          </router-link>
          <span v-else>-</span>
        </template>
        <template #flight_id="{ row }">
          <router-link
            v-if="row?.shipment?.flight_id"
            class="link-1"
            :to="'/shipping/flights?id=' + row?.shipment?.flight_id"
          >
            {{ row?.shipment?.flight_id }}
          </router-link>
          <span v-else>-</span>
        </template>
        <template #volumes_amount="{ row }">
          {{ row.volumes.length }}
        </template>
        <template #reference="{ row }">
          <NbButton
            variant="tertiary"
            v-copy="row.reference"
            v-b-tooltip.hover
            :title="$t('clickToCopy')"
          >
            {{ row.reference }}
          </NbButton>
        </template>
        <template #cn35_label="{ row }">
          <NbButton variant="tertiary" @click="generateLabel(row.id)">
            {{ $t("download") }}
            <NbIcon class="ml-1" icon="download" />
          </NbButton>
        </template>
      </DatatableRoot>
    </section>

    <Modal v-model="isMasterboxModalOpen" size="lg" @close="handleCloseModal">
      <MasterboxModal
        :masterbox-id="masterboxSelectedId"
        @refresh="handleCloseModal(true)"
      />
    </Modal>

    <Modal v-model="isCreateMasterboxModalOpen">
      <MasterboxCreateModal @success="$refs?.masterboxesRef?.getData()" />
    </Modal>
  </div>
</template>

<script>
import MasterBoxService from "@/services/MasterBoxService";
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import DatatableRoot from "@/components/datatable/DatatableRoot.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import { copyClipboard } from "@/directives/copy-clipboard";
import MasterboxModal from "./components/MasterboxModal.vue";
import queryParamsMixin from "@/mixins/query-params-mixin";
import MasterboxCreateModal from "./components/MasterboxCreateModal.vue";
import Modal from "@/components/modal/Modal.vue";

const masterBoxService = new MasterBoxService();

const tabs = {
  ALL: "all",
};

export default {
  name: "MasterBoxes",
  directives: {
    copy: copyClipboard,
  },
  mixins: [queryParamsMixin],
  components: {
    NbPageTitle,
    DatatableRoot,
    NbButton,
    NbIcon,
    MasterboxModal,
    MasterboxCreateModal,
    NbTabs,
    Modal,
  },
  data: () => {
    return {
      masterboxSelectedId: null,
      openCreateMasterboxModal: false,
      fields: [],
      totalMasterboxes: 0,
      currentTab: tabs.ALL,
      isMasterboxModalOpen: false,
      isCreateMasterboxModalOpen: false,
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: tabs.ALL,
          label: this.$t("all"),
          current: this.currentTab,
          found: this.totalMasterboxes,
        },
      ];
    },
    items() {
      return this.$store.state[this.namespace].items;
    },
    tableColumns() {
      return [
        { key: "id", label: "ID", sortable: false, thClass: ["small-content"] },
        {
          key: "reference",
          label: this.$t("masterboxesPage.reference"),
        },
        {
          key: "cn35_label",
          label: this.$t("masterboxesPage.cN35Label"),
        },
        {
          key: "shipment_id",
          label: this.$t("masterboxesPage.shipmentID"),
        },
        {
          key: "flight_id",
          label: this.$t("shipmentsPage.fields.flightID"),
        },
        {
          key: "carriers",
          label: this.$t("masterboxesPage.mbCarriers"),
        },
        {
          key: "volumes_amount",
          label: this.$t("masterboxesPage.volumesAmount"),
        },
        {
          key: "created_at",
          label: this.$t("masterboxesPage.creationDate"),
        },
      ];
    },
    tableFilters() {
      return [
        {
          label: this.$t("masterboxesPage.volumesAmount"),
          inputs: [
            {
              key: "volume_amount",
              type: "number",
            },
          ],
        },
        {
          label: this.$t("masterboxesPage.reference"),
          inputs: [
            {
              key: "reference",
            },
          ],
        },
        {
          label: this.$t("masterboxesPage.shipmentID"),
          inputs: [{ key: "shipment_id" }],
        },
        {
          label: this.$t("shipmentsPage.fields.flightID"),
          inputs: [
            {
              key: "flight_id",
              type: "number",
            },
          ],
        },
        {
          label: this.$t("registrationDate"),
          inputs: [
            {
              key: "start_registration_date",
              type: "date",
            },
            {
              key: "end_registration_date",
              type: "date",
            },
          ],
        },
      ];
    },
  },
  methods: {
    showMasterbox(id) {
      this.masterboxSelectedId = id;
      this.setUrlSearchParam("id", id);
      this.isMasterboxModalOpen = true;
    },
    handleCloseModal(loadContent = false) {
      this.masterboxSelectedId = null;
      this.setUrlSearchParam("id");

      if (loadContent) {
        this.$refs?.masterboxesRef?.getData();
      }
    },
    generateLabel(id) {
      masterBoxService.generateMasterBoxLabel(id).then((response) => {
        if (
          // eslint-disable-next-line no-prototype-builtins
          response.data.hasOwnProperty("data") &&
          // eslint-disable-next-line no-prototype-builtins
          response.data.data.hasOwnProperty("download_url")
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
  },
  mounted() {
    const queryId = this.$route.query?.id;
    if (queryId) {
      this.showMasterbox(queryId);
    }
  },
};
</script>
