var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.disabled && _vm.variant)?_c('NbTextField',{attrs:{"placeholder":_vm.placeholder,"textarea":_vm.textarea,"id":_vm.id,"name":_vm.configuredName,"variant":"borderless-gray-10","prepend":_vm.prepend,"append":_vm.append,"type":_vm.type,"decimals":_vm.decimals,"value":_vm.textContent}}):_c('div',{staticClass:"nb-input",class:_vm.errorsFeedback ? `is-invalid ${_vm.variant}` : `${_vm.variant}`},[(_vm.name)?_c('label',{staticClass:"input-label",class:_vm.disabled ? 'not-allowed' : '',attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.configuredName)+" "),(_vm.helpText)?_c('NbHelpText',{staticClass:"mx-1",attrs:{"id":`${_vm.id}-popover`,"size":_vm.helpTextSize,"placement":"topright"}},[_vm._v(" "+_vm._s(_vm.helpText)+" "),(_vm.helpTextLink)?_c('a',{staticClass:"link-2",staticStyle:{"color":"white !important"},attrs:{"href":_vm.helpTextLink.href,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.helpTextLink.text)+" ")]):_vm._e()]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"d-flex input-wrapper",class:_vm.wrapperClasses,attrs:{"id":`${_vm.id}-input-wrapper`}},[(_vm.prepend)?_c('div',{staticClass:"fake-input pointer prepend",class:`
        ${_vm.textContent && !_vm.disabled ? 'text-black ' : ''}
        ${_vm.errorsFeedback ? 'is-invalid ' : ''}
        ${_vm.disabled ? 'disabled' : ''}
      `,style:(_vm.prependStyle)},[_vm._v(" "+_vm._s(_vm.prepend)+" ")]):_vm._e(),(_vm.masks)?_c('InputFacade',{staticClass:"nb form-control input",class:`${
        _vm.errorsFeedback
          ? _vm.disabled
            ? 'is-invalid not-allowed'
            : 'is-invalid'
          : _vm.disabled
            ? 'not-allowed'
            : ''
      }`,attrs:{"tabindex":_vm.tabindex,"type":_vm.type == 'money' ? 'number' : _vm.type,"min":_vm.min,"step":_vm.step,"id":_vm.id,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"maxlength":_vm.maxlength,"autocomplete":_vm.autocomplete,"onkeypress":_vm.onkeypress,"mask":_vm.masks},on:{"input":function($event){return _vm.onInput($event)},"blur":_vm.onBlur,"focus":function($event){_vm.autoSelect ? $event.target.select() : false;
        _vm.inputFocused();}},model:{value:(_vm.textContent),callback:function ($$v) {_vm.textContent=$$v},expression:"textContent"}}):(_vm.type == 'textarea')?_c('b-form-textarea',{staticClass:"nb form-control input textarea",class:`${
        _vm.errorsFeedback
          ? _vm.disabled
            ? 'is-invalid not-allowed'
            : 'is-invalid'
          : _vm.disabled
            ? 'not-allowed'
            : ''
      }`,attrs:{"tabindex":_vm.tabindex,"id":_vm.id,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"maxlength":_vm.maxlength,"autocomplete":_vm.autocomplete,"onkeypress":_vm.onkeypress,"rows":"10"},on:{"input":function($event){return _vm.onInput($event)},"blur":_vm.onBlur,"focus":function($event){_vm.autoSelect ? $event.target.select() : false;
        _vm.inputFocused();}},model:{value:(_vm.textContent),callback:function ($$v) {_vm.textContent=$$v},expression:"textContent"}}):((_vm.type == 'money' ? 'number' : _vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.textContent),expression:"textContent"}],staticClass:"nb form-control input",class:`${
        _vm.errorsFeedback
          ? _vm.disabled
            ? 'is-invalid not-allowed'
            : 'is-invalid'
          : _vm.disabled
            ? 'not-allowed'
            : ''
      }`,attrs:{"tabindex":_vm.tabindex,"min":_vm.min,"step":_vm.step,"id":_vm.id,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"maxlength":_vm.maxlength,"autocomplete":_vm.autocomplete,"onkeypress":_vm.onkeypress,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.textContent)?_vm._i(_vm.textContent,null)>-1:(_vm.textContent)},on:{"input":function($event){return _vm.onInput($event)},"blur":_vm.onBlur,"focus":function($event){_vm.autoSelect ? $event.target.select() : false;
        _vm.inputFocused();},"change":function($event){var $$a=_vm.textContent,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.textContent=$$a.concat([$$v]))}else{$$i>-1&&(_vm.textContent=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.textContent=$$c}}}}):((_vm.type == 'money' ? 'number' : _vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.textContent),expression:"textContent"}],staticClass:"nb form-control input",class:`${
        _vm.errorsFeedback
          ? _vm.disabled
            ? 'is-invalid not-allowed'
            : 'is-invalid'
          : _vm.disabled
            ? 'not-allowed'
            : ''
      }`,attrs:{"tabindex":_vm.tabindex,"min":_vm.min,"step":_vm.step,"id":_vm.id,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"maxlength":_vm.maxlength,"autocomplete":_vm.autocomplete,"onkeypress":_vm.onkeypress,"type":"radio"},domProps:{"checked":_vm._q(_vm.textContent,null)},on:{"input":function($event){return _vm.onInput($event)},"blur":_vm.onBlur,"focus":function($event){_vm.autoSelect ? $event.target.select() : false;
        _vm.inputFocused();},"change":function($event){_vm.textContent=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.textContent),expression:"textContent"}],staticClass:"nb form-control input",class:`${
        _vm.errorsFeedback
          ? _vm.disabled
            ? 'is-invalid not-allowed'
            : 'is-invalid'
          : _vm.disabled
            ? 'not-allowed'
            : ''
      }`,attrs:{"tabindex":_vm.tabindex,"min":_vm.min,"step":_vm.step,"id":_vm.id,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"maxlength":_vm.maxlength,"autocomplete":_vm.autocomplete,"onkeypress":_vm.onkeypress,"type":_vm.type == 'money' ? 'number' : _vm.type},domProps:{"value":(_vm.textContent)},on:{"input":[function($event){if($event.target.composing)return;_vm.textContent=$event.target.value},function($event){return _vm.onInput($event)}],"blur":_vm.onBlur,"focus":function($event){_vm.autoSelect ? $event.target.select() : false;
        _vm.inputFocused();}}}),(_vm.append)?_c('div',{staticClass:"fake-input pointer append",class:` 
        ${_vm.textContent ? 'text-black ' : ''}
        ${_vm.errorsFeedback ? 'is-invalid' : ''}
      `},[_vm._v(" "+_vm._s(_vm.append)+" ")]):_vm._e()],1),_c('ErrorFeedback',{attrs:{"error":_vm.errorsFeedback}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }