<template>
  <div>
    <NbModal :id="id" modalConfig="modal-dialog-centered" width="50rem">
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">
            {{ $t("trackingRulesPage.addNewTrackingRule") }}
          </h5>
        </div>
      </template>
      <div class="modal-body mb-5">
        <NbRadio
          id="tracking_mode-tracking-rules"
          :name="$t('trackingRulesPage.trackingMode')"
          required
          :options="[
            {
              text: $t('trackingRulesPage.receivedCheckpoint'),
              value: 'received_checkpoint',
            },
            {
              text: $t('trackingRulesPage.timeBetweenTwoCheckpoints'),
              value: 'time_between_checkpoints',
            },
          ]"
          v-model="newTrackingRule.tracking_mode"
        />
        <div v-if="newTrackingRule.tracking_mode !== null">
          <div class="d-flex justify-content-between flex-wrap">
            <NbTextInput
              id="name-tracking-rules"
              class="w-100 my-2"
              :name="$t('trackingRulesPage.name')"
              :placeholder="$t('name')"
              :error="errors.name"
              v-model="newTrackingRule.name"
            />
            <NbSelectInput
              id="seller_id-tracking-rules"
              class="w-48-5 my-2"
              :name="$t('trackingRulesPage.seller')"
              placeholder="Select Seller"
              :options="allSellersOptions"
              :error="errors.seller_id"
              v-model="newTrackingRule.seller_id"
            />
            <NbSelectInput
              id="contract_id-tracking-rules"
              class="w-48-5 my-2"
              :name="$t('trackingRulesPage.contract')"
              placeholder="Select Contract"
              :options="allContractsOptions"
              :error="errors.contract_id"
              v-model="newTrackingRule.contract_id"
            />
            <div
              v-if="newTrackingRule.tracking_mode == 'time_between_checkpoints'"
              class="d-flex justify-content-between flex-wrap w-100"
            >
              <NbSelectInput
                id="start_checkpoint_id-tracking-rules"
                class="w-38 my-2"
                :name="$t('trackingRulesPage.startCheckpoint')"
                placeholder="Select Checkpoint"
                :options="allCheckpointsOptions"
                :error="errors.start_checkpoint_id"
                v-model="newTrackingRule.start_checkpoint_id"
              />
              <NbSelectInput
                id="end_checkpoint_id-tracking-rules"
                class="w-38 my-2"
                :name="$t('trackingRulesPage.endCheckpoint')"
                placeholder="Select Checkpoint"
                :options="allCheckpointsOptions"
                :error="errors.end_checkpoint_id"
                v-model="newTrackingRule.end_checkpoint_id"
              />
              <NbTextInput
                id="days-tracking-rules"
                class="w-20-5 my-2"
                :name="$t('trackingRulesPage.maximumDays')"
                type="number"
                :placeholder="$t('trackingRulesPage.maximumDays')"
                :error="errors.days"
                v-model="newTrackingRule.days"
              />
            </div>
            <div v-else class="d-flex justify-content-between flex-wrap w-100">
              <NbSelectInput
                id="start_checkpoint_id-tracking-rules"
                class="w-100 my-2"
                :name="$t('trackingRulesPage.checkpoint')"
                placeholder="Select Checkpoint"
                :options="allCheckpointsOptions"
                :error="errors.start_checkpoint_id"
                v-model="newTrackingRule.start_checkpoint_id"
              />
            </div>
            <NbCheckbox
              id="config-log-tracking-rules"
              class="w-100 my-2"
              :name="$t('trackingRulesPage.log')"
              v-model="newTrackingRule.configuration.log"
            />

            <div class="w-100 bg-gray-10 nb-card-simple p-3 my-1">
              <NbCheckbox
                id="notify_seller-tracking-rules"
                class="w-100 my-2"
                :name="$t('trackingRulesPage.notifySeller')"
                v-model="newTrackingRule.configuration.notify_seller"
              />
              <div
                v-if="newTrackingRule.configuration.notify_seller"
                class="d-flex justify-content-between flex-wrap w-100"
              >
                <NbTextInput
                  id="seller_mail_title-tracking-rules"
                  class="w-100 mb-2 mt-3"
                  name="Title"
                  placeholder="Title"
                  :error="errors.seller_mail_title"
                  v-model="newTrackingRule.configuration.seller_mail_title"
                />
                <NbTextInput
                  id="seller_mail_body-tracking-rules"
                  class="w-100 my-2"
                  name="Seller Mail"
                  type="textarea"
                  placeholder="Seller Mail"
                  :error="errors.seller_mail_body"
                  v-model="newTrackingRule.configuration.seller_mail_body"
                />
              </div>
            </div>

            <div class="w-100 bg-gray-10 nb-card-simple p-3 my-1">
              <NbCheckbox
                id="seller_notification-tracking-rules"
                class="w-100 my-2"
                :name="$t('trackingRulesPage.sellerNotification')"
                v-model="newTrackingRule.configuration.seller_notification"
              />
              <div
                v-if="newTrackingRule.configuration.seller_notification"
                class="d-flex justify-content-between flex-wrap w-100"
              >
                <NbSelectInput
                  id="seller_notification_title-tracking-rules"
                  class="w-100 mb-2 mt-3"
                  name="Title"
                  :options="allSellerNotificationTitles"
                  placeholder="Title"
                  :error="errors.seller_notification_title"
                  v-model="
                    newTrackingRule.configuration.seller_notification_title
                  "
                />
                <NbTextInput
                  id="seller_notification_body-tracking-rules"
                  class="w-100 my-2"
                  name="Notification Mail"
                  type="textarea"
                  placeholder="Notification Mail"
                  :error="errors.seller_notification_body"
                  v-model="
                    newTrackingRule.configuration.seller_notification_body
                  "
                />
              </div>
            </div>

            <div class="w-100 bg-gray-10 nb-card-simple p-3 my-1">
              <NbCheckbox
                id="notify_customer-tracking-rules"
                class="w-100 my-2"
                :name="$t('trackingRulesPage.notifyCustomer')"
                v-model="newTrackingRule.configuration.notify_customer"
              />
              <div
                v-if="newTrackingRule.configuration.notify_customer"
                class="d-flex justify-content-between flex-wrap w-100"
              >
                <NbTextInput
                  id="customer_mail_title-tracking-rules"
                  class="w-100 mb-2 mt-3"
                  name="Title"
                  placeholder="Title"
                  :error="errors.customer_mail_title"
                  v-model="newTrackingRule.configuration.customer_mail_title"
                />
                <NbTextInput
                  id="customer_mail_body-tracking-rules"
                  class="w-100 my-2"
                  name="Customer Mail"
                  type="textarea"
                  placeholder="Customer Mail"
                  :error="errors.customer_mail_body"
                  v-model="newTrackingRule.configuration.customer_mail_body"
                />
              </div>
            </div>

            <div class="w-100 bg-gray-10 nb-card-simple p-3 my-1">
              <NbCheckbox
                id="notify_nobordist-tracking-rules"
                class="w-100 my-2"
                :name="$t('trackingRulesPage.notifyNobordist')"
                v-model="newTrackingRule.configuration.notify_nobordist"
              />
              <div
                v-if="newTrackingRule.configuration.notify_nobordist"
                class="d-flex justify-content-between flex-wrap w-100"
              >
                <NbTextInput
                  id="nobordist_mail_destination-tracking-rules"
                  class="w-48-5 mb-2 mt-3"
                  type="email"
                  name="Nobordist Email"
                  placeholder="destination@nobordist.com"
                  :error="errors.destination"
                  v-model="newTrackingRule.destination"
                />
                <NbTextInput
                  id="nobordist_mail_title-tracking-rules"
                  class="w-48-5 mb-2 mt-3"
                  name="Title"
                  placeholder="Title"
                  :error="errors.nobordist_mail_title"
                  v-model="newTrackingRule.configuration.nobordist_mail_title"
                />
                <NbTextInput
                  id="nobordist_mail_body-tracking-rules"
                  class="w-100 my-2"
                  name="Nobordist Mail"
                  type="textarea"
                  placeholder="Nobordist Mail"
                  :error="errors.nobordist_mail_body"
                  v-model="newTrackingRule.configuration.nobordist_mail_body"
                />
              </div>
            </div>

            <div class="w-100 bg-gray-10 nb-card-simple p-3 my-1">
              <NbCheckbox
                id="notify_webhook-tracking-rules"
                class="w-100 my-2"
                :name="$t('trackingRulesPage.webhook')"
                v-model="newTrackingRule.configuration.notify_webhook"
              />
              <div
                v-if="newTrackingRule.configuration.notify_webhook"
                class="d-flex justify-content-between flex-wrap w-100"
              >
                <NbRadio
                  id="webhook-method-radio-tracking-rules"
                  class="w-16 my-2"
                  :name="$t('trackingRulesPage.method')"
                  :options="webhookOptions"
                  v-model="newTrackingRule.configuration.webhook.method"
                />
                <NbTextInput
                  id="endpoint-tracking-rules"
                  class="w-80 my-2"
                  name="Endpoint"
                  placeholder="Endpoint"
                  :error="errors.nobordist_mail_body"
                  v-model="newTrackingRule.configuration.webhook.endpoint"
                />

                <div
                  class="d-flex justify-content-between flex-wrap w-100 my-3"
                >
                  <div
                    class="w-48-5 border border-gray-20 my-1 py-2 px-3 h-fit"
                  >
                    <span class="w-100 heading-1 mb-3">
                      {{ $t("trackingRulesPage.addHeaders") }}:
                    </span>
                    <div class="border-top border-gray-20 mt-2 py-1 w-100">
                      <NbTextInput
                        id="hkey"
                        class="w-fill-available mb-2"
                        :name="$t('trackingRulesPage.key')"
                        :error="errors.headers"
                        v-model="hkey"
                      />
                      <NbTextInput
                        id="hvalue"
                        class="w-fill-available my-2"
                        :name="$t('trackingRulesPage.value')"
                        :error="errors.headers"
                        v-model="hvalue"
                      />
                      <NbButton
                        class="mt-0"
                        variant="primary"
                        icon="plus"
                        @click="addHeaders(hvalue, hkey)"
                      >
                        {{ $t("add") }}
                      </NbButton>
                    </div>
                  </div>
                  <div
                    class="w-48-5 border border-gray-20 my-1 py-2 px-3 h-fit"
                  >
                    <span class="w-100 heading-1 mb-3">
                      {{ $t("trackingRulesPage.headers") }}:
                    </span>
                    <div
                      v-for="(value, key, index) in newTrackingRule
                        .configuration.webhook.headers"
                      :key="index"
                      class="w-100"
                    >
                      <div
                        class="border-top border-gray-20 mt-2 py-1 d-flex w-100"
                      >
                        <NbTextInput
                          :id="`webhook-header-${index}`"
                          class="w-fill-available"
                          :name="key"
                          :error="errors.headers"
                          v-model="
                            newTrackingRule.configuration.webhook.headers[key]
                          "
                        />
                        <NbButton
                          v-if="key !== 'ContentType'"
                          class="mt-4"
                          variant="tertiary"
                          icon="trash"
                          @click="removeHeaders(key)"
                        >
                        </NbButton>
                      </div>
                    </div>
                  </div>
                </div>

                <NbTextInput
                  id="Webhook-body-tracking-rules"
                  class="w-100 my-2"
                  name="Webhook body"
                  type="textarea"
                  placeholder="Webhook body"
                  :error="errors.nobordist_mail_body"
                  v-model="newTrackingRule.configuration.webhook.body"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            :data-target="`#${id}`"
          >
            {{ $t("close") }}
          </NbButton>
          <NbButton @click="checkTrackingRule()">
            {{ newTrackingRule.id ? $t("update") : $t("save") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbRadio from "@/components/buttons/NbRadio.vue";
import NbSelectInput from "@/components/input/select/NbSelectInput.vue";
import NbCheckbox from "@/components/buttons/NbCheckbox.vue";

import TrackingRuleService from "@/services/TrackingRuleService";

const trackingRuleService = new TrackingRuleService();

export default {
  components: {
    NbModal,
    NbButton,
    NbTextInput,
    NbRadio,
    NbSelectInput,
    NbCheckbox,
  },
  name: "ModalTrackingRuleCreate",
  props: {
    id: {
      type: String,
      default: "ModalTrackingRuleCreate",
    },
    trackingRule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hkey: "",
      hvalue: "",
      errors: {},
    };
  },
  created() {},
  computed: {
    newTrackingRule: {
      get() {
        return this.trackingRule;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    allContracts() {
      return this.$store.state.contracts.all_items;
    },
    allContractsOptions() {
      const contractOptions = this.allContracts.map((item) => ({
        value: item.id,
        text: `(${item.id}) ${item.slug}`,
      }));
      return contractOptions;
    },
    allSellers() {
      return this.$store.state.sellers.all_items;
    },
    allSellersOptions() {
      const sellerOptions = this.allSellers.map((item) => ({
        value: item.id,
        text: `(${item.id}) ${item.name}`,
      }));
      return sellerOptions;
    },
    allCheckpoints() {
      return this.$store.state.nobordist_checkpoints.all_items;
    },
    allCheckpointsOptions() {
      const checkpointOptions = this.allCheckpoints.map((item) => ({
        value: item.id,
        text: `(${item.id}) ${item.name}`,
      }));
      return checkpointOptions;
    },
    allSellerNotificationTitles() {
      return [
        {
          value: "finance",
          text: this.$t(
            "trackingRulesPage.allSellerNotificationTitles.finance",
          ),
        },
        {
          value: "insufficient_balance",
          text: this.$t(
            "trackingRulesPage.allSellerNotificationTitles.insufficientBalance",
          ),
        },
        {
          value: "tracking_issue",
          text: this.$t(
            "trackingRulesPage.allSellerNotificationTitles.trackingIssue",
          ),
        },
      ];
    },
    webhookOptions() {
      return [
        { text: "POST", value: "POST" },
        { text: "PUT", value: "PUT" },
      ];
    },
  },
  methods: {
    removeHeaders(key) {
      this.newTrackingRule.configuration.webhook.headers;
      const updatedHeaders = {
        ...this.newTrackingRule.configuration.webhook.headers,
      };
      delete updatedHeaders[key];

      this.newTrackingRule.configuration.webhook.headers = updatedHeaders;
      /* usa o VUE para setar
      this.$set(
        this.newTrackingRule.configuration.webhook,
        "headers",
        updatedHeaders,
      );
      */
    },
    addHeaders(value, key) {
      this.newTrackingRule.configuration.webhook.headers[key] = value;
      this.hkey = "";
      this.hvalue = "";
    },
    deleteObjectProps(obj) {
      let newObj = JSON.parse(JSON.stringify(obj));
      if (newObj.id) delete newObj.id;
      if (newObj.type) delete newObj.type;
      if (newObj.created_at) delete newObj.created_at;
      if (newObj.updated_at) delete newObj.updated_at;
      if (newObj.hierarchy_status) delete newObj.hierarchy_status;
      delete newObj.is_internal;
      delete newObj.final_status;
      return newObj;
    },
    assessWebhook() {
      if (this.newTrackingRule.tracking_mode === "received_checkpoint") {
        let start_checkpoint;
        if (this.newTrackingRule.start_checkpoint_id) {
          start_checkpoint = this.allCheckpoints.find(
            (checkpoint) =>
              checkpoint.id == this.newTrackingRule.start_checkpoint_id,
          );
          this.bodyWebhook = this.deleteObjectProps(start_checkpoint);
        }
      } else if (
        this.newTrackingRule.tracking_mode === "time_between_checkpoints"
      ) {
        let startCheckpoint;
        let endCheckpoint;
        if (this.newTrackingRule.start_checkpoint_id) {
          startCheckpoint = this.allCheckpoints.find(
            (checkpoint) =>
              checkpoint.id == this.newTrackingRule.start_checkpoint_id,
          );
        }
        if (this.newTrackingRule.end_checkpoint_id) {
          endCheckpoint = this.allCheckpoints.find(
            (checkpoint) =>
              checkpoint.id == this.newTrackingRule.end_checkpoint_id,
          );
        }

        if (startCheckpoint && endCheckpoint && this.newTrackingRule.days) {
          this.bodyWebhook = {
            start_checkpoint: this.deleteObjectProps(startCheckpoint),
            end_checkpoint: this.deleteObjectProps(endCheckpoint),
            maximum_days: this.newTrackingRule.days,
          };
        }
      }
    },
    checkTrackingRule() {
      if (this.newTrackingRule.id) {
        this.updateTrackingRule();
        return;
      }
      this.addTrackingRule();
    },
    addTrackingRule() {
      trackingRuleService
        .setTrackingRule(this.newTrackingRule)
        .then(() => {
          this.$helpers.closeModal(this.id);
          this.$emit("reloadTrackingRules");
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
        });
    },
    updateTrackingRule() {
      trackingRuleService
        .updateTrackingRule(this.newTrackingRule.id, this.newTrackingRule)
        .then(() => {
          this.$helpers.closeModal(this.id);
          this.$emit("updateTrackingRule");
        })
        .catch((error) => {
          if (
            Object.prototype.hasOwnProperty.call(error, "response") &&
            Object.prototype.hasOwnProperty.call(error.response, "data") &&
            Object.prototype.hasOwnProperty.call(
              error.response.data,
              "messages",
            )
          ) {
            this.errors = error.response.data.messages[0];
          }
        });
    },
  },
  watch: {
    "newTrackingRule.tracking_mode": function () {
      if (this.newTrackingRule.tracking_mode !== "time_between_checkpoints") {
        this.newTrackingRule.end_checkpoint_id = null;
        this.newTrackingRule.days = null;
      }
    },
    "newTrackingRule.start_checkpoint_id": function () {
      this.assessWebhook();
    },
    "newTrackingRule.end_checkpoint_id": function () {
      this.assessWebhook();
    },
    "newTrackingRule.days": function () {
      this.assessWebhook();
    },
    "newTrackingRule.configuration.notify_webhook": function (val) {
      if (val) {
        // // To remove Vue error use line below
        // this.newTrackingRule.configuration.webhook.body = JSON.stringify(this.bodyWebhook)
        this.newTrackingRule.configuration.webhook.body = this.bodyWebhook;
      } else {
        this.newTrackingRule.configuration.webhook.body = null;
      }
    },
  },
};
</script>

<style scoped></style>
