<template>
  <div>
    <NbModal
      :id="id"
      :preventClose="true"
      modalConfig="modal-dialog-centered"
      :zindex="zindex"
      width="76rem"
    >
      <template v-slot:header>
        <div class="d-flex mb-4">
          <div>
            <h5 class="heading-4 ml-3 mb-1">
              {{ `${$t("nobordistCheckpointsViewPage.title")} ${itemId}` }}
            </h5>
            <p class="mb-0 ml-3 body-4">
              {{ $t("checkpointsPage.subTitleView") }}
            </p>
          </div>
        </div>
      </template>
      <div>
        <NbCard
          id="checkpoint_chard"
          :title="$t('generalInformation')"
          class="bg-gray-05"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap">
              <NbTextInput
                id="tracking_checkpoint_code"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('nobordistCheckpointsViewPage.trackingCode')"
                v-model="checkpoint.tracking_code"
                disabled
              />
              <NbTextInput
                id="checkpoint_type"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('nobordistCheckpointsViewPage.type')"
                v-model="checkpoint.type"
                disabled
              />
              <NbTextInput
                id="checkpoint_slug"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('nobordistCheckpointsViewPage.slug')"
                v-model="checkpoint.slug"
                disabled
              />
              <NbTextInput
                id="checkpoint_name"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('nobordistCheckpointsViewPage.name')"
                v-model="checkpoint.name"
                disabled
              />
              <NbTextInput
                id="tcheckpoint_itle"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('nobordistCheckpointsViewPage.titleField')"
                v-model="checkpoint.title"
                disabled
              />
              <NbTextInput
                id="titlcheckpoint_e_pt"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('nobordistCheckpointsViewPage.titlePt')"
                v-model="checkpoint.title_pt"
                disabled
              />
              <NbTextInput
                id="descripcheckpoint_tion"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-100 my-2"
                :name="$t('nobordistCheckpointsViewPage.description')"
                v-model="checkpoint.description"
                disabled
              />
              <NbTextInput
                id="descriptiocheckpoint_n_pt"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-100 my-2"
                :name="$t('nobordistCheckpointsViewPage.descriptionPt')"
                v-model="checkpoint.description_pt"
                disabled
              />
            </div>
          </template>
        </NbCard>
        <NbFooter
          :text="$t('components.footer.checkOurSupport')"
          link="https://nobordistinc.freshdesk.com/support/home"
        />
      </div>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            :data-target="`#${id}`"
            @click="resetQuery()"
          >
            {{ $t("close") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import CheckpointService from "@/services/CheckpointService";

const checkpointService = new CheckpointService();

export default {
  components: { NbModal, NbButton, NbTextInput, NbCard, NbFooter },
  name: "ModalNobordistCheckpointsView",
  props: {
    id: {
      type: String,
      default: "ModalNobordistCheckpointsView",
    },
    itemId: {
      type: [Number, String],
      required: true,
    },
    zindex: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      checkpoint: {},
    };
  },
  created() {},
  computed: {},
  methods: {
    resetQuery() {
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.push({ query: {} });
        return;
      }
    },
  },
  watch: {
    itemId(newVal) {
      if (newVal) {
        checkpointService.getCheckpoint(newVal).then((response) => {
          this.checkpoint = response.data.data;
        });
      }
    },
  },
};
</script>

<style scoped></style>
