<template>
  <div>
    <div
      class="heading d-md-flex justify-content-md-between align-items-md-center"
    >
      <NbPageTitle
        :title="$t('flightsPage.title')"
        :subTitle="$t('flightsPage.subtitle')"
      />
      <NbButton
        @click="isCreateFlightModalOpen = true"
        class="btn-add d-flex justify-content-start align-items-center"
      >
        <NbIcon icon="plus" class="mr-2" />
        {{ $t("flightsPage.createNewFlight") }}
      </NbButton>
    </div>

    <section class="page-container">
      <NbTabs class="mb-4" :tabs="tabsOptions" />

      <NbTablev2
        tableOf="flighstTable"
        namespace="flights"
        ref="flighstTableRef"
        :clickable="true"
        :allFields="tableFields"
        :fields="allFields"
        :tabs="true"
        :optionsWidth="260"
        :filterOptions="filterOptions"
        @reloadFields="allFields = $event"
        @total="totalFlights = $event"
        @clickedRow="checkPaymentLoadModalData($event)"
      >
        <template #cell(flight_label)="data">
          <div v-if="data.item.mawb_number">
            <NbButton
              variant="tertiary"
              @click="generateFlightLabel(data.item.id)"
            >
              <NbIcon icon="download" class="mr-1" />
              {{ $t("download") }}
            </NbButton>
          </div>
        </template>
        <template #cell(green_light)="data">
          <IsCheckedIcon :checked="data.item.green_light" />
        </template>

        <template #cell(prealerts_sent)="data">
          {{ data.item.prealerts_sent }}
        </template>

        <template #cell(status)="data">
          <FlightStatus :status="data.item.status" />
        </template>
      </NbTablev2>
    </section>

    <Modal v-model="isFlightModalOpen" size="lg" @close="removeQueryParam()">
      <FlightModal
        :flight-id="flightId"
        @refresh="$refs?.flighstTableRef?.getData()"
      />
    </Modal>

    <Modal v-model="isCreateFlightModalOpen" size="lg">
      <FlightCreateModal @success="$refs?.flighstTableRef?.getData()" />
    </Modal>
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import NbIcon from "@/components/icons/NbIcon.vue";
import FlightModal from "./components/FlightModal.vue";
import FlightService from "@/services/FlightService";
import FlightCreateModal from "./components/FlightCreateModal.vue";
import FlightStatus from "./components/FlightStatus.vue";
import IsCheckedIcon from "../../../components/IsCheckedIcon.vue";
import Modal from "@/components/modal/Modal.vue";
import queryParamsMixin from "../../../mixins/query-params-mixin";

const flightService = new FlightService();

const tabs = {
  NEXT_FLIGHTS: "nextFlights",
};

export default {
  name: "Flights",
  mixins: [queryParamsMixin],
  components: {
    NbPageTitle,
    NbButton,
    NbTabs,
    NbIcon,
    NbTablev2,
    FlightCreateModal,
    FlightModal,
    FlightStatus,
    IsCheckedIcon,
    Modal,
  },
  data: () => {
    return {
      filter: null,
      allFields: [],
      namespace: "flights",
      currentTab: tabs.NEXT_FLIGHTS,
      totalFlights: 0,
      flightId: null,
      isFlightModalOpen: false,
      isCreateFlightModalOpen: false,
    };
  },
  computed: {
    tableFields() {
      return [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "airline",
          label: this.$t("flightsPage.fields.airline"),
        },
        {
          key: "flight_number",
          label: this.$t("flightsPage.fields.flightNumber"),
        },
        {
          key: "flight_label",
          label: this.$t("flightsPage.fields.flightLabel"),
        },
        {
          key: "mawb_number",
          label: this.$t("flightsPage.fields.mawbNumber"),
        },
        {
          key: "green_light",
          label: this.$t("flightsPage.fields.greenLight"),
        },
        {
          key: "prealerts_sent",
          label: this.$t("flightsPage.fields.prealertsSent"),
        },
        {
          key: "status",
          label: this.$t("flightsPage.fields.status"),
        },
        {
          key: "departure_airport",
          label: this.$t("flightsPage.fields.departureAirport"),
        },
        {
          key: "arrival_airport",
          label: this.$t("flightsPage.fields.arrivalAirport"),
        },
        {
          key: "departure_date",
          label: this.$t("flightsPage.fields.departureDate"),
        },
        {
          key: "created_at",
          label: this.$t("flightsPage.fields.creationDate"),
          sortable: true,
        },
      ];
    },
    tabsOptions() {
      return [
        {
          id: tabs.NEXT_FLIGHTS,
          label: this.$t("flightsPage.nextFlights"),
          current: this.currentTab,
          found: this.totalFlights,
        },
      ];
    },
    items() {
      return this.$store.state[this.namespace].items;
    },
    fields() {
      return [
        { key: "id", label: "ID" },
        {
          key: "airline",
          label: this.$t("flightsPage.fields.airline"),
        },
        {
          key: "flight_number",
          label: this.$t("flightsPage.fields.flightNumber"),
        },
        {
          key: "flight_label",
          label: this.$t("flightsPage.fields.flightLabel"),
        },
        {
          key: "mawb_number",
          label: this.$t("flightsPage.fields.mawbNumber"),
        },
        {
          key: "green_light",
          label: this.$t("flightsPage.fields.greenLight"),
        },
        {
          key: "prealerts_sent",
          label: this.$t("flightsPage.fields.prealertsSent"),
        },
        {
          key: "departure_airport",
          label: this.$t("flightsPage.fields.departureAirport"),
        },
        {
          key: "arrival_airport",
          label: this.$t("flightsPage.fields.arrivalAirport"),
        },
        {
          key: "departure_date",
          label: this.$t("flightsPage.fields.departureDate"),
        },
        {
          key: "created_at",
          label: this.$t("flightsPage.fields.creationDate"),
        },
      ];
    },
    filterOptions() {
      return [
        { key: "airline", label: this.$t("flightsPage.fields.airline") },
        {
          key: "flight_number",
          label: this.$t("flightsPage.fields.flightNumber"),
        },
        {
          key: "mawb_number",
          label: this.$t("flightsPage.fields.mawbNumber"),
          type: "number",
        },
        {
          key: "green_light",
          label: this.$t("flightsPage.fields.greenLight"),
          type: "boolean",
        },
        {
          key: "prealerts_sent",
          label: this.$t("flightsPage.fields.prealertsSent"),
          type: "number",
        },
        {
          key: "departure_airport",
          label: this.$t("flightsPage.fields.departureAirport"),
        },
        {
          key: "arrival_airport",
          label: this.$t("flightsPage.fields.arrivalAirport"),
        },
        {
          key: "departure_date",
          label: this.$t("flightsPage.fields.departureDate"),
          type: "dates",
        },
        {
          key: "registration_date",
          label: this.$t("registrationDate"),
          type: "dates",
        },
      ];
    },
  },
  methods: {
    onDelete() {
      this.isFlightModalOpen = false;
      this.$refs?.flighstTableRef?.getData();
    },
    checkPaymentLoadModalData(event) {
      if (event.id) {
        this.showFlight(event.id);
        return;
      }
    },
    closeModal(loadContent = false) {
      this.flightId = null;
      this.removeQueryParam();

      if (loadContent) {
        this.$refs?.flighstTableRef?.getData();
      }
    },
    showFlight(id) {
      this.flightId = id;
      this.isFlightModalOpen = true;
      this.setUrlSearchParam("id", id);
    },
    loadItems() {
      this.$store.dispatch(this.namespace + "/fetchItems", this.namespace);
    },
    generateFlightLabel(id) {
      flightService.generateFlightLabel(id).then((response) => {
        if (
          // eslint-disable-next-line no-prototype-builtins
          response.data.hasOwnProperty("data") &&
          // eslint-disable-next-line no-prototype-builtins
          response.data.data.hasOwnProperty("download_url")
        ) {
          window.open(response.data.data.download_url);
        }
      });
    },
  },
  mounted() {
    const queryId = this.$route.query?.id;
    if (queryId) {
      this.showFlight(queryId);
    }
  },
  created() {
    this.allFields = this.fields;
  },
};
</script>

<style lang="scss" scoped>
.btn-add {
  width: 250px;
}
</style>
