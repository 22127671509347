<template>
  <div>
    <NbModal
      :id="id"
      :preventClose="true"
      modalConfig="modal-dialog-centered"
      width="76rem"
      @close="resetQuery()"
    >
      <template v-slot:header>
        <div class="d-flex mb-4">
          <h5 class="heading-4 ml-3 mb-1">
            {{ `${$t("volumeCheckpointsViewPage.title")} ${itemId}` }}
          </h5>
        </div>
      </template>
      <div>
        <NbCard
          id="checkpoint_chard"
          :title="$t('generalInformation')"
          class="bg-gray-05"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap">
              <NbTextInput
                id="volumeCheckpoint-message"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-100 my-2"
                :name="$t('volumeCheckpointsViewPage.message')"
                v-model="volumeCheckpoint.message"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-message"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('volumeCheckpointsViewPage.attachment')"
                v-model="volumeCheckpoint.attachment"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-ConvertedNotifs"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('volumeCheckpointsViewPage.ConvertedNotifs')"
                v-model="volumeCheckpoint.converted_notifs"
                disabled
              />
              <div
                class="w-32-5 my-2 link-1"
                @click="
                  pushTo(
                    `/shipping/volumes?id=${volumeCheckpoint.volume_id}`,
                    volumeCheckpoint.volume_id,
                  )
                "
              >
                <NbTextInput
                  id="volumeCheckpoint-volumeID"
                  variant="borderless-gray-10"
                  :placeholder="$t('notFound')"
                  class="w-100 link-1"
                  :name="$t('volumeCheckpointsViewPage.volumeID')"
                  v-model="volumeCheckpoint.volume_id"
                  disabled
                />
              </div>
              <NbTextInput
                id="volumeCheckpoint-receivedCheckpointID"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('volumeCheckpointsViewPage.receivedCheckpointID')"
                v-model="volumeCheckpoint.received_checkpoint_id"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-checkpointID"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-32-5 my-2"
                :name="$t('volumeCheckpointsViewPage.checkpointID')"
                v-model="volumeCheckpoint.checkpoint_id"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-created"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('volumeCheckpointsViewPage.created')"
                v-model="volumeCheckpoint.created_at"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-dateIso"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('volumeCheckpointsViewPage.dateIso')"
                v-model="volumeCheckpoint.date_iso"
                disabled
              />
            </div>
          </template>
        </NbCard>

        <NbCard
          v-if="volumeCheckpoint.checkpoint"
          id="checkpoint_chard_2"
          :title="`${$t('volumeCheckpointsViewPage.checkpoint')} #${
            volumeCheckpoint.checkpoint_id
          }`"
          class="bg-gray-05 mt-3"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap">
              <NbTextInput
                id="volumeCheckpoint-type"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('volumeCheckpointsViewPage.type')"
                v-model="volumeCheckpoint.checkpoint.type"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-slug"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('volumeCheckpointsViewPage.slug')"
                v-model="volumeCheckpoint.checkpoint.slug"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-name"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('volumeCheckpointsViewPage.name')"
                v-model="volumeCheckpoint.checkpoint.name"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-description"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('volumeCheckpointsViewPage.description')"
                v-model="volumeCheckpoint.checkpoint.description"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-trackingCode"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('volumeCheckpointsViewPage.trackingCode')"
                v-model="volumeCheckpoint.checkpoint.tracking_code"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-titleField"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('volumeCheckpointsViewPage.titleField')"
                v-model="volumeCheckpoint.checkpoint.title"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-namePT"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('volumeCheckpointsViewPage.namePT')"
                v-model="volumeCheckpoint.checkpoint.name_pt"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-descriptionPT"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('volumeCheckpointsViewPage.descriptionPT')"
                v-model="volumeCheckpoint.checkpoint.description_pt"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-titlePT"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('volumeCheckpointsViewPage.titlePT')"
                v-model="volumeCheckpoint.checkpoint.title_pt"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-hierarchyStatus"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('volumeCheckpointsViewPage.hierarchyStatus')"
                v-model="volumeCheckpoint.checkpoint.hierarchy_status"
                disabled
              />
            </div>
          </template>
        </NbCard>

        <NbCard
          v-if="volume"
          id="checkpoint_chard_3"
          :title="`${$t('volumeCheckpointsViewPage.volumeData')} ${volume.id}`"
          class="bg-gray-05 mt-3"
        >
          <template #body>
            <div class="d-flex justify-content-between flex-wrap">
              <NbTextInput
                id="volumeCheckpoint-height"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-24 my-2"
                :decimals="2"
                :name="$t('volumeCheckpointsViewPage.height')"
                v-model="volume.height"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-width"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-24 my-2"
                :decimals="2"
                :name="$t('volumeCheckpointsViewPage.width')"
                v-model="volume.width"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-length"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-24 my-2"
                :decimals="2"
                :name="$t('volumeCheckpointsViewPage.length')"
                v-model="volume.length"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-weight"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-24 my-2"
                :decimals="3"
                :name="$t('volumeCheckpointsViewPage.weight')"
                v-model="volume.weight"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-cubageFactor"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('volumeCheckpointsViewPage.cubageFactor')"
                v-model="volume.cubage_factor"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-volumeType"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('volumeCheckpointsViewPage.volumeType')"
                v-model="volume.volume_type"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-orderID"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('volumeCheckpointsViewPage.orderID')"
                v-model="volume.order_id"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-lastMileTrackingNumber"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('volumeCheckpointsViewPage.lastMileTrackingNumber')"
                v-model="volume.last_mile_tracking_number"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-orderReference"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('volumeCheckpointsViewPage.orderReference')"
                v-model="volume.current_status.tracking_code"
                disabled
              />
              <NbTextInput
                id="volumeCheckpoint-checkpoint"
                variant="borderless-gray-10"
                :placeholder="$t('notFound')"
                class="w-48-5 my-2"
                :name="$t('volumeCheckpointsViewPage.checkpoint')"
                v-model="volume.order_reference"
                disabled
              />
            </div>
          </template>
        </NbCard>

        <NbCard
          v-if="volume"
          id="checkpoint_chard_4"
          :title="`${$t('volumeCheckpointsViewPage.volumes')}`"
          class="bg-gray-05 mt-3"
        >
          <template #body>
            <NbTablev2
              class="mt-2"
              tableOf="volumesInCheckpoint"
              :hasParentItens="true"
              ref="volumesInCheckpoint"
              :hiddeTableOptions="true"
              height="fit-content"
              tdClass="td-dark"
              :allFields="allFields"
              :fields="fields"
              @reloadFields="fields = $event"
              :startItems="volume.order_items"
            >
              <template #cell(value)="data">
                {{ standardizeValue(data.item.value) }}
              </template>
              <template #cell(length)="data">
                {{ standardizeValue(data.item.length) }}
              </template>
              <template #cell(width)="data">
                {{ standardizeValue(data.item.width) }}
              </template>
              <template #cell(height)="data">
                {{ standardizeValue(data.item.height) }}
              </template>
              <template #cell(weight)="data">
                {{ standardizeValue(data.item.weight, 3) }}
              </template>
            </NbTablev2>
          </template>
        </NbCard>
        <NbFooter
          :text="$t('components.footer.checkOurSupport')"
          link="https://nobordistinc.freshdesk.com/support/home"
        />
      </div>
      <template v-slot:footer>
        <div class="d-flex justify-content-end">
          <NbButton
            variant="secondary"
            data-toggle="modal"
            :data-target="`#${id}`"
            @click="resetQuery()"
          >
            {{ $t("close") }}
          </NbButton>
        </div>
      </template>
    </NbModal>
  </div>
</template>

<script>
import NbModal from "@/components/modal/NbModal.vue";
import NbButton from "@/components/buttons/NbButton.vue";
import NbTextInput from "@/components/input/text/NbTextInput.vue";
import NbCard from "@/components/cards/NbCard.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbFooter from "@/components/pagescomponents/NbFooter.vue";
import NProgress from "nprogress";

import VolumeService from "@/services/VolumeService";

const volumeService = new VolumeService();

export default {
  components: { NbModal, NbButton, NbTextInput, NbCard, NbFooter, NbTablev2 },
  name: "ModalVolumeCheckpointsView",
  props: {
    id: {
      type: String,
      default: "ModalVolumeCheckpointsView",
    },
    itemId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      volumeCheckpoint: {},
      volume: null,
      fields: [],
    };
  },
  created() {},
  computed: {
    allFields() {
      return [
        { key: "id", label: "ID", sortable: false },
        {
          key: "name",
          label: this.$t("volumeCheckpointsViewPage.fields.name"),
          sortable: false,
        },
        {
          key: "description",
          label: this.$t("volumeCheckpointsViewPage.fields.description"),
          sortable: false,
        },
        {
          key: "sku",
          label: this.$t("volumeCheckpointsViewPage.fields.sku"),
          sortable: false,
        },
        {
          key: "value_with_tax",
          label: this.$t("volumeCheckpointsViewPage.fields.valueWithTax"),
          sortable: false,
        },
        {
          key: "value",
          label: this.$t("volumeCheckpointsViewPage.fields.value"),
          sortable: false,
        },
        {
          key: "hs_code",
          label: this.$t("volumeCheckpointsViewPage.fields.hsCode"),
          sortable: false,
        },
        {
          key: "quantity",
          label: this.$t("volumeCheckpointsViewPage.fields.quantity"),
          sortable: false,
        },
        {
          key: "origin_country",
          label: this.$t("volumeCheckpointsViewPage.fields.originCountry"),
          sortable: false,
        },
        {
          key: "length",
          label: this.$t("volumeCheckpointsViewPage.fields.length"),
          sortable: false,
        },
        {
          key: "width",
          label: this.$t("volumeCheckpointsViewPage.fields.width"),
          sortable: false,
        },
        {
          key: "height",
          label: this.$t("volumeCheckpointsViewPage.fields.height"),
          sortable: false,
        },
        {
          key: "weight",
          label: this.$t("volumeCheckpointsViewPage.fields.weight"),
          sortable: false,
        },
      ];
    },
  },
  methods: {
    pushTo(path, id) {
      this.$helpers.closeModal(this.id);
      this.$router.push({
        path: path,
        query: {
          filteredVal: id,
          filteredKey: "name",
        },
      });
    },
    resetQuery() {
      this.$router.push({ query: {} });
      this.$emit("close");
    },
    addZero(number) {
      if (number <= 9) {
        return "0" + number;
      } else {
        return number;
      }
    },
    formateDate(date, time) {
      const data = new Date(date);
      const hours = new Date(time);
      const formatedDate =
        this.addZero(
          data.getDate().toString() +
            "/" +
            this.addZero(data.getMonth() + 1).toString() +
            "/" +
            data.getFullYear(),
        ) +
        "  " +
        this.addZero(hours.getHours()) +
        ":" +
        this.addZero(hours.getMinutes()) +
        ":" +
        this.addZero(hours.getSeconds());
      return formatedDate;
    },
    standardizeValue(val, fixTo) {
      let fix = 2;
      if (fixTo) fix = fixTo;
      const finalVal = parseFloat(val).toFixed(fix);
      if (finalVal === "NaN") {
        return "0.00";
      }
      return finalVal;
    },
  },
  watch: {
    itemId(newVal) {
      if (newVal) {
        volumeService.getVolumeCheckpoint(newVal).then((response) => {
          this.volumeCheckpoint = response.data.data;
          const time = this.volumeCheckpoint.created_at;
          const date = this.volumeCheckpoint.created_at;
          const updated = this.volumeCheckpoint.updated_at;
          // this.volumeCheckpoint.time = this.formateTime(time);
          this.volumeCheckpoint.created_at = this.formateDate(date, time);
          this.volumeCheckpoint.updated_at = this.formateDate(updated);
          if (this.volumeCheckpoint.date_iso) {
            const date_iso = this.volumeCheckpoint.date_iso;
            this.volumeCheckpoint.date_iso = this.formateDate(
              date_iso,
              date_iso,
            );
          }

          //TODO: A resposta do back já deveria conter o volume
          if (!this.volumeCheckpoint.volume) {
            volumeService
              .getVolume(this.volumeCheckpoint.volume_id)
              .then((response) => {
                this.volume = response.data.data;
              })
              .catch(() => {
                this.volume = null;
                NProgress.done();
                this.$bvToast.toast("Volume Not Found", {
                  title: "Error",
                  toaster: "b-toaster-top-right",
                  variant: "danger",
                  autoHideDelay: 10000,
                  appendToast: true,
                  solid: true,
                });
              });
          } else {
            this.volume = this.volumeCheckpoint.volume;
          }
        });
      }
    },
  },
};
</script>

<style scoped></style>
