<template>
  <div>
    <NbPageTitle
      :title="$t('volumeReversePage.title')"
      :subTitle="$t('volumeReversePage.subtitle')"
    />

    <DownloadVolumesReverseModal :show.sync="showVolumesReverseModal" />

    <VolumeReverseModal
      @close="setUrlSearchParam('id')"
      ref="volumeReverseModal"
    />

    <section class="page-container mt-5">
      <NbTabs class="mb-4" :tabs="tabsOptions" @switchTab="switchTab($event)" />
      <NbTablev2
        tableOf="volumesReversePage"
        namespace="volume_reverses"
        ref="volumesReverseRef"
        :clickable="true"
        :allFields="fields"
        :fields="volumesReverseFields"
        :optionsWidth="260"
        :filterOptions="filterOptions"
        @optionTook="optionTook($event)"
        :buttonOptions="buttonOptions"
        :selectedItems.sync="selected"
        @reloadFields="volumesReverseFields = $event"
        @total="totalVolumesReverse = $event"
        tabs
        selectable
        @clickedRow="showVolumeReverseDetails($event.id)"
      >
        <template #cell(volume.order.order_number)="data">
          {{ data.item.volume.order.order_number }}
        </template>
        <template #cell(order_items_name)="data">
          <span v-for="item in data.item.volume.order_items" :key="item.id">
            {{ item.name }}
          </span>
        </template>
        <template #cell(order_items_description)="data">
          <span v-for="item in data.item.volume.order_items" :key="item.id">
            {{ item.description }}
          </span>
        </template>
        <template #cell(order_items_hs_code)="data">
          <span v-for="item in data.item.volume.order_items" :key="item.id">
            {{ item.hs_code }}
          </span>
        </template>
        <template #[`cell(volume.order_id)`]="data">
          <router-link
            class="link-1"
            :to="'/shipping/orders?id=' + data.item.volume.order_id"
          >
            {{ data.item.volume.order_id }}
          </router-link>
        </template>
        <template #cell(volume_id)="data">
          <router-link
            class="link-1"
            :to="`/shipping/volumes?id=` + data.item.volume_id"
          >
            {{ data.item.volume_id }}
          </router-link>
        </template>
        <template #[`cell(volume.order.seller_id)`]="data">
          <router-link
            class="link-1"
            :to="
              '/users_management/sellers?id=' + data.item.volume.order.seller_id
            "
          >
            {{ data.item.volume.order.seller_id }}
          </router-link>
        </template>
      </NbTablev2>
    </section>
  </div>
</template>

<script>
import NbPageTitle from "@/components/pagescomponents/NbPageTitle.vue";
import NbTablev2 from "@/components/tables/NbTablev2.vue";
import NbTabs from "@/components/tables/NbTabs.vue";
import DownloadVolumesReverseModal from "./components/DownloadVolumesReverseModal.vue";
import VolumeReverseModal from "./components/VolumeReverseModal.vue";
import queryParamsMixin from "@/mixins/query-params-mixin.js";

export default {
  name: "VolumeReverse",
  mixins: [queryParamsMixin],
  components: {
    NbPageTitle,
    NbTablev2,
    NbTabs,
    DownloadVolumesReverseModal,
    VolumeReverseModal,
  },
  data: () => {
    return {
      showVolumesReverseModal: false,
      volumesReverseFields: [],
      namespace: "volume_reverses",
      selected: [],
      totalVolumesReverse: 0,
      currentTab: "all",
    };
  },
  computed: {
    tabsOptions() {
      return [
        {
          id: this.currentTab,
          label: this.$t("components.nbTable.all"),
          found: this.totalVolumesReverse || 0,
          current: this.currentTab,
        },
      ];
    },
    buttonOptions() {
      return [
        {
          text: this.$t("downloadReport"),
          value: "showDownloadVolumesReverseModal",
          disabled: !this.selected.length,
        },
      ];
    },
    items() {
      return this.$store.state[this.namespace].items;
    },
    fields() {
      return [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "volume.order.order_number",
          label: this.$t("volumeReversePage.fields.orderNumber"),
        },
        {
          key: "volume.order_id",
          label: this.$t("volumeReversePage.fields.orderID"),
        },
        {
          key: "volume_id",
          label: this.$t("volumeReversePage.fields.volumeID"),
        },
        {
          key: "volume.order.seller_id",
          label: this.$t("volumeReversePage.fields.sellerID"),
        },
        {
          key: "name",
          label: this.$t("volumeReversePage.fields.name"),
        },
        {
          key: "created_at",
          label: this.$t("volumeReversePage.fields.creationdate"),
        },
        {
          key: "reverse_number",
          label: this.$t("volumeReversePage.fields.reverseNumber"),
        },
        {
          key: "address",
          label: this.$t("volumeReversePage.fields.address"),
        },
        {
          key: "address_number",
          label: this.$t("volumeReversePage.fields.addressNumber"),
        },
        {
          key: "address_complement",
          label: this.$t("volumeReversePage.fields.addressComplement"),
        },
        {
          key: "state",
          label: this.$t("volumeReversePage.fields.state"),
        },
        {
          key: "postal_code",
          label: this.$t("volumeReversePage.fields.postalCode"),
        },
        {
          key: "country",
          label: this.$t("volumeReversePage.fields.country"),
        },
        {
          key: "city",
          label: this.$t("volumeReversePage.fields.city"),
        },
        {
          key: "volume.order.customer_full_name",
          label: this.$t("volumeReversePage.fields.customerName"),
        },
        {
          key: "order_items_name",
          label: this.$t("volumeReversePage.fields.orderItemsName"),
        },
        {
          key: "order_items_description",
          label: this.$t("volumeReversePage.fields.orderItemsdescription"),
        },
        {
          key: "order_items_hs_code",
          label: this.$t("volumeReversePage.fields.orderItemsHSCode"),
        },
      ];
    },
    downloadFields() {
      return [
        { key: "id", label: "ID", show: true },
        {
          key: "volume.order.order_number",
          label: this.$t("volumeReversePage.fields.orderNumber"),
          show: true,
        },
        {
          key: "volume.order_id",
          label: this.$t("volumeReversePage.fields.orderID"),
          type: "number",
          show: true,
        },
        {
          key: "volume_id",
          label: this.$t("volumeReversePage.fields.volumeID"),
          type: "number",
          show: true,
        },
        {
          key: "volume.order.seller_id",
          label: this.$t("volumeReversePage.fields.sellerID"),
          type: "number",
          show: true,
        },
        {
          key: "name",
          label: this.$t("volumeReversePage.fields.name"),
          show: true,
        },
        {
          key: "created_at",
          label: this.$t("volumeReversePage.fields.creationdate"),
          type: "date",
          show: true,
        },
        {
          key: "reverse_number",
          label: this.$t("volumeReversePage.fields.reverseNumber"),
          show: true,
        },
        {
          key: "address",
          label: this.$t("volumeReversePage.fields.address"),
          show: true,
        },
        {
          key: "address_number",
          label: this.$t("volumeReversePage.fields.addressNumber"),
          show: true,
        },
        {
          key: "address_complement",
          label: this.$t("volumeReversePage.fields.addressComplement"),
          show: true,
        },
        {
          key: "state",
          label: this.$t("volumeReversePage.fields.state"),
          show: true,
        },
        {
          key: "postal_code",
          label: this.$t("volumeReversePage.fields.postalCode"),
          show: true,
        },
        {
          key: "country",
          label: this.$t("volumeReversePage.fields.country"),
          show: true,
        },
        {
          key: "city",
          label: this.$t("volumeReversePage.fields.city"),
          show: true,
        },
        {
          key: "volume.order.customer_full_name",
          label: this.$t("volumeReversePage.fields.customerName"),
          show: true,
        },
        {
          key: "order_items_name",
          label: this.$t("volumeReversePage.fields.orderItemsName"),
          show: true,
        },
        {
          key: "order_items_description",
          label: this.$t("volumeReversePage.fields.orderItemsdescription"),
          show: true,
        },
        {
          key: "order_items_hs_code",
          label: this.$t("volumeReversePage.fields.orderItemsHSCode"),
          show: true,
        },
      ];
    },
    filterOptions() {
      return [
        {
          key: "volume_id",
          label: this.$t("volumeReversePage.fields.volumeID"),
          type: "number",
        },
        {
          key: "seller_id",
          label: this.$t("volumeReversePage.fields.sellerID"),
          type: "number",
        },
        {
          key: "reverse_number",
          label: this.$t("volumeReversePage.fields.reverseNumber"),
        },
        { key: "address", label: this.$t("volumeReversePage.fields.address") },
        {
          key: "address_number",
          label: this.$t("volumeReversePage.fields.addressNumber"),
        },
        {
          key: "address_complement",
          label: this.$t("volumeReversePage.fields.addressComplement"),
        },
        { key: "state", label: this.$t("volumeReversePage.fields.state") },
        {
          key: "postal_code",
          label: this.$t("volumeReversePage.fields.postalCode"),
        },
        { key: "country", label: this.$t("volumeReversePage.fields.country") },
        { key: "city", label: this.$t("volumeReversePage.fields.city") },
        {
          key: "registration_date",
          label: this.$t("registrationDate"),
          type: "dates",
        },
      ];
    },
  },
  methods: {
    showVolumeReverseDetails(id) {
      this.setUrlSearchParam("id", id);
      this.$refs?.volumeReverseModal?.showModal();
    },
    optionTook(event) {
      this[event]();
    },
    showDownloadVolumesReverseModal() {
      this.showVolumesReverseModal = true;
    },
  },
  mounted() {
    const queryId = this.$route.query?.id;
    if (queryId) {
      this.showVolumeReverseDetails(queryId);
    }
  },
};
</script>
